import React, { memo, useState } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { ChevronUpIcon } from '@chakra-ui/icons';
import { useEventListener } from '@restart/hooks';

export const ScrollToTop = memo(function ScrollToTop() {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEventListener(window, 'scroll', toggleVisible);
  return (
    <Flex justify="flex-end" position="sticky" bottom="90px" width="full" p={3}>
      <IconButton
        size="lg"
        isRound={true}
        fontSize="30px"
        variant="accent"
        align-items="center"
        onClick={scrollToTop}
        icon={<ChevronUpIcon />}
        aria-label="Scroll to Top"
        style={{ display: visible ? 'inline-flex' : 'none' }}
      />
    </Flex>
  );
});
