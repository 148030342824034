import {
  Box,
  Button,
  Container,
  HStack,
  Icon,
  IconButton,
  Spacer,
  useToast,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  Flex,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import React, { memo, useCallback, useEffect } from 'react';
import { FaRedo, FaUndo } from 'react-icons/fa';
import {
  selectCanRedo,
  selectCanUndo,
  useCaseApi,
  useUseCaseStore,
} from '../../../store/useUseCaseStore';
import { unstable_usePrompt, useNavigate } from 'react-router-dom';
import useUpdatedRef from '@restart/hooks/useUpdatedRef';
import { useHotkeys } from 'react-hotkeys-hook';
import { isAdmin } from 'src/auth/scope';

export const EditorBar = memo(function EditorBar() {
  const toast = useToast();

  const undo = useUseCaseStore(state => state.undo);
  const redo = useUseCaseStore(state => state.redo);
  const saveChanges = useUseCaseStore(state => state.saveChanges);
  const getUseCases = useUseCaseStore(state => state.getAllUseCases);

  const canUndo = useUseCaseStore(selectCanUndo);
  const canRedo = useUseCaseStore(selectCanRedo);

  const isChanged = useUseCaseStore(state => state.isChanged);
  const isChangedRef = useUpdatedRef(isChanged);

  const useCase = useUseCaseStore(state => state.useCase);

  const navigate = useNavigate();

  const deleteUseCase = async () => {
    const id = useCase.id ? useCase.id : useCase.useCaseUserGeneralId;
    await useCaseApi.deleteUseCase(id);
    await getUseCases();
    navigate('/usecases');
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const saveChangesHandler = useCallback(async () => {
    try {
      const id = await saveChanges();

      if (id) navigate(`/usecases/${id}/details`);

      toast.closeAll();
      toast({
        status: 'success',
        position: 'top-right',
        description: 'Änderungen erfolgreich gespeichert',
      });
    } catch (e) {
      toast.closeAll();
      toast({
        position: 'top-right',
        status: 'error',
        description: 'Änderungen konnten nicht gespeichert werden',
      });
    }
  }, []);

  // prevent leaving the page by navigation when we have unsaved changes
  unstable_usePrompt({
    when: isChanged,
    message: 'Sie haben noch ungespeicherte Änderungen. Wollen Sie die Seite wirklich verlassen?',
  });

  // prevent leaving the page by browser
  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      if (isChangedRef.current) {
        e.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  }, []);

  // save hotkey
  useHotkeys(
    'metaKey+s,ctrl+s',
    () => {
      if (isChangedRef.current && useCase.selektionWorkshop) {
        saveChangesHandler();
      }
    },
    { enableOnFormTags: ['INPUT', 'SELECT', 'TEXTAREA'], preventDefault: true }
  );

  // undo hotkey
  useHotkeys(
    'metaKey+z,ctrl+z',
    () => {
      undo();
    },
    { enableOnFormTags: ['INPUT', 'SELECT', 'TEXTAREA'], preventDefault: true }
  );

  // redo hotkey
  useHotkeys(
    'metaKey+shift+z,ctrl+shift+z',
    () => {
      redo();
    },
    { enableOnFormTags: ['INPUT', 'SELECT', 'TEXTAREA'], preventDefault: true }
  );

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent p={6}>
          <Flex direction="column" align="center">
            <ModalHeader>Use-Case löschen</ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" justify="space-between" align="center" gap="20px">
              <Text>Möchten sie den Use-Case wirklich löschen?</Text>

              <Flex direction="row" justify="space-between" align="center" gap="20px">
                <Button className="unstyledBtn" onClick={onClose}>
                  Abbrechen
                </Button>
                <Button variant="accent" onClick={deleteUseCase}>
                  Löschen
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box position="sticky" backgroundColor="white" shadow="2xl" bottom="0" width="full">
        <Container maxWidth="container.md">
          <HStack p="4" spacing="4">
            <IconButton
              px={4}
              py={6}
              variant="accentLeft"
              icon={<Icon as={FaUndo} />}
              aria-label="Rückgängig"
              onClick={undo}
              isDisabled={!canUndo}
              data-test="undo-button"
            />
            <IconButton
              px={4}
              py={6}
              variant="accent"
              icon={<Icon as={FaRedo} />}
              aria-label="Wiederholen"
              onClick={redo}
              isDisabled={!canRedo}
              data-test="redo-button"
            />
            <Spacer />
            {isAdmin() && (useCase.id || useCase.useCaseUserGeneralId) && (
              <Button variant="accent" onClick={onOpen}>
                Use-Case löschen
              </Button>
            )}
            <Button
              variant="accent"
              data-test="save-button"
              onClick={saveChangesHandler}
              isDisabled={!isChanged || !useCase.selektionWorkshop}>
              Änderungen speichern
            </Button>
          </HStack>
        </Container>
      </Box>
    </>
  );
});
