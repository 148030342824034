import React from 'react';
import { Spinner } from '@chakra-ui/react';

const SpinnerUI = () => {
  return (
    <Spinner
      color="accent"
      size="xl"
      position="fixed"
      speed="0.65s"
      left="50%"
      top="50%"
      thickness="4px"
      emptyColor="gray.200"
    />
  );
};

export default SpinnerUI;
