import React, { useEffect, useState } from 'react';
import { Avatar, Divider, Flex, Text, ListItem, Box } from '@chakra-ui/react';
import { AddUpdateCustomerForm } from '../UserSelector/AddUpdateCustomerForm';
import CustomerConsultantManager from './CustomerConsultantManager';
import { useRelationshipStore } from 'src/store/useRelationshipStore';
import { UserInputDTO } from 'src/api';

function ConsultantInfo({ contacts }: { contacts: UserInputDTO[] }) {
  return (
    <Box mr={5}>
      {contacts.length < 1 ? (
        <Text>Derzeit keine Consultants</Text>
      ) : (
        <Flex direction="column">
          <Text fontSize="large" fontWeight="bold">
            Consultants:
          </Text>
          {contacts.map(contact => {
            return (
              <Box key={contact.id}>
                <Text>{contact.name}</Text>
                <Text>{contact.email}</Text>
                <Divider />
              </Box>
            );
          })}
        </Flex>
      )}
    </Box>
  );
}

export function UserItem({
  name,
  email,
  industrie,
  id,
  role,
}: {
  name: string;
  email: string;
  industrie: string;
  id: string;
  role: string;
}) {
  const customerContacts = useRelationshipStore(state => state.customerContacts);
  const [contacts, setContacts] = useState<UserInputDTO[]>([]);

  useEffect(() => {
    const customerContact = customerContacts.find(item => item.customerId === id);
    const contacts = customerContact ? customerContact.contacts : [];
    setContacts(contacts);
  }, [customerContacts]);

  return (
    <ListItem>
      <Flex alignItems="center" px="4" py="2" justifyContent="space-between">
        <Flex alignItems="center">
          <Avatar size="sm" />
          <Flex ml={4} direction="column" alignItems="start">
            <Text fontWeight="bold">{name}</Text>
            <Text fontSize="sm">{email}</Text>
            {industrie && (
              <Text fontSize="sm" fontWeight="bold">
                Industrie: {industrie}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center">
          {role === 'CUSTOMER' ? <ConsultantInfo contacts={contacts} /> : ''}
          {role === 'CONSULTANT' ? <CustomerConsultantManager consultantId={id} /> : ''}
          {industrie && <AddUpdateCustomerForm updateUser={true} id={id} />}
        </Flex>
      </Flex>
      <Divider />
    </ListItem>
  );
}
