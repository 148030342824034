import { create } from 'zustand';
import { combine, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  Configuration,
  RelationshipApi,
  UseCaseApi,
  UseCaseDTO,
  UseCaseGeneralApi,
  UseCaseGeneralDTO,
  UseCaseUserGeneralApi,
  UseCaseUserGeneralDTO,
  UserOutputDTO,
} from 'src/api';
import { ExtractState } from './types';
import Fuse from 'fuse.js';
import { debounce } from 'lodash-es';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { KeysWithValuesOfType } from '../types';
import {
  SortDataType,
  SortDirection,
  TableHeader,
} from 'src/components/pages/UseCaseList/useCaseTableData';
import { createEmptyUsecase } from './emptyUseCase';
import { useUserStore, userApi } from './useUserStore';
import { useCaseUserGeneralApi } from './useGeneralUseCaseStore';
import { isAdmin } from '../auth/scope';

export type UseCasesDTO = UseCaseDTO & UseCaseGeneralDTO & UseCaseUserGeneralDTO;

interface EditorAction {
  field: keyof UseCasesDTO;
  undoValue: string | number | boolean;
  redoValue: string | number | boolean;
}
const sortData: SortDataType = {
  keyToSort: 'industriespezifischTags',
  direction: SortDirection.DESC,
};

export const useCaseApi = new UseCaseApi(new Configuration({ basePath: '/api' }));
export const useCaseGeneralApi = new UseCaseGeneralApi(new Configuration({ basePath: '/api' }));

type UseCasesFilter = { [key in keyof UseCasesDTO]: Array<UseCasesDTO[key]> };

export interface SelectionsUserCaseFilter extends UseCasesFilter {
  selektionen?: Array<keyof UseCaseDTO>;
}

const emptyFilter: SelectionsUserCaseFilter = {};

const undoDebounceCallback = debounce((fn: () => void) => fn(), 500);

function setFavoriteFlag(generalUseCases: Array<UseCaseGeneralDTO>, favoriteUseCases: string[]) {
  for (let i = 0; i < generalUseCases.length; i++) {
    const useCase = generalUseCases.at(i) as UseCasesDTO;
    useCase.selektionFavorite = favoriteUseCases.includes(useCase.id);
  }
}

function preFilterUseCases(generalUseCases: UseCasesDTO[], customer: UserOutputDTO) {
  const userIndustrie = customer.industrie;
  const crossIndustrie = 'Übergreifend';

  generalUseCases = generalUseCases.filter(
    u => u.industrieTags.includes(userIndustrie) || u.industrieTags.includes(crossIndustrie)
  );
  return generalUseCases;
}

export const useUseCaseStore = create(
  devtools(
    immer(
      combine(
        {
          useCases: [] as UseCasesDTO[],
          useCase: {} as UseCasesDTO,
          filter: emptyFilter,
          sortData: sortData,
          filterQuery: '',
          undoHistory: [] as EditorAction[],
          redoHistory: [] as EditorAction[],
          lastChangedField: null as keyof UseCasesDTO,
          valueBeforeChange: null as string | number | boolean,
          isChanged: false,
          isLoading: true,
        },
        (set, get) => ({
          getAllUseCases: async () => {
            const customer = useUserStore.getState().selectedUser;
            const favoriteUseCases = (await userApi.getCustomersFavoriteUseCases(customer.id)).data;
            let generalUseCases = (await useCaseGeneralApi.getGeneralUseCases()).data;
            const specificUseCases = (await userApi.getCustomerUseCases(customer.id)).data;
            const userGeneralUseCases = (await userApi.getCustomerUseCasesGeneral(customer.id))
              .data;

            setFavoriteFlag(generalUseCases, favoriteUseCases);

            if (!isAdmin() || customer) {
              generalUseCases = preFilterUseCases(generalUseCases, customer);
            }

            if (!specificUseCases.length) {
              return set(state => {
                state.useCases = [...generalUseCases, ...userGeneralUseCases];
              });
            }

            const generalMixed = generalUseCases.map(gu => {
              const useCases: UseCaseDTO | undefined = specificUseCases.find(
                u => u.useCaseGeneralId === gu.id
              );

              return useCases ?? gu;
            });

            const userMixed = userGeneralUseCases.map(cu => {
              const useCases: UseCaseDTO | undefined = specificUseCases.find(
                u => u.useCaseUserGeneralId === cu.id
              );

              return useCases ?? cu;
            });

            set(state => {
              state.useCases = [...generalMixed, ...userMixed];
            });
          },
          getGeneralUseCases: async () => {
            const resGeneral = (await useCaseGeneralApi.getGeneralUseCases()).data;

            set(state => {
              state.useCases = resGeneral;
            });
          },
          createEmptyUseCaseDTO: async (generalUseCaseId: string) => {
            set(state => {
              state.isLoading = true;
            });

            const customer = useUserStore.getState().selectedUser;
            try {
              let res: AxiosResponse<UseCaseGeneralDTO | UseCaseUserGeneralDTO, any> = null;
              try {
                res = await useCaseGeneralApi.getGeneralUseCase(generalUseCaseId);
              } catch (e) {
                res = await useCaseUserGeneralApi.getGeneralUserUseCase(generalUseCaseId);
              }
              delete res.data.id;

              set(state => {
                state.useCase = createEmptyUsecase(customer.id, generalUseCaseId, false, res.data);
                state.isLoading = false;
              });
            } catch (err: any) {
              if (err.response.status === HttpStatusCode.NotFound) {
                const res = await useCaseUserGeneralApi.getGeneralUserUseCase(generalUseCaseId);
                set(state => {
                  state.useCase = createEmptyUsecase(
                    customer.id,
                    generalUseCaseId,
                    false,
                    res.data
                  );
                  state.isLoading = false;
                });
              }
            }
          },
          getUseCase: async (id: string) => {
            set(state => {
              state.isLoading = true;
            });
            const res = await useCaseApi.getUseCase(id);

            set(state => {
              state.isLoading = false;
              state.useCase = res.data;
            });
          },
          getGeneralUseCaseById: async (id: string) => {
            set(state => {
              state.isLoading = true;
            });

            const res = await useCaseGeneralApi.getGeneralUseCase(id);

            set(state => {
              state.isLoading = false;
              state.useCase = res.data;
            });
          },
          updateField: <T extends keyof UseCasesDTO>(field: T, value: UseCasesDTO[T]) => {
            set(state => {
              if (state.lastChangedField !== field) {
                if (state.lastChangedField !== null) {
                  state.undoHistory.push({
                    field: state.lastChangedField,
                    undoValue: state.valueBeforeChange,
                    redoValue: state.useCase[state.lastChangedField] as string | number | boolean,
                  });
                }

                state.lastChangedField = field;
                state.valueBeforeChange = <string | number | boolean>state.useCase[field];

                state.redoHistory = [];
              }

              state.isChanged = true;
              state.useCase[field] = value;
            });

            // use debounce callback to accumulate changes to the field and combine them in a single action
            undoDebounceCallback(() => {
              set(state => {
                state.undoHistory.push({
                  field: field,
                  undoValue: state.valueBeforeChange,
                  redoValue: state.useCase[state.lastChangedField] as string | number | boolean,
                });
                state.redoHistory = [];

                state.lastChangedField = null;
                state.valueBeforeChange = null;
              });
            });
          },
          undo: () => {
            set(state => {
              if (state.lastChangedField !== null) {
                // called before undoDebounceCallback finished
                undoDebounceCallback.cancel();

                state.redoHistory = [
                  {
                    field: state.lastChangedField,
                    undoValue: state.valueBeforeChange,
                    redoValue: state.useCase[state.lastChangedField] as string | number | boolean,
                  },
                ];

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                state.useCase[state.lastChangedField] = state.valueBeforeChange;

                state.lastChangedField = null;
                state.valueBeforeChange = null;
              } else {
                // called when there are no pending changes through undoDebounceCallback
                const action = state.undoHistory.pop();

                if (action) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  state.useCase[action.field] = action.undoValue;

                  state.redoHistory.push(action);

                  state.isChanged = true;
                }
              }
            });
          },
          redo: () => {
            set(state => {
              const action = state.redoHistory.pop();

              if (action) {
                // it should not be possible to get here if there is a pending change through undoDebounceCallback
                // so no cancel is necessary

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                state.useCase[action.field] = action.redoValue;

                state.undoHistory.push(action);

                state.isChanged = true;
              }
            });
          },
          saveChanges: async () => {
            const useCase = get().useCase;

            const response = useCase.id
              ? await useCaseApi.updateUseCase(useCase.id, useCase)
              : await useCaseApi.createUseCase(useCase);

            if (
              response.status !== HttpStatusCode.Ok &&
              response.status !== HttpStatusCode.Created
            ) {
              throw new Error();
            }

            if (response.status === HttpStatusCode.Created) {
              await useUseCaseStore.getState().getAllUseCases();

              set(state => {
                state.isChanged = false;
              });

              return response.data.id;
            }

            set(state => {
              state.isChanged = false;
              state.useCases = state.useCases.map(currentUseCase => {
                return currentUseCase.id === useCase.id ? { ...useCase } : currentUseCase;
              });
            });

            return null;
          },
          setFilterQuery: (filterQuery: string) =>
            set(state => {
              state.filterQuery = filterQuery;
            }),
          setFilter: (filter: Partial<SelectionsUserCaseFilter>) =>
            set(state => {
              state.filter = { ...state.filter, ...filter };
            }),
          removeFilterKey: (field: keyof SelectionsUserCaseFilter) =>
            set(state => {
              const { [field]: _value, ...clearedFilter } = get().filter; // eslint-disable-line @typescript-eslint/no-unused-vars
              state.filter = clearedFilter;
            }),
          resetFilter: () =>
            set(state => {
              state.filter = emptyFilter;
              state.filterQuery = '';
            }),
          setSortData: (header: TableHeader) =>
            set(state => {
              state.sortData = {
                keyToSort: header.field,
                direction:
                  header.field === state.sortData.keyToSort
                    ? state.sortData.direction === SortDirection.ASC
                      ? SortDirection.DESC
                      : SortDirection.ASC
                    : SortDirection.DESC,
              };
            }),
          setFieldToggle: async (id: string, checked: boolean, fieldName: string) => {
            const useCases = get().useCases;
            const updatedUsecases = useCases.map(usecase =>
              usecase.id === id ? { ...usecase, [fieldName]: checked } : usecase
            );
            const updatedUseCase = updatedUsecases.find(usecase => usecase.id === id);

            if (!updatedUseCase) return;

            if (updatedUseCase.useCaseGeneralId) {
              await useCaseApi.updateUseCase(id, updatedUseCase);

              set(state => {
                state.useCases = updatedUsecases;
              });

              return;
            }

            if (fieldName === 'selektionFavorite') {
              const customerId = useUserStore.getState().selectedUser.id;
              const useCaseId = updatedUseCase.id;

              if (updatedUseCase.useCaseGeneralId) {
                // SpecificUseCase with useCaseGeneralId
                await new UseCaseApi().updateUseCase(useCaseId, updatedUseCase);
              } else if (updatedUseCase.customerId) {
                // UserGeneralUseCase without useCaseGeneralId, but with customerId
                await new UseCaseUserGeneralApi().updateGeneralUserUseCase(
                  useCaseId,
                  updatedUseCase
                );
              } else {
                // GeneralUseCase without useCaseGeneralId && without customerId
                if (checked) {
                  await new RelationshipApi().createFavoriteGeneralUseCaseCustomerRelationship(
                    customerId,
                    useCaseId
                  );
                } else {
                  await new RelationshipApi().deleteFavoriteGeneralUseCaseCustomerRelationship(
                    customerId,
                    useCaseId
                  );
                }
              }

              set(state => {
                state.useCases = updatedUsecases;
              });

              return;
            }

            if (fieldName === 'selektionWorkshop') {
              const customer = useUserStore.getState().selectedUser;

              if (customer.id) {
                const savedUseCase = (
                  await useCaseApi.createUseCase(
                    createEmptyUsecase(customer.id, id, true, updatedUseCase)
                  )
                ).data;

                const updatedUsecases = useCases.map(usecase =>
                  usecase.id === savedUseCase.useCaseGeneralId
                    ? { ...usecase, ...savedUseCase }
                    : usecase
                );

                set(state => {
                  state.useCases = updatedUsecases;
                });
              }
            }
          },
          isUseCase: (useCaseId: string) => {
            const useCases = get().useCases;

            return (
              useCases.find(u => u.id === useCaseId)?.useCaseGeneralId ||
              useCases.find(u => u.id === useCaseId)?.useCaseUserGeneralId
            );
          },
          addUseCase: (useCase: UseCasesDTO) => {
            set(state => {
              state.useCases = [...state.useCases, useCase];
            });
          },
          changeIsKiUseCase: async (useCase: UseCasesDTO) => {
            const val = useCase['ki-usecase'] === 'ja' ? 'nein' : 'ja';
            const newUseCase = { ...useCase, 'ki-usecase': val };
            await useCaseGeneralApi.updateGeneralUseCase(newUseCase.id, newUseCase);
            set(state => {
              state.useCases = state.useCases.filter(useCase => useCase.id !== newUseCase.id);
              state.useCases.push(newUseCase);
            });
          },
        })
      )
    )
  )
);

type UseCaseStoreState = ExtractState<typeof useUseCaseStore>;

export const selectUseCases = (state: UseCaseStoreState) => state.useCases;
export const selectUseCase = (state: UseCaseStoreState) => state.useCase;

export const selectFieldValues = (field: keyof UseCasesDTO) => (state: UseCaseStoreState) => {
  return [...new Set(state.useCases.map(u => u[field]))];
};

export const selectArrayFieldValues = (field: keyof UseCasesDTO) => (state: UseCaseStoreState) => {
  const values: (string | number | boolean)[] = [];

  state.useCases.forEach(usecase => {
    const value = usecase[field];
    if (Array.isArray(value)) {
      value.forEach(item => {
        if (!values.includes(item)) {
          values.push(item);
        }
      });
    } else {
      if (!values.includes(value)) {
        values.push(value);
      }
    }
  });

  return values;
};

export const selectSumWeightingValues =
  (fields: KeysWithValuesOfType<UseCasesDTO, number>[]) => (state: UseCaseStoreState) => {
    const arrayOfFields = fields.map(field => state.useCase[field]);

    const percentageSumWeighting = Math.round(
      arrayOfFields.reduce((current, previous) => current + previous) * 100
    );

    const weighting = Math.floor((percentageSumWeighting * 100) / 100);

    return weighting;
  };

export const selectSumRatingValues =
  (fields: KeysWithValuesOfType<UseCasesDTO, number>[]) => (state: UseCaseStoreState) => {
    const arrayOfFields = fields.map(field => state.useCase[field]);

    const averageOfFields =
      arrayOfFields.reduce((current, previous) => current + previous) / fields.length;

    function roundNumber(num: number, scale: number) {
      if (!('' + num).includes('e')) {
        return +(Math.round(Number(num + 'e+' + scale)) + 'e-' + scale);
      } else {
        const arr = ('' + num).split('e');
        let sig = '';
        if (+arr[1] + scale > 0) {
          sig = '+';
        }
        const i = +arr[0] + 'e' + sig + (+arr[1] + scale);
        const j = Math.round(Number(i));
        const k = +(j + 'e-' + scale);
        return k;
      }
    }

    return roundNumber(averageOfFields, 2);
  };

export const selectFilterField =
  (field: keyof SelectionsUserCaseFilter) => (state: UseCaseStoreState) =>
    state.filter[field] || [];

export const selectSortedAndFilteredUseCases =
  (queryIndex: Fuse<UseCasesDTO>) =>
  (state: UseCaseStoreState): UseCasesDTO[] => {
    const useCases =
      state.filterQuery.length > 0
        ? queryIndex.search(state.filterQuery).map(i => i.item)
        : state.useCases;

    const filters = Object.entries(state.filter) as [keyof UseCasesDTO, unknown[]][];

    const filteredUseCases = useCases.filter(u =>
      filters.every(([field, values]) => {
        if (field.toString() !== 'selektionen')
          // The values are , sepereated, so they need to be split to an array and then checked, if values are included in the UC
          return (
            values.length === 0 ||
            values.some(v =>
              u[field as keyof UseCasesDTO]
                .toString()
                .split(',')
                .includes(v as string)
            )
          );
        else return values.every(val => u[val as keyof UseCasesDTO]);
      })
    );
    if (state.sortData.direction === SortDirection.ASC) {
      return filteredUseCases.sort((a, b) =>
        a[state.sortData.keyToSort] > b[state.sortData.keyToSort] ? 1 : -1
      );
    } else {
      return filteredUseCases.sort((a, b) =>
        a[state.sortData.keyToSort] > b[state.sortData.keyToSort] ? -1 : 1
      );
    }
  };

export const selectCanUndo = (state: UseCaseStoreState) =>
  state.lastChangedField !== null || state.undoHistory.length > 0;
export const selectCanRedo = (state: UseCaseStoreState) => state.redoHistory.length > 0;

export const selectUseCaseField =
  <T extends keyof UseCasesDTO>(field: T) =>
  (state: UseCaseStoreState) =>
    state.useCase[field];
