import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Flex,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Button,
} from '@chakra-ui/react';
import { FaUsers, FaUser } from 'react-icons/fa';
import { UserOutputDTO } from 'src/api';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { AddUpdateCustomerForm } from './AddUpdateCustomerForm';
import {
  selectUsers,
  selectSelectedUser,
  useUserStore,
  UserModeEnum,
} from 'src/store/useUserStore';
import { useNavigate } from 'react-router-dom';
import { UserProfile, UserProfileEnum } from './UserProfile';
import { UserListItem } from './UserListItem';
import { useCustomerSelectorMode } from './useCustomerSelectorMode';

const UserModeSelectorPage = memo(function UserModeSelectorPage() {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<UserOutputDTO>(null);
  const { allgemeinMode, customerMode, isSelectedMode } = useCustomerSelectorMode();

  const getUsers = useUserStore(state => state.getCustomerUsers);
  const users = useUserStore(selectUsers);
  const setUser = useUserStore(state => state.setSelectedUser);
  const resetUser = useUserStore(state => state.resetSelectedUser);
  const setUserMode = useUserStore(state => state.setUserMode);
  const selectUser = useUserStore(selectSelectedUser);

  useEffect(() => {
    getUsers();
  }, []);

  const handleUserSelect = (user: UserOutputDTO) => {
    setSelectedUser(user);
    setUser(user);
    setUserMode(UserModeEnum.userSpezifisch);
  };
  const handleSelectMode = (mode: UserModeEnum) => {
    setUserMode(mode);
    resetUser();
    setSelectedUser(null);
  };

  const handleNavigation = () => {
    if (customerMode || allgemeinMode) {
      navigate('/');
    }
  };
  return (
    <Box>
      <Container maxWidth="container.md" my="3rem">
        <Flex direction="column" gap="3rem">
          <Box alignSelf="end">
            <AddUpdateCustomerForm />
          </Box>
          <Flex
            onClick={() => handleSelectMode(UserModeEnum.allgemein)}
            cursor="pointer"
            gap="4rem"
            bg="white"
            border={allgemeinMode == UserModeEnum.allgemein ? '1.5px solid #c8b600' : 'none'}
            p="2rem"
            data-test="allgemein-box"
            boxShadow="md"
            rounded="md">
            <Icon color="black" as={FaUsers} boxSize="16" />
            <Box>
              <Text fontSize="lg" fontWeight="bold">
                Allgemein
              </Text>
              <Text fontSize="sm">Die Änderungen werden auf alle Kunden angewendet.</Text>
            </Box>
          </Flex>
          <Box
            border={
              customerMode?.mode == UserModeEnum.userSpezifisch ? '1.5px solid #c8b600' : 'none'
            }
            p="2rem"
            bg="white"
            boxShadow="md"
            rounded="md">
            <Flex gap="5rem">
              <Icon color="black" as={FaUser} boxSize="12" />
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Kundenspezifisch
                </Text>
                <Text fontSize="sm">
                  Die Änderungen werden speziell auf ausgewählte Kunden angewendet.
                </Text>
              </Box>
            </Flex>
            <Box>
              <Menu>
                <MenuButton
                  _expanded={{ borderColor: 'accent' }}
                  minWidth="100%"
                  px="8"
                  py="4"
                  mt="3rem"
                  borderRadius="md"
                  data-test="dropdown-button"
                  borderWidth="1px">
                  <Flex alignItems="center" justifyContent="space-between">
                    {selectedUser ? (
                      <UserProfile name={selectedUser?.name} type={UserProfileEnum.standard} />
                    ) : (
                      <Text color="gray">Wählen Sie bitte einen Kunden aus</Text>
                    )}
                    <ChevronDownIcon boxSize="8" />
                  </Flex>
                </MenuButton>
                <MenuList
                  maxHeight="200px"
                  overflow="scroll"
                  className="menuWidth"
                  data-test="customer-dropdown">
                  {users.map(user => (
                    <UserListItem
                      key={user.id}
                      user={user}
                      onSelect={handleUserSelect}
                      isSelected={selectUser?.id === user.id}
                    />
                  ))}
                </MenuList>
              </Menu>
            </Box>
          </Box>
          <Button
            onClick={handleNavigation}
            isDisabled={!isSelectedMode}
            width="8rem"
            alignSelf="end"
            data-test="next-button"
            variant="accent">
            Next
          </Button>
        </Flex>
      </Container>
    </Box>
  );
});

export default UserModeSelectorPage;
