import React, { memo } from 'react';
import { Box, Container, Flex, HStack } from '@chakra-ui/react';
import { NavLink, useParams } from 'react-router-dom';
import { useUseCaseStore } from '../../../../store/useUseCaseStore';
export const DetailsTabNav = memo(function DetailsTabNav() {
  const workShopIsToggle = useUseCaseStore(state => state.useCase.selektionWorkshop);
  const deepDiveIsToggle = useUseCaseStore(state => state.useCase.selektionDeepdive);
  const { useCaseId } = useParams();

  return (
    <Box
      bg="primary"
      color="white
    ">
      <Container maxW="container.xl" p={8}>
        <HStack spacing="150px" justifyContent="center">
          <Flex gap="100px" className="tab">
            {(workShopIsToggle || deepDiveIsToggle) && (
              <NavLink
                to={`/usecases/${useCaseId}/details`}
                className={({ isActive }) => (isActive ? 'tab--activated' : 'tab--deactivated')}>
                Details
              </NavLink>
            )}
            {workShopIsToggle && (
              <NavLink
                to={`/usecases/${useCaseId}/workshop`}
                className={({ isActive }) => (isActive ? 'tab--activated' : 'tab--deactivated')}>
                Workshop
              </NavLink>
            )}
            {deepDiveIsToggle && (
              <NavLink
                to={`/usecases/${useCaseId}/deepdive`}
                className={({ isActive }) => (isActive ? 'tab--activated' : 'tab--deactivated')}>
                Deep dive
              </NavLink>
            )}
          </Flex>
        </HStack>
      </Container>
    </Box>
  );
});
