import { UseCaseGeneralDTO } from 'src/api';

export const emptyGeneralUseCase: UseCaseGeneralDTO = {
  id: null,
  usecaseNumber: null,
  usecaseExternerKundenkontakt: true,
  usecaseUsecaseTitel: '',
  usecaseFachlicherHintergrund: '',
  usecaseChance: '',
  'ki-usecase': '',
  industrieTags: [],
  industriespezifischTags: [],
  technologienTags: [],
  geschaeftsfelderTags: [],
};
