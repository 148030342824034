import { MenuItem } from '@chakra-ui/react';
import { memo } from 'react';
import { UserOutputDTO } from 'src/api';
import { UserProfile, UserProfileEnum } from './UserProfile';

type UserListItemProps = {
  user: UserOutputDTO;
  isSelected: boolean;
  onSelect: (user: UserOutputDTO) => void;
};

export const UserListItem = memo(function UserListItem({
  user,
  isSelected,
  onSelect,
}: UserListItemProps) {
  return (
    <MenuItem
      data-test="dropdown-items"
      color="black"
      key={user.id}
      onClick={() => onSelect(user)}
      backgroundColor={isSelected ? 'gray.50' : 'white'}>
      <UserProfile type={UserProfileEnum.standard} name={user.name} />
    </MenuItem>
  );
});
