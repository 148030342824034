import { isReadOnly } from 'src/auth/scope';
import { useUserStore, selectUserMode, UserModeEnum } from 'src/store/useUserStore';

export function useCustomerSelectorMode() {
  const readOnly = isReadOnly();

  let allgemeinMode: UserModeEnum;
  let customerMode: { mode: UserModeEnum; id: string };

  const selectedMode = useUserStore(selectUserMode);
  const userSelectedMode = readOnly
    ? 'userSpezifisch'
    : selectedMode || localStorage.getItem('userMode');

  if (userSelectedMode == UserModeEnum.allgemein) {
    allgemeinMode = userSelectedMode;
  }
  if (userSelectedMode == UserModeEnum.userSpezifisch) {
    customerMode = { mode: userSelectedMode, id: localStorage.getItem('userSelectedId') };
  }

  const isSelectedMode = Boolean(userSelectedMode);

  return { allgemeinMode, customerMode, isSelectedMode };
}
