import { Box, Container } from '@chakra-ui/react';
import { TitleInput } from '../controls/TitleInput';
import { TextareaInput } from '../controls/TextareaInput';
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useCustomerSelectorMode } from '../../UserSelector/useCustomerSelectorMode';

export const DescriptionSection = memo(function DescriptionSection() {
  const { customerMode } = useCustomerSelectorMode();

  const tab = useLocation().pathname.split('/')[3];
  return (
    <Box bgColor="primary" py="5rem">
      <Container maxW="container.md">
        <Box bgColor="white" py="1rem" px="7rem" rounded="md">
          <TitleInput field="usecaseUsecaseTitel" />
          <TextareaInput
            isFieldDisabled={Boolean(customerMode)}
            field="usecaseFachlicherHintergrund"
            title="Fachlicher Hintergrund"
          />
          <TextareaInput
            isFieldDisabled={Boolean(customerMode)}
            field="usecaseChance"
            title="Chance"
          />
          {tab !== 'details' && (
            <>
              <TextareaInput field="usecaseSoftwareloesung" title="Software Lösung" />
              <TextareaInput field="usecaseErgaenzendeInformation" title="Ergänzende Information" />
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
});
