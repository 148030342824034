import { Configuration, ContactDTO, ContactIds, RelationshipApi } from '../api';
import { combine, devtools } from 'zustand/middleware';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { userApi } from 'src/store/useUserStore';

export const relationshipApi = new RelationshipApi(new Configuration({ basePath: '/api' }));

export interface CustomerContact {
  customerId: string;
  contacts: ContactDTO[];
}

export const useRelationshipStore = create(
  devtools(
    immer(
      combine(
        {
          contacts: [] as ContactDTO[],
          isLoading: false,
          customerContacts: [] as CustomerContact[],
          error: null,
        },
        (set, get) => ({
          createCustomerContactRelationship: async (customerId: string, contactIds: ContactIds) => {
            try {
              set({ isLoading: true });
              const res = await relationshipApi.updateCustomerContacts(customerId, {
                contactids: contactIds,
              } as ContactIds);

              set({ isLoading: false });
              return res.data;
            } catch (err: any) {
              set({ error: err.message });
            }
          },
          addContactToList: (contact: ContactDTO) => {
            set(state => {
              state.contacts = state.contacts.some(c => c.id === contact.id)
                ? [...state.contacts]
                : [...state.contacts, { ...contact }];
            });
          },
          deleteCustomerContactRelationship: async (customerId: string, contactIds: string) => {
            try {
              set({ isLoading: true });
              const contacts = get().contacts.filter(c => c.id !== contactIds);
              await relationshipApi.updateCustomerContacts(customerId, {
                contactids: contacts,
              } as ContactIds);
              set(state => {
                state.isLoading = false;
                state.contacts = contacts;
              });
            } catch (err: any) {
              set({ error: err.message });
            }
          },
          setCustomerContacts: async () => {
            const customerRes = (await userApi.getAllCustomerUsers()).data;
            console.log('Hello World');
            customerRes.forEach(async customer => {
              const contact: CustomerContact = {
                customerId: customer.id,
                contacts: [],
              };

              const consultants = (await relationshipApi.getCustomerContacts(customer.id)).data;
              contact.contacts = consultants;

              set(state => {
                const currentContacts = state.customerContacts;
                const filteredContacts = currentContacts.filter(
                  customerContact => customerContact.customerId !== contact.customerId
                );
                filteredContacts.push(contact);

                state.customerContacts = filteredContacts;
              });
            });
          },
          emptyContacts: () => {
            set({ contacts: [] });
          },
        })
      )
    )
  )
);
