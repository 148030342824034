import { Box, Flex } from '@chakra-ui/react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import React, { memo } from 'react';

export const Layout = memo(function Layout() {
  return (
    <Flex flexDirection="column" minHeight="100vh">
      <ScrollRestoration />
      <Box flex="1">
        <Outlet />
      </Box>
    </Flex>
  );
});
