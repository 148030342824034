import { Box, Container, Stack } from '@chakra-ui/react';
import Selektion from './details/Selektion';
import Reference from './details/Reference';
import UseCase from './details/UseCase';
import React, { memo } from 'react';
import { useCustomerSelectorMode } from '../../UserSelector/useCustomerSelectorMode';
import { isAdmin, isSalesUser } from '../../../../auth/scope';

export const DetailsSection = memo(function DetailsSection() {
  const { customerMode } = useCustomerSelectorMode();
  const salesUser = isSalesUser();
  const adminUser = isAdmin();

  return (
    <Box
      py={20}
      backgroundImage="url('/images/business-people-blur.png')"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat">
      {adminUser && (
        <Container maxW="container.md">
          {customerMode && (
            <Stack direction={['column', 'row']} gap={20}>
              <Selektion />
              <Reference />
            </Stack>
          )}
          <Stack direction={['column', 'row']} gap={20} marginTop={20}>
            <UseCase />
          </Stack>
        </Container>
      )}
      {!adminUser && (
        <Container maxW="container.md">
          <Stack direction={['column', 'row']} gap={20}>
            {customerMode && !salesUser && <Selektion />}
            {customerMode && salesUser && <Reference />}
            <UseCase />
          </Stack>
        </Container>
      )}
    </Box>
  );
});
