import { KeysWithValuesOfType } from '../../../../types';
import { UseCaseDTO } from '../../../../api';
import { Flex, Input, Link, Text } from '@chakra-ui/react';
import React, { ElementRef, forwardRef, memo } from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useUpdateField } from '../useUpdateField';
import { isAdmin } from '../../../../auth/scope';

type LinkInputProps = {
  field: KeysWithValuesOfType<UseCaseDTO, string>;
  title?: string;
};

export const LinkInput = memo(
  forwardRef<ElementRef<'input'>, LinkInputProps>(function TextInput(props, ref) {
    const { field, title } = props;

    const { value, update } = useUpdateField(field);
    const adminUser = isAdmin();

    const updateLink = (value: string) => {
      if (!value.includes('http')) {
        const updatedValue = 'https://'.concat(value);
        update(updatedValue);
        return;
      }
      update(value);
    };

    return (
      <Flex justifyContent="space-between" alignItems="center">
        {title && <Text>{title}</Text>}
        {!adminUser && (
          <Link href={value as string} isExternal>
            Demo <ExternalLinkIcon mx="2px" />{' '}
          </Link>
        )}
        {adminUser && (
          <Input
            ref={ref}
            {...props}
            value={value ?? ''}
            onChange={e => updateLink(e.target.value)}
            variant="outlined"
            textAlign="right"
            marginLeft={20}
            _hover={{ bg: 'blackAlpha.100' }}
            _readOnly={{ _hover: { bg: 'none' } }}
          />
        )}
      </Flex>
    );
  })
);
