import React from 'react';
import { IconButton, Container, Tooltip, Text, Flex, Box } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { AddUpdateCustomerForm } from '../UserSelector/AddUpdateCustomerForm';
import { MdPersonAdd } from 'react-icons/md';

export const WelcomeAdmin = () => {
  const navigate = useNavigate();

  const handleCreateUseCase = () => {
    navigate('/usecases/general/create');
  };

  return (
    <Box>
      <Container maxW="container.xl" p={20}>
        <Flex align="center">
          <Tooltip hasArrow label="Neuen Use-Case erstellen" bg="grey">
            <IconButton
              size="lg"
              isRound={true}
              variant="accent"
              onClick={handleCreateUseCase}
              icon={<AddIcon />}
              aria-label="Add General Use-Case"
              mb={5}
            />
          </Tooltip>
          <Text ml={5} mt={-4}>
            Neuen Use-Case erstellen
          </Text>
        </Flex>
        <Flex align="center">
          <AddUpdateCustomerForm icon={<MdPersonAdd />} />
          <Text ml={5} mt={2}>
            Neuen User erstellen
          </Text>
        </Flex>
      </Container>
    </Box>
  );
};
