import React, { memo } from 'react';
import { Avatar, Flex, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { ContactDTO } from '../../../../api';
import { useRelationshipStore } from '../../../../store/useRelationshipStore';
import { useUserStore } from 'src/store/useUserStore';

export const SelectedContactTiles = memo(function FilterTiles({
  customerId,
  contactList,
}: {
  customerId: string;
  contactList: ContactDTO[];
}) {
  const deleteCustomerContactRelationship = useRelationshipStore(
    state => state.deleteCustomerContactRelationship
  );

  const removeContact = useUserStore(state => state.removeContact);

  const remove = (contact: ContactDTO) => {
    deleteCustomerContactRelationship(customerId, contact.id);
    removeContact(contact.id);
  };

  return (
    <Flex justify="flex-start" gap="10px" wrap="wrap">
      {contactList.map(contact => (
        <Flex
          gap={3}
          key={contact.id}
          as="button"
          align="center"
          borderRadius="md"
          className="contactTiles"
          onClick={() => remove(contact)}>
          <Avatar size="sm" src="./images/Default_profile.png" />
          <Flex direction="column" justify="flex-start">
            <Text fontSize={'lg'}>{contact.name}</Text>
            <Text fontSize={'xs'}>{contact.email}</Text>
          </Flex>
          {<CloseIcon boxSize="2" color="accent" />}
        </Flex>
      ))}
    </Flex>
  );
});
