import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  variants: {
    accent: {
      bg: 'accent',
      color: 'white',
      rounded: '0',
      p: '6',
      clipPath: 'polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0% 100%)',
      _hover: {
        bg: 'accentLight',
      },
      _disabled: {
        bg: 'secondary',
        _hover: {
          bg: 'secondary !important',
        },
      },
    },
    accentLeft: {
      bg: 'accent',
      color: 'white',
      rounded: '0',
      p: '6',
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 10px 100%, 0% calc(100% - 10px))',
      _hover: {
        bg: 'accentLight',
      },
      _disabled: {
        bg: 'secondary',
        _hover: {
          bg: 'secondary !important',
        },
      },
    },
    noaccent: {
      bg: 'accent',
      color: 'white',
      rounded: '0',
      p: '6',
      _hover: {
        bg: 'accentLight',
      },
      _disabled: {
        bg: 'secondary',
        _hover: {
          bg: 'secondary !important',
        },
      },
    },
    tile: {
      fontWeight: 'normal',
      bg: 'primaryDark',
      color: 'white',
      _hover: {
        opacity: '0.8',
      },
      _focus: {
        opacity: '0.8',
      },
    },
  },
});
