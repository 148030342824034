interface IndustrieOption {
  label: string;
  value: string;
}

export const industrieOptions: IndustrieOption[] = [
  {
    label: 'Automotive and Transportation',
    value: 'Automotive and Transportation',
  },
  {
    label: 'Banking',
    value: 'Banking',
  },
  {
    label: 'Cross Industries',
    value: 'Cross Industries',
  },
  {
    label: 'Digital Experience',
    value: 'Digital Experience',
  },
  {
    label: 'DnA',
    value: 'DnA',
  },
  {
    label: 'Health',
    value: 'Health',
  },
  {
    label: 'Insurance',
    value: 'Insurance',
  },
  {
    label: 'Life Sciences',
    value: 'Life Sciences',
  },
  {
    label: 'Lottery',
    value: 'Lottery',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Microsoft',
    value: 'Microsoft',
  },
  {
    label: 'Public',
    value: 'Public',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Salesforce',
    value: 'Salesforce',
  },
  {
    label: 'Übergreifend',
    value: 'Übergreifend',
  },
];
