import { defineStyleConfig } from '@chakra-ui/react';

export const Textarea = defineStyleConfig({
  baseStyle: {
    resize: 'none',
  },
  variants: {
    outline: {
      p: '1rem',
      borderColor: 'accent',
      shadow: 'none !important',
      _focus: {
        borderColor: 'gray.300',
      },
    },
    unstyled: {
      p: '2',
      _hover: {
        bg: 'blackAlpha.100',
      },
      _focus: {
        bg: 'blackAlpha.100',
      },
      _readOnly: {
        bg: 'transparent !important',
      },
    },
  },
});
