import React, { memo } from 'react';
import { Flex } from '@chakra-ui/react';
import { InfoTile } from '../../controls/InfoTile';
import { SwitchInput } from '../../controls/SwitchInput';
import { useUseCaseStore } from 'src/store/useUseCaseStore';
import { LinkInput } from '../../controls/LinkInput';
import { isAdmin } from '../../../../../auth/scope';
import { TextInput } from '../../controls/TextInput';

const Reference = memo(function Reference() {
  const useCase = useUseCaseStore(state => state.useCase);
  const adminUser = isAdmin();

  return (
    <InfoTile title="Referenz">
      <Flex h="100%" justify="space-around" rowGap="8" direction="column">
        <SwitchInput field="referenceAvailable" title="Referenz vorhanden" />
        <SwitchInput field="referenceExecuted" title="Bereits durchgeführt" />
        {(useCase.referenceContact || adminUser) && (
          <TextInput field="referenceContact" title={'Ansprechpartner'} />
        )}
        <SwitchInput field="referenceDemoAvailable" title="Demo vorhanden" />
        {useCase.referenceDemoAvailable && (
          <LinkInput field="referenceDemoLink" title={'Demolink'} />
        )}
      </Flex>
    </InfoTile>
  );
});

export default Reference;
