import React, { memo } from 'react';
import { RatingContainer } from './elements/RatingContainer';
import { Flex, VStack } from '@chakra-ui/react';
import RatingInput from './elements/RatingInput';
import { RatingGroup } from './elements/RatingGroup';
import {
  InitialBenefits,
  InitialBenefitsWeightings,
  InitialExpenses,
  InitialExpensesWeightings,
} from './util/RatingFields';
import { RATING_TOOLTIP } from './util/InfoBoxUtil';

export const RatingInitial = memo(function RatingInitial() {
  return (
    <RatingContainer title="Erstbewertung" info={RATING_TOOLTIP}>
      <Flex direction={['column', 'row']}>
        <RatingGroup
          sumField="erstbewertungGesamtbewertungNutzen"
          title="Ertrag"
          ratings={InitialBenefits}
          allWeightings={InitialBenefitsWeightings}>
          <VStack spacing="16" align="stretch">
            <RatingInput
              text="Ertragssteigerung"
              field="erstbewertungErtragssteigerung"
              weighting="erstbewertungErtragssteigerungWeight"
              allWeightings={InitialBenefitsWeightings}
            />
            <RatingInput
              text="Aufwandsreduktion"
              field="erstbewertungAufwandsreduktion"
              weighting="erstbewertungAufwandsreduktionWeight"
              allWeightings={InitialBenefitsWeightings}
            />
            <RatingInput
              text="Customer Experience"
              field="erstbewertungCustomerExperience"
              weighting="erstbewertungCustomerExperienceWeight"
              allWeightings={InitialBenefitsWeightings}
            />
            <RatingInput
              text="Prozessqualität"
              field="erstbewertungProzessqualitaet"
              weighting="erstbewertungProzessqualitaetWeight"
              allWeightings={InitialBenefitsWeightings}
            />
          </VStack>
        </RatingGroup>
        <RatingGroup
          sumField="erstbewertungGesamtbewertungAufwand"
          title="Aufwand"
          ratings={InitialExpenses}
          allWeightings={InitialExpensesWeightings}>
          <VStack spacing="6" align="stretch">
            <RatingInput
              text="Umsetzungszeit"
              field="erstbewertungUmsetzungszei"
              weighting="erstbewertungUmsetzungszeiWeight"
              allWeightings={InitialExpensesWeightings}
            />
            <RatingInput
              text="Change Impact"
              field="erstbewertungChangeImpact"
              weighting="erstbewertungChangeImpactWeight"
              allWeightings={InitialExpensesWeightings}
            />
            <RatingInput
              text="Komplexität"
              field="erstbewertungKomplexitaet"
              weighting="erstbewertungKomplexitaetWeight"
              allWeightings={InitialExpensesWeightings}
            />
            <RatingInput
              text="Kosten IT Betrieb"
              field="erstbewertungKostenItBetrieb"
              weighting="erstbewertungKostenItBetriebWeight"
              allWeightings={InitialExpensesWeightings}
            />
            <RatingInput
              text="Synergie"
              field="erstbewertungSynergie"
              weighting="erstbewertungSynergieWeight"
              allWeightings={InitialExpensesWeightings}
            />
            <RatingInput
              text="Ethische / Regulatorische Risiken"
              field="erstbewertungEthischeRegRisiken"
              weighting="erstbewertungEthischeRegRisikenWeight"
              allWeightings={InitialExpensesWeightings}
            />
          </VStack>
        </RatingGroup>
      </Flex>
    </RatingContainer>
  );
});
