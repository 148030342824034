import { NumberInput, NumberInputField, Box } from '@chakra-ui/react';
import { KeysWithValuesOfType } from '../../../../../../types';
import { UseCaseDTO } from '../../../../../../api';
import { useUpdateField } from '../../../useUpdateField';
import { memo, useState } from 'react';

type RatingWeightingInputProps = {
  weighting: KeysWithValuesOfType<UseCaseDTO, number>;
  allWeightings: KeysWithValuesOfType<UseCaseDTO, number>[];
};

const RatingWeightingInput = memo(function RatingWeightingInput({
  weighting,
}: RatingWeightingInputProps) {
  const { value, update } = useUpdateField(weighting);
  const [inputValue, setInputValue] = useState((value * 100).toString());
  function updateWeightingField(changedValue: string) {
    setInputValue(changedValue);
    const changedValueNumber = Number(changedValue) / 100;
    update(changedValueNumber);
  }

  const properties = {
    cursor: 'pointer',
    borderWidth: '1px',
    borderColor: 'yellow.400',
    borderRadius: 'md',
    outline: 'none',
    _focus: {
      bg: 'white',
      borderColor: 'yellow.400',
      boxShadow: 'none',
    },
  };

  return (
    <Box as="label">
      <NumberInput
        p={0}
        width="16"
        value={inputValue}
        max={100}
        onChange={updateWeightingField}
        {...properties}>
        <NumberInputField data-testid="ratingWeightingInput" textAlign={'center'} p="2" />
      </NumberInput>
    </Box>
  );
});

export default RatingWeightingInput;
