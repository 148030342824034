import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

export const PreviousPageButton = memo(function PreviousPageButton({ url }: { url: string }) {
  const navigate = useNavigate();
  const previousPage = () => {
    navigate(url);
  };
  return (
    <Box bgColor="primary">
      <Container maxW="container.md">
        <IconButton
          size="lg"
          isRound={true}
          fontSize="30px"
          variant="accent"
          icon={<ArrowBackIcon />}
          aria-label="previousPage"
          onClick={previousPage}
        />
      </Container>
    </Box>
  );
});
