import { create } from 'zustand';
import { combine, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import {
  Configuration,
  UserOutputDTO,
  UserApi,
  ContactDTO,
  UserInputDTO,
  RelationshipApi,
} from '../api';
import { ExtractState } from './types';

export const userApi = new UserApi(new Configuration({ basePath: '/api' }));

export enum UserModeEnum {
  allgemein = 'allgemein',
  userSpezifisch = 'userSpezifisch',
}

export const useUserStore = create(
  devtools(
    immer(
      combine(
        {
          contacts: [] as ContactDTO[],
          allContacts: [] as UserOutputDTO[],
          users: [] as UserOutputDTO[],
          user: {} as UserOutputDTO,
          selectedUser: {} as UserOutputDTO,
          isLoading: true,
          userMode: null as UserModeEnum,
          adminUser: [] as UserOutputDTO,
          consultantUser: [] as UserOutputDTO,
          customerUser: [] as UserOutputDTO,
          salesUser: [] as UserOutputDTO,
        },
        set => ({
          getUsers: async () => {
            const customerUsers = (await userApi.getAllCustomerUsers()).data;
            const adminUsers = (await userApi.getAllAdminUsers()).data;
            const consultantUsers = (await userApi.getAllConsultantUsers()).data;
            const salesUsers = (await userApi.getAllSalesUsers()).data;

            const users: UserOutputDTO[] = customerUsers.concat(
              adminUsers,
              consultantUsers,
              salesUsers
            );

            set(state => {
              state.users = users;
            });
          },
          getUserWithId: async (id: string) => {
            const res = await userApi.getUserById(id);
            set(state => {
              state.isLoading = false;
              state.user = res.data;
            });
          },
          getSelectedUser: async (id: string) => {
            const res = await userApi.getUserById(id);

            set(state => {
              state.isLoading = false;
              state.selectedUser = res.data;
            });
          },
          updateUser: async (id: string, User: UserInputDTO) => {
            const res = await userApi.updateUser(id, User);
            const updatedUser = res.data;

            set(state => {
              state.isLoading = false;
              state.users = state.users.map(existingUser => {
                return existingUser.id === updatedUser.id ? { ...updatedUser } : existingUser;
              });
            });
          },
          createUser: async (user: UserInputDTO) => {
            const res = await userApi.createUser(user);
            const newUser = res.data;

            set(state => {
              state.isLoading = false;
              state.users = [...state.users, { ...newUser }];
            });
          },
          setUserMode: (mode: UserModeEnum) => {
            set(state => {
              state.userMode = mode;
              if (mode === UserModeEnum.allgemein) {
                localStorage.setItem('userMode', mode);
                localStorage.setItem('userSelectedId', null);
              } else {
                localStorage.setItem('userMode', mode);
                localStorage.setItem('userSelectedId', state.selectedUser.id);
              }
            });
          },
          setSelectedUser: (user: UserOutputDTO) => {
            set(state => {
              state.selectedUser = user;
            });
          },
          setUserOnLogin: async (id: string) => {
            localStorage.setItem('userSelectedId', id);

            const res = await userApi.getUserById(id);

            set(state => {
              state.isLoading = false;
              state.selectedUser = res.data;
            });
          },
          resetSelectedUser: () => {
            set(state => {
              state.selectedUser = null;
            });
          },
          getContactsByUserId: async (userId: string) => {
            const res = await new RelationshipApi().getCustomerContacts(userId);
            set(state => {
              state.isLoading = true;
              state.contacts = res.data;
            });
          },
          removeContact: (contactId: string) => {
            set(state => {
              state.contacts = state.contacts.filter(contact => contact.id !== contactId);
            });
          },
          setContacts: (contacts: ContactDTO[]) => {
            set({ contacts });
          },
          getAllContacts: async () => {
            const res = await userApi.getAllConsultantUsers();
            const consultants = res.data;
            set({ allContacts: consultants });
          },
          getCustomerUsers: async () => {
            const res = await userApi.getAllCustomerUsers();
            const customers = res.data;
            set({ users: customers });
          },
          deleteUser: async (id: string) => {
            await userApi.deleteUser(id);

            set(state => {
              state.users = state.users.filter(user => user.id !== id);
            });
          },
        })
      )
    )
  )
);

type UseUserStore = ExtractState<typeof useUserStore>;

export const selectUsers = (state: UseUserStore) => state.users;
export const selectSelectedUser = (state: UseUserStore) => state.selectedUser;
export const selectUserMode = (state: UseUserStore) => state.userMode;
