import React, { useState } from 'react';
import { Box, Text, Center, Flex, Input, Button, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { UpdatePasswordApi } from 'src/api';
import authApi from 'src/auth/authApi';
import { HttpStatusCode } from 'axios';
import { useAuth } from 'src/auth/useAuth';
import { useUserStore } from 'src/store/useUserStore';

export default function ChangePassword() {
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const setUserOnLogin = useUserStore(state => state.setUserOnLogin);
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) return <Navigate to={'/login'} replace />;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ password: string; oldPassword: string }>({
    mode: 'onChange',
  });

  const changePasswordConfirm = async ({
    password,
    oldPassword,
  }: {
    password: string;
    oldPassword: string;
  }) => {
    toast.closeAll();

    if (password !== confirmPassword) {
      toast({
        position: 'top-right',
        status: 'error',
        description: 'Passwörter stimmen nicht überein',
      });

      return;
    }

    const res = await new UpdatePasswordApi().updatePasswordOnFirstLogin({
      credentials: {
        username: location.state.email,
        password: oldPassword,
      },
      newPassword: password,
    });

    if (res.status === HttpStatusCode.Ok) {
      authApi().setAccessToken(res.data.accessToken);
      const { user } = useAuth();
      await setUserOnLogin(user.sub);
      navigate('/?status=changed');
    } else
      toast({
        position: 'top-right',
        status: 'error',
        description: 'Bitte Versuche es Nochmal',
      });
  };

  return (
    <Box
      height="100vh"
      bgColor="primary"
      bgImage="url('/images/Background.png')"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat">
      <Flex>
        <Box w="37%" pl="12%" pt="5%">
          <Center mt="10%" pb="10%">
            <Text fontSize="4xl" color="white">
              Einmalpasswort ändern
            </Text>
          </Center>
          <form onSubmit={handleSubmit(changePasswordConfirm)}>
            <Box p={3}>
              <Text fontSize="xl" color="white">
                E-Mail
              </Text>
              <Input
                type="email"
                name="email"
                variant="filled"
                size="sm"
                value={location.state.email}
                disabled={true}
              />
            </Box>

            <Box p={3}>
              <Text fontSize="xl" color="white">
                Altes Passwort
              </Text>
              <Input
                {...register('oldPassword', {
                  required: 'Old Password is required',
                })}
                type="password"
                name="oldPassword"
                variant="filled"
                size="sm"
                border={errors.oldPassword ? '2px' : undefined}
                borderColor={errors.oldPassword ? 'red' : undefined}
              />
              {errors.oldPassword && (
                <Text fontSize="sm" color="red">
                  {errors.oldPassword.message}
                </Text>
              )}
            </Box>

            <Box p={3}>
              <Text fontSize="xl" color="white">
                Neues Passwort
              </Text>
              <Input
                {...register('password', {
                  required: 'Password is required',
                  pattern: /(?=.*[a-z])(?=.*[A-Z])/,
                  minLength: 8,
                })}
                type="password"
                name="password"
                variant="filled"
                size="sm"
                border={errors.password ? '2px' : undefined}
                borderColor={errors.password ? 'red' : undefined}
              />
              {errors.password && (
                <Text fontSize="sm" color="red">
                  {errors.password.message}
                </Text>
              )}
            </Box>
            <Box p={3}>
              <Text fontSize="xl" color="white">
                Passwort bestätigen
              </Text>
              <Input
                type="password"
                name="password"
                variant="filled"
                size="sm"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              <Text fontSize="s" color="white" mt={3}>
                Das Passwort muss mindestens 8 Zeichen lang sein, und einen Groß- und
                Kleinbuchstaben beinhalten.
              </Text>
            </Box>
            <Flex justify="flex-end">
              <Button
                type="submit"
                variant="accent"
                p={6}
                m={4}
                isDisabled={!isValid || confirmPassword.length < 8}>
                Passwort Ändern
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}
