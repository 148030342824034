import React, { memo, PropsWithChildren, useCallback } from 'react';
import { Box, Flex, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { KeysWithValuesOfType } from '../../../../../../types';
import { UseCaseDTO } from '../../../../../../api';
import { RatingSum } from './RatingSum';
import { selectSumWeightingValues, useUseCaseStore } from '../../../../../../store/useUseCaseStore';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { isConsultantUser, isReadOnly } from 'src/auth/scope';

export const RatingGroup = memo(function RatingGroup({
  sumField,
  title,
  ratings,
  allWeightings,
  children,
}: PropsWithChildren<{
  sumField: KeysWithValuesOfType<UseCaseDTO, number>;
  title: string;
  ratings: KeysWithValuesOfType<UseCaseDTO, number>[];
  allWeightings: KeysWithValuesOfType<UseCaseDTO, number>[];
}>) {
  const readOnly = isReadOnly();
  const sumOfWeightings = useUseCaseStore(useCallback(selectSumWeightingValues(allWeightings), []));
  return (
    <Box w="900px" mx="auto" mb="2rem" px="2rem">
      <Text data-testid="ratingGroupTitle" fontSize="2xl" color="accent" textAlign="left">
        {title}
      </Text>

      <Flex
        p="10"
        rounded="md"
        border="1px"
        borderColor="accent"
        justify="space-between"
        direction="column"
        h="100%">
        <Box>
          <Flex alignItems="left" py="1rem">
            <Text fontSize="14">Bewertung</Text>
            <Spacer />
            <Text fontSize="14">Gewichtung in %</Text>
          </Flex>
        </Box>
        <Box>{children}</Box>

        <Box mt={6}>
          {(!readOnly || isConsultantUser()) && sumOfWeightings !== 100 && (
            <Box
              data-testid="sumOfWeightingsWarning"
              padding="20px"
              borderRadius="5px"
              backgroundColor="red.100"
              minHeight="50px"
              borderLeft="5px solid"
              position="relative"
              borderColor="red.400"
              fontWeight="bold">
              <Text data-testid="sumOfWeightingsWarningText">
                Die Summe Gewichtung muss insgesamt 100 % betragen.
              </Text>
              <Box position="absolute" right="2" top="2">
                <InfoOutlineIcon boxSize="3" />
              </Box>
            </Box>
          )}
          <Stack>
            <Flex mt={6}>
              <Text fontSize="14" py="0.5rem">
                Summe Gewichtungen
              </Text>
              <Spacer />
              <HStack>
                <Text data-testid="sumOfWeightingsText" fontSize="2xl">
                  {sumOfWeightings}
                </Text>
                <Text fontSize="2xl">%</Text>
              </HStack>
            </Flex>
            <hr />
          </Stack>

          <RatingSum
            field={sumField}
            ratings={ratings}
            title={title}
            weightings={allWeightings}></RatingSum>
        </Box>
      </Flex>
    </Box>
  );
});
