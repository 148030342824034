import { Container, Heading, Text, VStack, Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  return (
    <Container mt="8rem">
      <VStack gap="1rem">
        <Text
          fontSize="6xl"
          fontWeight="extrabold"
          bgGradient="linear(to-r, primary, accent)"
          bgClip="text">
          404
        </Text>
        <Flex direction="column" alignItems="center" gap="0.7rem">
          <Heading>Seite Nicht gefunden</Heading>
          <Text>Die von Ihnen gesuchte Seite scheint nicht zu existieren</Text>
          <Button size="md" onClick={() => navigate('usecases')} variant={'accent'}>
            Go to Use-Cases
          </Button>
        </Flex>
      </VStack>
    </Container>
  );
}

export default NotFound;
