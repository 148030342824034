import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys
);

const baseStyle = definePartsStyle({
  button: {
    //Style for Menu Button
  },
  list: {
    borderRadius: 'xl',
    border: 'none',
    bg: 'white',
  },
  item: {
    color: 'black',
    py: '5',
    px: '7',
    _hover: {
      bg: 'rgba(200, 182, 0, 0.3)',
    },
    _focus: {
      bg: 'rgba(200, 182, 0, 0.3)',
    },
  },
  groupTitle: {
    // this will style the text defined by the title prop
    // in the MenuGroup and MenuOptionGroup components
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'center',
    letterSpacing: 'wider',
    opacity: '0.7',
  },
  divider: {
    // this will style the MenuDivider component
    my: '4',
    borderColor: '#707070',
    borderBottom: '0,5px solid',
  },
});

export const Menu = defineMultiStyleConfig({ baseStyle });
