import { ChakraStylesConfig, Select } from 'chakra-react-select';
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

const filledStyles: ChakraStylesConfig = {
  input: provided => ({ ...provided, minWidth: '150px' }),
  inputContainer: provided => ({ ...provided, py: '3' }),
  valueContainer: provided => ({ ...provided, py: 0 }),
  menu: provided => ({ ...provided, width: 'max-content', minWidth: '100%', zIndex: '20' }),
  menuList: provided => ({ ...provided, border: 0, shadow: 'md' }),
  option: provided => ({
    ...provided,
    width: 'auto',
    mx: '2',
    my: '2',
    rounded: 'lg',
  }),
};

export const CustomSelect = forwardRef<
  ElementRef<typeof Select>,
  ComponentPropsWithoutRef<typeof Select>
>(function CustomSelect(props, ref) {
  const { variant, controlShouldRenderValue, ...rest } = props;
  const styles = variant === 'filled' ? filledStyles : {};

  return (
    <Select
      ref={ref}
      {...rest}
      useBasicStyles
      controlShouldRenderValue={controlShouldRenderValue}
      isClearable={false}
      escapeClearsValue={false}
      backspaceRemovesValue={false}
      variant={variant}
      chakraStyles={styles}
    />
  );
});

CustomSelect.defaultProps = {
  controlShouldRenderValue: false,
};
