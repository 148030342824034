import React, { ReactElement, memo, useState } from 'react';
import {
  Button,
  Flex,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Box,
  useDisclosure,
  ModalHeader,
  useToast,
  MenuItem,
  Icon,
  Text,
  IconProps,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { UserInputDTO, UserOutputDTO } from 'src/api';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { useUserStore } from 'src/store/useUserStore';
import { FaUserPlus } from 'react-icons/fa';
import { industrieOptions } from './IndustrieOptions';
import { CustomSelect } from 'src/components/controls/CustomSelect';
import { roles } from 'src/auth/scope';

export const AddUpdateCustomerForm = memo(function AddUpdateCustomerForm({
  isMenuItem = false,
  icon = <AddIcon />,
  updateUser = false,
  id,
}: {
  customer?: UserOutputDTO;
  isMenuItem?: boolean;
  icon?: ReactElement<IconProps, any>;
  updateUser?: boolean;
  id?: string;
}) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();

  const { register, handleSubmit, reset, control, watch } = useForm<UserInputDTO>({});
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const createUser = useUserStore(state => state.createUser);
  const updateUserFunction = useUserStore(state => state.updateUser);
  const getSelectedUser = useUserStore(state => state.getSelectedUser);
  const selectedUser = useUserStore(state => state.selectedUser);
  const deleteUser = useUserStore(state => state.deleteUser);

  const handleSubmission: SubmitHandler<UserInputDTO> = (data: UserInputDTO) => {
    if (confirmPassword !== data.password) {
      toast.closeAll();
      toast({
        status: 'error',
        position: 'top',
        description: 'Passwörter stimmen nicht überein',
      });
      return;
    }
    updateUser ? updateUserFunction(id, data) : createUser(data);
    toast.closeAll();
    toast({
      status: 'success',
      position: 'top',
      description: updateUser
        ? `Nutzer ${data.name} wurde bearbeitet`
        : `Nutzer ${data.name} wurde erstellt`,
    });
    checkSubmission();
  };

  const watchRole = watch('role');

  const checkSubmission = () => {
    reset();
    setConfirmPassword('');
    onClose();
  };

  async function openUpdateModal() {
    await getSelectedUser(id);
    onOpen();
  }

  return (
    <>
      {isMenuItem ? (
        <MenuItem
          icon={<Icon as={FaUserPlus} boxSize="1.5rem" opacity="0.6" mr={2} />}
          onClick={() => onOpen()}>
          Neuen User erstellen
        </MenuItem>
      ) : updateUser ? (
        <Button size="md" variant="noaccent" onClick={openUpdateModal}>
          Nutzer Bearbeiten
        </Button>
      ) : (
        <IconButton
          size="lg"
          isRound={true}
          variant="accent"
          onClick={() => onOpen()}
          icon={icon}
          aria-label="Add Customer"
        />
      )}

      {/* Confirm Delete Modal */}
      {updateUser && (
        <Modal onClose={deleteOnClose} isOpen={deleteIsOpen} size="xl" isCentered>
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
          <ModalContent p={6}>
            <Flex direction="column" align="center">
              <ModalHeader>Nutzer Löschen</ModalHeader>
            </Flex>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" justify="space-between" align="center" gap="20px">
                {selectedUser && <Text>Nutzer {selectedUser.name} wirklich löschen?</Text>}

                <Flex direction="row" justify="space-between" align="center" gap="20px">
                  <Button className="unstyledBtn" onClick={deleteOnClose}>
                    Abbrechen
                  </Button>
                  <Button colorScheme="red" onClick={() => deleteUser(selectedUser.id)}>
                    Löschen
                  </Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Modal onClose={onClose} isOpen={isOpen} size="2xl" isCentered>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />;
        <ModalContent p={6}>
          <ModalHeader>{updateUser ? 'Nutzer bearbeiten' : 'Neuen User'}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(handleSubmission)}>
            <ModalBody>
              <Flex direction="row" justify="space-between" align="center" gap="40px">
                <Box flex={1}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    focusBorderColor="accent"
                    mb={2}
                    defaultValue={updateUser && selectedUser ? selectedUser.name : ''}
                    readOnly={updateUser}
                    {...register('name', { required: true })}
                  />
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    focusBorderColor="accent"
                    mb={2}
                    defaultValue={updateUser && selectedUser ? selectedUser.email : ''}
                    {...register('email', { required: true })}
                  />
                  <FormLabel>Phone</FormLabel>
                  <Input
                    type="tel"
                    focusBorderColor="accent"
                    mb={2}
                    defaultValue={updateUser && selectedUser ? selectedUser.phoneNumber : ''}
                    {...register('phoneNumber', { required: true })}
                  />
                  <FormLabel>Neues Kennwort</FormLabel>
                  <Input
                    type="password"
                    focusBorderColor="accent"
                    mb={2}
                    {...register('password', { required: updateUser ? false : true })}
                  />
                  <FormLabel>Kennwort bestätigen</FormLabel>
                  <Input
                    type="password"
                    focusBorderColor="accent"
                    mb={2}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />

                  <Controller
                    control={control}
                    name="role"
                    defaultValue={updateUser && selectedUser ? selectedUser.role : ''}
                    render={({ field: { onChange, value, ref, name } }) => (
                      <>
                        <FormLabel>Rolle</FormLabel>
                        <CustomSelect
                          placeholder="Rolle"
                          variant="outline"
                          ref={ref}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={true}
                          options={roles}
                          value={roles.find(role => role.value === value)}
                          onChange={(e: any) => onChange(e.value)}
                          name={name}
                          controlShouldRenderValue={true}
                        />
                      </>
                    )}
                  />

                  {watchRole !== 'ADMIN' && (
                    <Controller
                      control={control}
                      name="industrie"
                      defaultValue={updateUser && selectedUser ? selectedUser.industrie : ''}
                      render={({ field: { onChange, value, ref, name } }) => (
                        <>
                          <FormLabel>Industrie</FormLabel>
                          <CustomSelect
                            placeholder="Industrie"
                            variant="outline"
                            ref={ref}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            options={industrieOptions}
                            value={industrieOptions.find(industrie => industrie.value === value)}
                            onChange={(e: any) => onChange(e.value)}
                            name={name}
                            controlShouldRenderValue={true}
                          />
                        </>
                      )}
                    />
                  )}
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter
              display="flex"
              justifyContent={updateUser ? 'space-between' : 'end'}
              gap="10px">
              {updateUser && (
                <Button colorScheme="red" onClick={deleteOnOpen}>
                  Benutzer Löschen
                </Button>
              )}
              <div>
                <Button className="unstyledBtn" onClick={checkSubmission}>
                  Abbrechen
                </Button>
                <Button variant="accent" type="submit">
                  Speichern
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
});
