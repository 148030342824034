import React, { memo } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { KeysWithValuesOfType } from '../../../types';
import { UseCaseDTO } from '../../../api';
import { useUpdateField } from './useUpdateField';

type DeepDiveRatingScaleProps = {
  scale: number;
  fillColor: string;
  strokeColor: string;
  field: KeysWithValuesOfType<UseCaseDTO, number>;
  title: string;
};

const DeepDiveRatingScale = memo(function DeepDiveRatingScale({
  scale,
  fillColor,
  strokeColor,
  field,
  title,
}: DeepDiveRatingScaleProps) {
  const buttons: JSX.Element[] = [];
  const { value, update, readOnly } = useUpdateField(field);

  const onClick = (idx: number) => {
    if (readOnly) return;
    if (!isNaN(idx)) {
      // allow user to click first icon and set rating to zero if rating is already 1
      if (value === 1 && idx === 1) {
        update(0);
      } else {
        update(idx);
      }
    }
  };

  const RatingIcon = ({ fill }: { fill: boolean }) => {
    return (
      <StarIcon
        name="star"
        color={fillColor}
        stroke={strokeColor}
        onClick={() => onClick(0)}
        fillOpacity={fill ? '100%' : '0'}
      />
    );
  };

  const RatingButton = ({ idx, fill }: { idx: number; fill: boolean }) => {
    return (
      <Box
        disabled={readOnly}
        as="button"
        aria-label={`Rate ${idx}`}
        mx={1}
        onClick={() => onClick(idx)}
        _focus={{ outline: 0 }}>
        <RatingIcon fill={fill} />
      </Box>
    );
  };

  for (let i = 1; i <= scale; i++) {
    buttons.push(<RatingButton key={i} idx={i} fill={i <= value} />);
  }

  return (
    <Stack isInline mt={0} justify="center" paddingTop="0.8rem">
      <Box>
        <Text fontSize="sm" width="121px">
          {title}
        </Text>
      </Box>
      <input name="rating" type="hidden" value={value} />
      {buttons}
    </Stack>
  );
});

export default DeepDiveRatingScale;
