import React, { memo, PropsWithChildren } from 'react';
import { Box, Container, Text, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export const RatingContainer = memo(function RatingContainer({
  title,
  info,
  children,
}: PropsWithChildren<{ title: string; info: string }>) {
  return (
    <Box bgColor="primary" py="7rem">
      <Container position={'relative'} maxW="container.md" bgColor="white" py="5rem" rounded="md">
        <Text
          data-testid="ratingContainerTitle"
          mb="10"
          fontSize="3xl"
          fontWeight="semibold"
          color="primary"
          textAlign="center">
          {title}
        </Text>
        <Box position="absolute" right="7" top="10">
          <Tooltip fontSize="xl" label={info}>
            <InfoOutlineIcon color="primary" boxSize="8" />
          </Tooltip>
        </Box>
        {children}
      </Container>
    </Box>
  );
});
