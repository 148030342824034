import { useParams } from 'react-router-dom';
import React, { memo, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useUseCaseStore } from 'src/store/useUseCaseStore';
import { DescriptionSection } from './sections/DescriptionSection';
import { EditorBar } from './EditorBar';
import { isConsultantUser, isReadOnly } from '../../../auth/scope';
import { DetailsTabNav } from './sections/DetailsTabsNav';
import { ScrollToTop } from './ScrollToTop';
import { PreviousPageButton } from './controls/PreviousPageButton';
import { useCustomerSelectorMode } from '../UserSelector/useCustomerSelectorMode';
import SpinnerUI from 'src/components/common/Spinner';
import { RatingInitial } from './sections/ratings/RatingInitial';

export const UseCaseWorkshop = memo(function UseCaseWorkshop() {
  const { useCaseId } = useParams();
  const getUseCase = useUseCaseStore(state => state.getUseCase);
  const { allgemeinMode } = useCustomerSelectorMode();
  const { customerMode } = useCustomerSelectorMode();

  const dataIsLoading = useUseCaseStore(state => state.isLoading);

  const readOnly = isReadOnly();

  useEffect(() => {
    getUseCase(useCaseId);
  }, [useCaseId, allgemeinMode]);

  if (dataIsLoading) return <SpinnerUI />;

  return (
    <Box>
      <DetailsTabNav />
      <PreviousPageButton url={'/usecases'} />
      <DescriptionSection />
      {customerMode && <RatingInitial />}
      <ScrollToTop />
      {(!readOnly || isConsultantUser()) && <EditorBar />}
    </Box>
  );
});
