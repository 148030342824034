import { Flex, Switch, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

type ToggleButtonProps = {
  size?: string;
  color?: string;
  activeColor?: string;
  bgColor?: string;
  fontSize?: string;
  text?: string;
  toggleValue?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  title: string;
  isDisabled: boolean;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  onChange,
  toggleValue,
  title,
  isDisabled,
}) => {
  const [isChecked, setisChecked] = useState(toggleValue);
  const onClickChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setisChecked(prev => !prev);
  };
  return (
    <Flex justifyContent="space-between" alignItems="center">
      {title && <Text>{title}</Text>}
      <Switch
        size="lg"
        isChecked={isChecked}
        onChange={onClickChange}
        isDisabled={isDisabled}></Switch>
    </Flex>
  );
};

export default ToggleButton;
