import { selectUseCaseField, useUseCaseStore } from '../../../store/useUseCaseStore';
import { useCallback } from 'react';
import { isConsultantUser, isReadOnly } from '../../../auth/scope';
import { UseCaseDTO, UseCaseGeneralDTO } from '../../../api';
import {
  selectGeneralUseCaseField,
  useGeneralUseCaseStore,
} from 'src/store/useGeneralUseCaseStore';

export function useUpdateField<T extends keyof UseCaseDTO>(field: T) {
  const value = useUseCaseStore(useCallback(selectUseCaseField(field), [field]));

  const updateField = useUseCaseStore(state => state.updateField);

  const update = useCallback((value: UseCaseDTO[T]) => updateField(field, value), [field]);

  const updateArrayValues = useCallback(
    (value: UseCaseDTO[T]) => {
      updateField(field, value);
    },
    [field]
  );

  const readOnly = isReadOnly() && !isConsultantUser();

  return { value, update, readOnly, updateArrayValues };
}

export function useGeneralUpdateField<T extends keyof UseCaseGeneralDTO>(field: T) {
  const value = useGeneralUseCaseStore(useCallback(selectGeneralUseCaseField(field), [field]));

  const updateField = useGeneralUseCaseStore(state => state.updateField);

  const update = useCallback((value: UseCaseGeneralDTO[T]) => updateField(field, value), [field]);

  const updateArrayValues = useCallback(
    (value: UseCaseGeneralDTO[T]) => {
      updateField(field, value);
    },
    [field]
  );

  const readOnly = isReadOnly();

  return { value, update, readOnly, updateArrayValues };
}
