import { KeysWithValuesOfType } from '../../../../../../types';
import { UseCaseDTO } from '../../../../../../api';

export const RatingOptions = ['1', '2', '3', '4'];
export const InitialBenefits: KeysWithValuesOfType<UseCaseDTO, number>[] = [
  'erstbewertungErtragssteigerung',
  'erstbewertungAufwandsreduktion',
  'erstbewertungCustomerExperience',
  'erstbewertungProzessqualitaet',
];
export const InitialBenefitsWeightings: KeysWithValuesOfType<UseCaseDTO, number>[] = [
  'erstbewertungAufwandsreduktionWeight',
  'erstbewertungErtragssteigerungWeight',
  'erstbewertungCustomerExperienceWeight',
  'erstbewertungProzessqualitaetWeight',
];
export const InitialExpenses: KeysWithValuesOfType<UseCaseDTO, number>[] = [
  'erstbewertungUmsetzungszei',
  'erstbewertungChangeImpact',
  'erstbewertungKomplexitaet',
  'erstbewertungKostenItBetrieb',
  'erstbewertungSynergie',
  'erstbewertungEthischeRegRisiken',
];
export const InitialExpensesWeightings: KeysWithValuesOfType<UseCaseDTO, number>[] = [
  'erstbewertungUmsetzungszeiWeight',
  'erstbewertungChangeImpactWeight',
  'erstbewertungKomplexitaetWeight',
  'erstbewertungKostenItBetriebWeight',
  'erstbewertungSynergieWeight',
  'erstbewertungEthischeRegRisikenWeight',
];
export const WorkshopBenefits: KeysWithValuesOfType<UseCaseDTO, number>[] = [
  'workshopbewertungErtragssteigerung',
  'workshopbewertungAufwandsreduktion',
  'workshopbewertungCustomerExperience',
  'workshopbewertungProzessqualitaet',
];
export const WorkshopBenefitsWeights: KeysWithValuesOfType<UseCaseDTO, number>[] = [
  'workshopbewertungErtragssteigerungWeight',
  'workshopbewertungAufwandsreduktionWeight',
  'workshopbewertungCustomerExperienceWeight',
  'workshopbewertungProzessqualitaetWeight',
];
