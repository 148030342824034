import { createContext, PropsWithChildren, useContext } from 'react';
import Fuse from 'fuse.js';
import { UseCaseDTO } from '../api';
import { selectUseCases, useUseCaseStore } from '../store/useUseCaseStore';
import useStableMemo from '@restart/hooks/useStableMemo';

const QueryIndexContext = createContext<Fuse<UseCaseDTO>>(null);

export const useQueryIndex = () => useContext(QueryIndexContext);

export function QueryIndexProvider({ children }: PropsWithChildren) {
  const useCases = useUseCaseStore(selectUseCases);
  const queryIndex = useStableMemo(
    () =>
      new Fuse(useCases, {
        keys: [
          'usecaseUsecaseTitel',
          { name: 'selektionWorkshop', weight: 0.5 },
          { name: 'industrieTags', weight: 0.5 },
          { name: 'geschaeftsbereichTags', weight: 0.5 },
          { name: 'techClusterTags', weight: 0.5 },
        ],
        threshold: 0,
        ignoreLocation: true,
        distance: 100,
      }),
    [useCases]
  );

  return <QueryIndexContext.Provider value={queryIndex}>{children}</QueryIndexContext.Provider>;
}
