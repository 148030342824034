import { useAuth } from './useAuth';
export enum Scope {
  USE_CASES_READ = 'use-cases:read',
  USE_CASES_WRITE = 'use-cases:write',
  USE_CASES_ADMIN_READ = 'use_cases_admin:read',
  USE_CASES_ADMIN_WRITE = 'use_cases_admin:write',
  USE_CASES_CUSTOMER_READ = 'use_cases_customer:read',
  USE_CASES_CUSTOMER_WRITE = 'use_cases_customer:write',
}

export const roles = [
  {
    label: 'Customer',
    value: 'CUSTOMER',
  },
  {
    label: 'Consultant',
    value: 'CONSULTANT',
  },
  {
    label: 'Sales',
    value: 'SALES',
  },
  {
    label: 'Admin',
    value: 'ADMIN',
  },
];

export function getRoles() {
  return useAuth().user ? useAuth().user?.realm_access.roles : [];
}

export function isReadOnly() {
  const realm_roles: string[] = getRoles();
  return !realm_roles.includes(roles[3].value); // 'ADMIN'
}

export function isAdmin() {
  const realm_roles: string[] = getRoles();
  return realm_roles.includes(roles[3].value); // 'ADMIN'
}

export function isSalesUser() {
  const realm_roles: string[] = getRoles();
  return realm_roles.includes(roles[2].value); // 'SALES'
}

export function isConsultantUser() {
  const realm_roles: string[] = getRoles();
  return realm_roles.includes(roles[1].value); // 'CONSULTANT'
}
