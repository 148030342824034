import React, { memo } from 'react';
import { Box, Flex, Spacer, Text, Center, useRadioGroup } from '@chakra-ui/react';
import { UseCaseDTO } from '../../../../../../api';
import { KeysWithValuesOfType } from 'src/types';
import RatingButton from './RatingButton';
import { useUpdateField } from '../../../useUpdateField';
import RatingWeightingInput from './RatingWeightingInput';
import { RatingOptions } from '../util/RatingFields';

type RatingInputProps = {
  field: KeysWithValuesOfType<UseCaseDTO, number>;
  text?: string;
  weighting: KeysWithValuesOfType<UseCaseDTO, number>;
  allWeightings: KeysWithValuesOfType<UseCaseDTO, number>[];
};

const RatingInput = memo(function RatingInput({
  text,
  field,
  weighting,
  allWeightings,
}: RatingInputProps) {
  const { value, update, readOnly } = useUpdateField(field);
  const { value: weightingValue } = useUpdateField(weighting);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field,
    defaultValue: String(value),
    onChange: updatedValue => update(Number(updatedValue)),
    isDisabled: readOnly,
  });

  const group = getRootProps();

  return (
    <Box {...group}>
      <Flex direction="row" gap="3" alignItems="center">
        <Text data-testid="ratingInputText" color="primaryDark">
          {text}
        </Text>

        <Spacer />
        {!readOnly &&
          RatingOptions.map((value: React.Key) => {
            const radio = getRadioProps({ value });
            return (
              <RatingButton key={value} {...radio}>
                {value}
              </RatingButton>
            );
          })}

        {readOnly && (
          <Center w="10px">
            <Box data-testid="ratingValue">{value}</Box>{' '}
          </Center>
        )}
        <Box>
          <Spacer width={readOnly ? '100px' : '3'} />
        </Box>

        {!readOnly && (
          <RatingWeightingInput
            weighting={weighting}
            allWeightings={allWeightings}></RatingWeightingInput>
        )}

        {readOnly && (
          <Center w="50px">
            <Box data-testid="weightingValue">{Math.floor(weightingValue * 100)}</Box>
          </Center>
        )}
      </Flex>
    </Box>
  );
});

export default RatingInput;
