import { Box, VStack } from '@chakra-ui/react';
import React, { memo, PropsWithChildren } from 'react';
import TitleSection from '../../../common/TitleSection';

export const InfoTile = memo(function InfoTile({
  title,
  className,
  children,
}: PropsWithChildren<{ title: string; className?: string }>) {
  return (
    <VStack className={className} spacing="3" flex={1} alignItems="stretch">
      <TitleSection shadow="md">{title}</TitleSection>
      <Box shadow="md" rounded="md" bg="white" px="50px" py="60px" flex="1" minHeight="375px">
        {children}
      </Box>
    </VStack>
  );
});
