import React, { memo } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Icon,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { RiContactsBookFill } from 'react-icons/ri';
import { ContactSelect } from './ContactSelect';
import { SelectedContactTiles } from './SelectedContactsTiles';
import { ContactDTO, ContactIds, UserOutputDTO } from '../../../../api';
import { useRelationshipStore } from '../../../../store/useRelationshipStore';
import { useUserStore } from 'src/store/useUserStore';

export const AddExistingContactModal = memo(function AddContactModal({
  customerId,
  allContacts,
  selectedContacts,
}: {
  customerId: string;
  allContacts: UserOutputDTO[];
  selectedContacts: ContactDTO[];
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const createCustomerContactRelationship = useRelationshipStore(
    state => state.createCustomerContactRelationship
  );

  const relationshipContacts = useRelationshipStore(state => state.contacts);
  const addContactToList = useRelationshipStore(state => state.addContactToList);
  const emptyContacts = useRelationshipStore(state => state.emptyContacts);
  const getContactsByUserId = useUserStore(state => state.getContactsByUserId);

  const customOnClose = () => {
    emptyContacts();
    onClose();
  };

  const selectedContact = (contacts: ContactDTO[]) => {
    contacts.forEach(c => {
      if (!selectedContacts.some(sc => sc.id === c.id)) addContactToList(c);
    });
  };

  const save = async () => {
    const contactIds = relationshipContacts.map(contact => contact.id);
    createCustomerContactRelationship(customerId, contactIds as ContactIds);
    getContactsByUserId(customerId);
    onClose();
    emptyContacts();
  };

  return (
    <>
      <IconButton
        size="lg"
        isRound={true}
        variant="accent"
        onClick={() => onOpen()}
        icon={<Icon as={RiContactsBookFill} />}
        aria-label="addUser"
      />

      <Modal onClose={customOnClose} isOpen={isOpen} size="2xl" isCentered>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />;
        <ModalContent p={5}>
          <ModalCloseButton onClick={customOnClose} />
          <ModalBody>
            <FormControl>
              <Box mb={6}>
                <FormLabel>Kontakte</FormLabel>
                <ContactSelect
                  contactList={allContacts}
                  placeholder="Kontakte"
                  setSelected={selectedContact}
                  selectedContacts={selectedContacts}
                />
              </Box>
            </FormControl>
            <Box>
              <SelectedContactTiles
                customerId={customerId}
                contactList={[...selectedContacts, ...relationshipContacts]}
              />
            </Box>
          </ModalBody>
          <ModalFooter display="flex" gap="10px">
            <Button className="unstyledBtn" onClick={onClose}>
              Abbrechen
            </Button>
            <Button variant="accent" onClick={() => save()}>
              Speichern
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
