import { KeysWithValuesOfType } from '../../../../types';
import { UseCaseDTO } from '../../../../api';
import {
  selectArrayFieldValues,
  selectFieldValues,
  useUseCaseStore,
} from '../../../../store/useUseCaseStore';
import { memo, ReactNode, useCallback } from 'react';
import { ChakraStylesConfig, CreatableSelect } from 'chakra-react-select';
import { useMultiSelectOptions, useSingleSelectOptions } from '../../../controls/useSelectOptions';
import { useUpdateField } from '../useUpdateField';
import { Box, Flex, HStack, Spacer, Tag } from '@chakra-ui/react';
import { useCustomerSelectorMode } from '../../UserSelector/useCustomerSelectorMode';

export const selectStyles: ChakraStylesConfig = {
  container: provided => ({ ...provided, width: '250px' }),
  input: provided => ({ ...provided, minWidth: '150px' }),
  inputContainer: provided => ({ ...provided, py: '3' }),
  valueContainer: provided => ({ ...provided, py: 0 }),
  menu: provided => ({ ...provided, width: 'max-content', minWidth: '100%', right: 0 }),
  menuList: provided => ({ ...provided, border: 0, shadow: 'md' }),
  option: provided => ({
    ...provided,
    width: 'auto',
    mx: '2',
    my: '2',
    rounded: 'lg',
  }),
};

export const multiSelectStyles: ChakraStylesConfig = {
  ...selectStyles,
  container: provided => ({ ...provided, width: '400px' }),
};

export const SelectInput = memo(function SelectInput({
  field,
  title,
  icon,
}: {
  field: KeysWithValuesOfType<UseCaseDTO, string>;
  title: string;
  icon: ReactNode;
}) {
  const { value, update, readOnly } = useUpdateField(field);

  const fieldValues = useUseCaseStore(useCallback(selectFieldValues(field), [field])) as string[];
  const {
    options,
    value: selectValue,
    onChange,
  } = useSingleSelectOptions<string>(fieldValues, value, update);

  return (
    <HStack>
      {icon}
      <span data-testid="titleText">{title}</span>
      <Spacer />
      {!readOnly && (
        <CreatableSelect
          options={options}
          value={selectValue}
          onChange={onChange as (value: unknown) => void}
          variant="flushed"
          formatCreateLabel={value => `"${value}" erstellen`}
          chakraStyles={selectStyles}
        />
      )}
      {readOnly && <Box maxWidth="250px">{value}</Box>}
    </HStack>
  );
});

export const MultiSelectInput = memo(function MultiSelectInput({
  field,
  title,
  icon,
  isFieldDisabled,
}: {
  field: KeysWithValuesOfType<UseCaseDTO, string[]>;
  title: string;
  icon: ReactNode;
  isFieldDisabled?: boolean;
}) {
  const { value, readOnly, updateArrayValues } = useUpdateField(field);
  const { customerMode, allgemeinMode } = useCustomerSelectorMode();

  const arrayFieldValues = useUseCaseStore(
    useCallback(selectArrayFieldValues(field), [field])
  ) as string[];

  const {
    options,
    value: selectValue,
    onChange,
  } = useMultiSelectOptions<string>(arrayFieldValues, value, updateArrayValues);

  return (
    <Flex justifyContent={'space-between'} gap={3}>
      <HStack>
        {icon}
        <span data-testid="titleText">{title}</span>
      </HStack>
      <Spacer />
      {!readOnly && allgemeinMode && (
        <CreatableSelect
          data-testid="selectME"
          isReadOnly={isFieldDisabled}
          variant="flushed"
          options={options}
          placeholder={title}
          defaultValue={[...selectValue]}
          isMulti
          closeMenuOnSelect={false}
          onChange={onChange as (value: unknown) => void}
          chakraStyles={multiSelectStyles}
        />
      )}
      {(readOnly || customerMode) && (
        <Box maxWidth="300px" display="flex" justifyContent="flex-end" flexWrap="wrap">
          {selectValue.map((value, index) => (
            <Tag
              data-testid="noteditable-select-option"
              size={'md'}
              mb={3}
              ml={2}
              className="spam"
              key={index.toString()}>
              {value.value}
            </Tag>
          ))}
        </Box>
      )}
    </Flex>
  );
});
