import React, { memo } from 'react';
import { ContactDTO } from '../../../../api';
import { Select } from 'chakra-react-select';
import { useRelationshipStore } from 'src/store/useRelationshipStore';

export const ContactSelect = memo(function ContactSelect({
  contactList,
  placeholder,
  setSelected,
  selectedContacts,
}: {
  contactList: ContactDTO[];
  placeholder: string;
  setSelected: (contact: ContactDTO[]) => void;
  selectedContacts: ContactDTO[];
}) {
  const customerContacts = useRelationshipStore(state => state.contacts);
  const contacts = contactList.filter(
    c => customerContacts.some(cc => cc.id === c.id) || selectedContacts.some(cc => cc.id === c.id)
  );

  return (
    <Select
      isMulti
      name="contacts"
      variant="flushed"
      value={contacts.map(contact => ({ value: contact, label: contact.name }))}
      placeholder={placeholder}
      focusBorderColor="accent"
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      onChange={contact => setSelected(contact.map(c => c.value))}
      options={contactList.map(contact => ({ value: contact, label: contact.name }))}
      isClearable={false}
    />
  );
});
