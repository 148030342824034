import { memo, useEffect, useState } from 'react';
import { Flex, VStack, Text } from '@chakra-ui/react';
import { MdWork } from 'react-icons/md';
import { SiMoleculer } from 'react-icons/si';
import { Icon } from '@chakra-ui/react';
import { GeneralMultiSelectInput } from '../controls/GeneralSelctInput';
import { useCustomerSelectorMode } from 'src/components/pages/UserSelector/useCustomerSelectorMode';
import { InfoTile } from '../../controls/InfoTile';
import { GeneralSwitchInput } from '../controls/GeneralSwitchInput';
import { isAdmin, isReadOnly } from 'src/auth/scope';
import ToggleButton from 'src/components/common/ToggleButton';
import { useUserStore, userApi } from 'src/store/useUserStore';
import { multiSelectStyles } from '../../controls/SelectInput';
import { CreatableSelect } from 'chakra-react-select';
import { useGeneralUseCaseStore } from 'src/store/useGeneralUseCaseStore';
import { industrieOptions } from 'src/components/pages/UserSelector/IndustrieOptions';

const GeneralUseCase = memo(function GeneralUseCase() {
  const { customerMode } = useCustomerSelectorMode();
  const readOnly = isReadOnly();
  const [isCustomerUseCase, setIsCustomerUseCase] = useState<boolean>(false);
  const [currentCustomerId, setCurrentCustomerId] = useState<string>('');
  const [customerName, setCustomerName] = useState<string>('');
  const [customerIndustrie, setCustomerIndustrie] = useState<string>('');
  const getCustomerUsers = useUserStore(state => state.getCustomerUsers);
  const customers = useUserStore(state => state.users);
  const generalUseCase = useGeneralUseCaseStore(state => state.generalUseCase);
  const setCustomerIdOnUseCase = useGeneralUseCaseStore(state => state.setCustomerIdOnUseCase);
  const generalUCfromUserUC = useGeneralUseCaseStore(state => state.generalUCfromUserUC);
  const setIndustrie = useGeneralUseCaseStore(state => state.setIndustrie);

  const [customerOptions, setCustomerOptions] = useState<Array<{ label: string; value: string }>>(
    []
  );

  useEffect(() => {
    getCustomerUsers();
    if (customerMode) {
      const getCustomer = async () => {
        const selectedUser = customerMode
          ? localStorage.getItem('userSelectedId')
          : generalUseCase.customerId;
        const { name, industrie, id } = (await userApi.getUserById(selectedUser)).data;
        setCustomerName(name);
        setCustomerIndustrie(industrie);
        setCurrentCustomerId(id);
        setCustomerIdOnUseCase(id);
      };
      getCustomer();
    }
  }, []);

  function toggleCustomer() {
    setIsCustomerUseCase(!isCustomerUseCase);
    if (isCustomerUseCase) {
      generalUCfromUserUC();
    }
  }

  function selectCustomerId(id: string) {
    setCurrentCustomerId(id);
    setCustomerIdOnUseCase(id);
  }

  function handleIndustrieSelect(industries: { label: string; value: string }[]) {
    setIndustrie(industries);
    const filteredCustomer = customers.filter(customer =>
      industries.some(industrie => industrie.value === customer.industrie)
    );
    const filteredOptions = filteredCustomer.map(customer => {
      return { label: customer.name, value: customer.id };
    });

    if (!filteredOptions.some(customer => customer.value !== currentCustomerId)) {
      setCurrentCustomerId(null);
    }

    setCustomerOptions(filteredOptions);
  }

  return (
    <InfoTile title="Use-Case">
      <VStack align="stretch">
        {isAdmin && (
          <>
            <Flex justifyContent="space-between" alignItems="center" gap={3}>
              <Text>Industrie</Text>
              <CreatableSelect
                defaultValue={customerIndustrie}
                chakraStyles={multiSelectStyles}
                options={industrieOptions}
                value={
                  customerMode
                    ? industrieOptions.find(opt => opt.value === customerIndustrie)
                    : generalUseCase.industrieTags?.map(industrie =>
                        industrieOptions.find(opt => opt.value === industrie)
                      )
                }
                isMulti={true}
                onChange={(e: any) => handleIndustrieSelect(e)}
                variant="flushed"
                placeholder="Industrie"
                isDisabled={Boolean(customerMode)}
              />
            </Flex>
            <GeneralMultiSelectInput
              icon={<Icon as={MdWork} color="gray.500" />}
              field="industriespezifischTags"
              title="Industriespezifisch"
            />
            <GeneralMultiSelectInput
              icon={<Icon as={MdWork} color="gray.500" />}
              field="geschaeftsfelderTags"
              title="Geschäftsfelder"
            />
            <GeneralMultiSelectInput
              // isFieldDisabled={Boolean(customerMode)}
              icon={<Icon as={SiMoleculer} color="gray.500" />}
              field="technologienTags"
              title="Technologien"
            />
            <GeneralSwitchInput
              isDisabled={Boolean(customerMode)}
              field="usecaseExternerKundenkontakt"
              title="Externer Kundenkontakt"
            />
            <GeneralSwitchInput
              isDisabled={Boolean(customerMode)}
              field="ki-usecase"
              title="Ist KI-UseCase?"
            />
            {!readOnly && !generalUseCase.id && (
              <ToggleButton
                onChange={toggleCustomer}
                toggleValue={Boolean(customerMode)}
                title="Kunden Use-Case"
                isDisabled={
                  Boolean(customerMode) ||
                  (generalUseCase?.industrieTags?.length < 1 && !currentCustomerId)
                }
              />
            )}
            {customerName && (
              <Flex justifyContent="space-between" gap={3}>
                <Text>KundenID</Text>
                <Text>{customerName}</Text>
              </Flex>
            )}
            {!readOnly && isCustomerUseCase && !customerName && (
              <Flex justifyContent="space-between" alignItems="center" gap={3}>
                <Text>Kunde</Text>
                <CreatableSelect
                  chakraStyles={multiSelectStyles}
                  options={customerOptions}
                  value={
                    customerOptions.find(options => options.value === currentCustomerId) || null
                  }
                  onChange={(e: any) => selectCustomerId(e.value)}
                  variant="flushed"
                  placeholder="Kunde"
                />
              </Flex>
            )}
          </>
        )}
      </VStack>
    </InfoTile>
  );
});

export default GeneralUseCase;
