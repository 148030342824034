import { UseCasesDTO } from 'src/store/useUseCaseStore';

export enum SortDirection {
  ASC = 'Ascending',
  DESC = 'Descending',
}

export interface TableHeader {
  title: string;
  field: keyof UseCasesDTO;
  generalMode?: boolean;
}
export interface SortDataType {
  keyToSort: keyof UseCasesDTO;
  direction: SortDirection;
}

export const tableHeaderData: TableHeader[] = [
  {
    title: 'Industrie',
    field: 'industrieTags',
    generalMode: true,
  },
  {
    title: 'Industriespezifisch',
    field: 'industriespezifischTags',
  },
  {
    title: 'Geschäftsfelder',
    field: 'geschaeftsfelderTags',
  },
  {
    title: 'Use-Case Titel',
    field: 'usecaseUsecaseTitel',
  },
  {
    title: 'Technologien',
    field: 'technologienTags',
  },
  {
    title: 'Ist KI-UseCase?',
    field: 'ki-usecase',
  },
];
