import globalAxios, { HttpStatusCode } from 'axios';
import AuthApi from './authApi';

export function useAuth() {
  const { getToken } = AuthApi();
  const tokenData = getToken();
  if (!tokenData) {
    return { tokenData };
  }

  // This sets the Current token of the user in the Authorization header for the global axios instance.
  globalAxios.interceptors.request.use(async config => {
    config.headers.setAuthorization(`Bearer ${tokenData}`);
    return config;
  });

  // // This uses a refresh token to get a new access toke
  globalAxios.interceptors.response.use(
    res => {
      return res;
    },
    async err => {
      const { config } = err;

      if (config.url !== '/api/login') {
        config.retry = true;
        if (err.response.status === HttpStatusCode.Unauthorized && getToken()) {
          try {
            AuthApi().logout();
            window.location.href = 'login?logout=success';
            return globalAxios(config);
          } catch (_err) {
            return Promise.reject(_err);
          }
        }
      }

      return Promise.reject(err);
    }
  );

  const base64Url = tokenData?.split('.')[1];
  // console.log('BaseUrl: ' + base64Url);
  const base64 = base64Url?.replace('-', '+')?.replace('_', '/');
  const user = JSON.parse(window.atob(base64));

  // TODO: replace with a method to get the token from the cookie.
  return { tokenData, user };
}
