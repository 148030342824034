import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['track', 'thumb', 'container']);

export const Switch = helpers.defineMultiStyleConfig({
  baseStyle: {
    track: {
      _checked: {
        bg: 'primaryDark',
      },
    },
  },
});
