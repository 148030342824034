import React, { memo, useCallback, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

import {
  selectUseCaseField,
  useUseCaseStore,
  selectUseCase,
} from '../../../../../../store/useUseCaseStore';
import { UseCaseDTO } from '../../../../../../api';
import { KeysWithValuesOfType } from '../../../../../../types';
import { useUpdateField } from '../../../useUpdateField';

export const RatingSum = memo(function RatingSum({
  field,
  title,
  ratings,
  weightings,
}: ComponentProps) {
  const fieldValue = useUseCaseStore(useCallback(selectUseCaseField(field), [field]));
  const useCase = useUseCaseStore(useCallback(selectUseCase, []));
  const { update } = useUpdateField(field);

  useEffect(() => {
    const multiplyandSumValues = () => {
      let total = 0;

      ratings.forEach((rating: KeysWithValuesOfType<UseCaseDTO, number>) => {
        const weightKey = weightings.find(wKey => wKey.includes(rating));

        if (weightKey) {
          const value = useCase[rating];
          const weightValue = useCase[weightKey];
          const multiplicationResult = value * weightValue;
          total += multiplicationResult;
        }
      });

      return total;
    };

    const multipliedSummedResult = multiplyandSumValues();
    if (Number(multipliedSummedResult.toFixed(1)) !== Number(fieldValue.toFixed(1)))
      update(Number(multipliedSummedResult.toFixed(1)));
  }, [useCase, ratings, weightings]);

  return (
    <Box bgColor="white" p="5">
      <Text mb="5" fontSize="1xl" fontWeight="semibold" color="primaryDark" textAlign="left">
        {'Gesamtbewertung ' + title}
      </Text>
      <Text
        data-testid="ratingSumValue"
        fontSize="2xl"
        fontWeight="semibold"
        color="yellow.400"
        textAlign="left">
        {fieldValue}
      </Text>
    </Box>
  );
});

type ComponentProps = {
  field: KeysWithValuesOfType<UseCaseDTO, number>;
  ratings: KeysWithValuesOfType<UseCaseDTO, number>[];
  weightings: KeysWithValuesOfType<UseCaseDTO, number>[];
  title: string;
};
