import { Box, Container } from '@chakra-ui/react';
import React, { memo } from 'react';
import GeneralTextareaInput from '../controls/GeneralTextareaInput';
import { GeneralTitleInput } from '../controls/GeneralTitleInput';
import { isAdmin } from '../../../../../auth/scope';

export const GeneralDescriptionSection = memo(function GeneralDescriptionSection() {
  return (
    <Box bgColor="primary" py="5rem">
      <Container maxW="container.md">
        <Box bgColor="white" py="1rem" px="7rem" rounded="md">
          <GeneralTitleInput field="usecaseUsecaseTitel" />
          <GeneralTextareaInput
            isFieldDisabled={Boolean(!isAdmin)}
            field="usecaseFachlicherHintergrund"
            title="Fachlicher Hintergrund"
          />
          <GeneralTextareaInput
            isFieldDisabled={Boolean(!isAdmin)}
            field="usecaseChance"
            title="Chance"
          />
        </Box>
      </Container>
    </Box>
  );
});
