import {
  SelectionsUserCaseFilter,
  selectFilterField,
  useUseCaseStore,
} from '../../../store/useUseCaseStore';
import { Box, Button, Heading, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import { ComponentPropsWithoutRef, ElementRef, forwardRef, memo, useCallback } from 'react';
import { CloseIcon } from '@chakra-ui/icons';

const FilterTiles = memo(function FilterTiles({
  field,
  title,
  format,
}: {
  field: keyof SelectionsUserCaseFilter;
  title: string;
  format?: (w: string) => string;
}) {
  const filterValues = useUseCaseStore(useCallback(selectFilterField(field), [field])) as unknown[];

  const setFilter = useUseCaseStore(state => state.setFilter);
  const removeFilterKey = useUseCaseStore(state => state.removeFilterKey);

  if (filterValues.length === 0) return null;

  const remove = (item: unknown) => {
    const values = filterValues.filter(i => i !== item);
    if (values.length === 0) removeFilterKey(field);
    else setFilter({ [field]: filterValues.filter(i => i !== item) });
  };

  return (
    <Box>
      <Heading size="sm" mb="3" ms="4">
        {title}
      </Heading>
      <Wrap>
        {filterValues.map(w => (
          <WrapItem key={w.toString()}>
            <Button
              variant="tile"
              size="sm"
              onClick={() => remove(w)}
              rightIcon={<CloseIcon boxSize="3" color="accent" ms="1" />}>
              {format ? format(w.toString()) : w.toString()}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
});

export const UseCaseFilterTiles = memo(
  forwardRef<ElementRef<typeof VStack>, ComponentPropsWithoutRef<typeof VStack>>(
    function UseCaseFilterTiles(props, ref) {
      const resetFilter = useUseCaseStore(state => state.resetFilter);
      const filter = useUseCaseStore(state => state.filter);

      return (
        <VStack ref={ref} {...props} alignItems="stretch" spacing="4">
          <FilterTiles field="industrieTags" title="Industrie" />
          <FilterTiles field="industriespezifischTags" title="Industriespezifisch" />
          <FilterTiles field="geschaeftsfelderTags" title="Geschäftsfelder" />
          <FilterTiles field="usecaseUsecaseTitel" title="Usecase Titel" />
          <FilterTiles field="technologienTags" title="Technologien" />
          <FilterTiles
            field="selektionen"
            title="Selektionen"
            format={w => w.split(/(?=[A-Z])/)[1]}
          />
          {Object.keys(filter).length > 0 && (
            <Box>
              <Button variant="solid" size="sm" mt={3} onClick={resetFilter}>
                Alle Filter löschen
              </Button>
            </Box>
          )}
        </VStack>
      );
    }
  )
);
