import React from 'react';
import { Box, chakra, useRadio } from '@chakra-ui/react';

function RatingButton(props: any) {
  const { ...radioProps } = props;
  const { state, getInputProps, htmlProps, getLabelProps } = useRadio(radioProps);
  const input = getInputProps({});

  const properties = {
    cursor: 'pointer',
    borderWidth: '1px',
    borderColor: 'yellow.400',
    borderRadius: 'md',
    color: 'yellow.400',
    _checked: {
      bg: 'yellow.400',
      color: 'white',
      borderColor: 'yellow.400',
    },
    px: 3.5,
    py: 1.5,
  };

  return (
    <chakra.label {...htmlProps}>
      <Box as="label">
        <input {...input} hidden />
        <Box
          {...getLabelProps()}
          {...properties}
          bg={state.isChecked ? 'accent' : 'transparent'}
          color={state.isChecked ? 'white' : 'accent'}>
          {props.children}
        </Box>
      </Box>
    </chakra.label>
  );
}

export default RatingButton;
