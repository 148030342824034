import { memo } from 'react';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { FaTrashAlt, FaUserEdit } from 'react-icons/fa';
import { AddUpdateCustomerForm } from '../../UserSelector/AddUpdateCustomerForm';

export const MoreMenu = memo(function MoreMenu() {
  return (
    <Menu>
      <MenuButton as={IconButton} icon={<Icon as={FiMoreVertical} />} variant="unstyled" />
      <MenuList>
        <AddUpdateCustomerForm isMenuItem={true} />
        <MenuItem icon={<Icon as={FaUserEdit} boxSize="1.5rem" opacity="0.6" mr={2} />}>
          Kunden Profil bearbeiten
        </MenuItem>
        <MenuItem icon={<Icon as={FaTrashAlt} boxSize="1.3rem" opacity="0.6" mr={2} />}>
          Kunden Löschen
        </MenuItem>
      </MenuList>
    </Menu>
  );
});
