import { memo, ReactNode, useCallback } from 'react';
import { CreatableSelect } from 'chakra-react-select';
import { Box, Flex, HStack, Spacer } from '@chakra-ui/react';
import { KeysWithValuesOfType } from 'src/types';
import { UseCaseGeneralDTO } from 'src/api';
import {
  selectGeneralUsecaseArrayFieldValues,
  selectGeneralUsecaseFieldValues,
  useGeneralUseCaseStore,
} from 'src/store/useGeneralUseCaseStore';
import { useGeneralUpdateField } from '../../useUpdateField';
import {
  useMultiSelectOptions,
  useSingleSelectOptions,
} from 'src/components/controls/useSelectOptions';
import { multiSelectStyles, selectStyles } from '../../controls/SelectInput';

export const GeneralSelectInput = memo(function GeneralSelectInput({
  field,
  title,
  icon,
}: {
  field: KeysWithValuesOfType<UseCaseGeneralDTO, string>;
  title: string;
  icon: ReactNode;
}) {
  const { value, update, readOnly } = useGeneralUpdateField(field);

  const fieldValues = useGeneralUseCaseStore(
    useCallback(selectGeneralUsecaseFieldValues(field), [field])
  ) as string[];

  const {
    options,
    value: selectValue,
    onChange,
  } = useSingleSelectOptions<string>(fieldValues, value, update);

  return (
    <HStack>
      {icon}
      <span data-testid="titleText">{title}</span>
      <Spacer />
      {!readOnly && (
        <CreatableSelect
          options={options}
          value={selectValue}
          onChange={onChange as (value: unknown) => void}
          variant="flushed"
          formatCreateLabel={value => `"${value}" erstellen`}
          chakraStyles={selectStyles}
        />
      )}
      {readOnly && <Box maxWidth="250px">{value}</Box>}
    </HStack>
  );
});

export const GeneralMultiSelectInput = memo(function GeneralMultiSelectInput({
  field,
  title,
  icon,
  isFieldDisabled,
}: {
  field: KeysWithValuesOfType<UseCaseGeneralDTO, string[]>;
  title: string;
  icon: ReactNode;
  isFieldDisabled?: boolean;
}) {
  const { value, readOnly, updateArrayValues } = useGeneralUpdateField(field);

  const arrayFieldValues = useGeneralUseCaseStore(
    useCallback(selectGeneralUsecaseArrayFieldValues(field), [field])
  ) as string[];

  const {
    options,
    value: selectValue,
    onChange,
  } = useMultiSelectOptions<string>(arrayFieldValues, value, updateArrayValues);

  return (
    <Flex justifyContent={'space-between'} gap={3}>
      <HStack>
        {icon}
        <span data-testid="titleText">{title}</span>
      </HStack>
      {!readOnly && (
        <CreatableSelect
          isDisabled={isFieldDisabled}
          variant="flushed"
          options={options}
          placeholder={title}
          defaultValue={selectValue && [...selectValue]}
          value={selectValue && [...selectValue]}
          isMulti
          closeMenuOnSelect={false}
          onChange={onChange as (value: unknown) => void}
          chakraStyles={multiSelectStyles}
        />
      )}
    </Flex>
  );
});
