import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Container,
  Divider,
  List,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Tooltip,
  Text,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { UserInputDTO, UserOutputDTO } from 'src/api';
import { useUserStore, userApi } from 'src/store/useUserStore';
import SectionHeader from './SectionHeader';
import { UserItem } from './UserItem';
import { utils, read } from 'xlsx';
import { AddIcon } from '@chakra-ui/icons';
import { useRelationshipStore } from 'src/store/useRelationshipStore';

function ImportCustomer() {
  const fileInput = useRef(null);
  const toast = useToast();
  const getUsers = useUserStore(state => state.getUsers);

  async function fileUploadHandler(file: File) {
    const data = await file.arrayBuffer();
    const workbook = await read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const rawData = utils.sheet_to_json(worksheet);
    const users: UserInputDTO[] = rawData.map((data: any) => {
      return {
        name: data?.Name,
        email: data?.Email,
        phoneNumber: data?.Phone,
        password: data?.Password,
        role: data?.Role.toUpperCase(),
        industrie: data?.Industrie,
      };
    });

    try {
      await userApi.createMultipleUsers(users);
      toast.closeAll();
      toast({
        status: 'success',
        position: 'top-right',
        description: 'User wurden erfolgreich hinzugefügt.',
      });
      getUsers();
    } catch (err) {
      console.error(err);
      toast.closeAll();
      toast({
        status: 'error',
        position: 'top-right',
        description: 'Das Format stimmt nicht, oder einen der User gibt es schon',
      });
    }
  }

  return (
    <Flex align="center" pl="16" pt="16">
      <Tooltip hasArrow label="Kunden Importieren" bg="grey">
        <Box>
          <IconButton
            size="lg"
            isRound={true}
            variant="accent"
            onClick={() => fileInput.current.click()}
            icon={<AddIcon />}
            aria-label="Kunden Importieren"
          />

          <input
            type="file"
            style={{ display: 'none' }}
            onChange={event => fileUploadHandler(event.target.files[0])}
            ref={fileInput}
            accept=".xlsx, .xls"
          />
        </Box>
      </Tooltip>
      <Text ml={5}>Kunden importieren</Text>
    </Flex>
  );
}

export default function RoleManagement() {
  const users = useUserStore(state => state.users);
  const getUsers = useUserStore(state => state.getUsers);
  const setCustomersContacts = useRelationshipStore(state => state.setCustomerContacts);

  const [consultantUser, setConsultantUser] = useState<UserOutputDTO[]>([]);
  const [customerUser, setCustomerUser] = useState<UserOutputDTO[]>([]);
  const [salesUser, setSalesUser] = useState<UserOutputDTO[]>([]);
  const [adminUser, setAdminUser] = useState<UserOutputDTO[]>([]);

  useEffect(() => {
    getUsers();
    setCustomersContacts();
  }, []);

  useEffect(() => {
    setAdminUser(users.filter(user => user.role === 'ADMIN'));
    setConsultantUser(users.filter(user => user.role === 'CONSULTANT'));
    setSalesUser(users.filter(user => user.role === 'SALES'));
    setCustomerUser(users.filter(user => user.role === 'CUSTOMER'));
  }, [users]);

  return (
    <Container flexGrow={1} maxW="container.xl">
      <ImportCustomer />
      <Box p="16">
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <SectionHeader title="Admin" />
              <AccordionIcon />
            </AccordionButton>
            <Divider />
            <AccordionPanel>
              <List>
                {adminUser.map(admin => (
                  <UserItem
                    name={admin.name}
                    email={admin.email}
                    key={admin.id}
                    industrie={admin.industrie}
                    id={admin.id}
                    role={admin.role}
                  />
                ))}
              </List>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem mt={10}>
            <AccordionButton>
              <SectionHeader title="Consultant" />
              <AccordionIcon />
            </AccordionButton>
            <Divider />
            <AccordionPanel>
              <List>
                {consultantUser.map(consultant => (
                  <UserItem
                    name={consultant.name}
                    email={consultant.email}
                    key={consultant.id}
                    industrie={consultant.industrie}
                    id={consultant.id}
                    role={consultant.role}
                  />
                ))}
              </List>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem mt={10}>
            <AccordionButton>
              <SectionHeader title="Kunden" />
              <AccordionIcon />
            </AccordionButton>
            <Divider />
            <AccordionPanel>
              <List>
                {customerUser.map(customer => (
                  <UserItem
                    name={customer.name}
                    email={customer.email}
                    key={customer.id}
                    industrie={customer.industrie}
                    id={customer.id}
                    role={customer.role}
                  />
                ))}
              </List>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem mt={10}>
            <AccordionButton>
              <SectionHeader title="Sales" />
              <AccordionIcon />
            </AccordionButton>
            <Divider />
            <AccordionPanel>
              <List>
                {salesUser.map(sales => (
                  <UserItem
                    name={sales.name}
                    email={sales.email}
                    key={sales.id}
                    industrie={sales.industrie}
                    id={sales.id}
                    role={sales.role}
                  />
                ))}
              </List>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Container>
  );
}
