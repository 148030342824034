import React, { useEffect } from 'react';
import authApi from 'src/auth/authApi';
import {
  Alert,
  Box,
  Flex,
  Button,
  Input,
  Text,
  Center,
  AlertIcon,
  CloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isReadOnly } from '../auth/scope';
import { useAuth } from 'src/auth/useAuth';
import { useUserStore } from 'src/store/useUserStore';
import { HttpStatusCode, isAxiosError } from 'axios';

function Login() {
  type FormValue = {
    email: string;
    password: string;
  };

  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('logout') === 'success') {
      toast.closeAll();
      toast({
        position: 'top-right',
        status: 'success',
        description: 'Sie wurden abgemeldet',
      });
      setSearchParams({});
    }
  }, []);

  const navigate = useNavigate();
  const setUserOnLogin = useUserStore(state => state.setUserOnLogin);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValue>({
    mode: 'onChange',
  });
  const loginUser = async (data: FormValue) => {
    toast.closeAll();

    try {
      if (isValid) {
        if (await authApi().login(data.email, data.password)) {
          navigate('/change-password', { state: { email: data.email } });
        }
      }

      if (isReadOnly()) {
        const { user } = useAuth();
        await setUserOnLogin(user.sub);
        navigate('/');
      } else {
        navigate('/selector'); // Only redirect, if token is in LocalStorage
      }
    } catch (err: any) {
      if (
        isAxiosError(err) &&
        (err.response.status === HttpStatusCode.Forbidden ||
          err.response.status === HttpStatusCode.Unauthorized)
      ) {
        toast({
          position: 'top-right',
          status: 'error',
          description: 'E-Mail oder Passwort falsch',
        });
      }
    }
  };

  // Will be implementd back after ADFS works
  // const routeChange = () => {
  //   console.log('Login.tsx');
  //   window.location.href =
  //     'https://login.microsoftonline.com/common/adminconsent?client_id=3d355765-67d9-47cd-9c7a-bf31179f56eb/oauth2/v2.0/authorize/';
  // };

  const readOnly = isReadOnly();
  const { isOpen: isErrorVisible, onClose } = useDisclosure({ defaultIsOpen: false });
  // deafultIsOpen needs to be set to true in order to see the fehlermeldung.(for now)

  return (
    <Box
      height="100vh"
      bgColor="primary"
      bgImage="url('/images/Background.png')"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat">
      <Flex>
        <Box w="37%" pl="12%" pt="5%">
          <Center mt="10%" pb="10%">
            <Text fontSize="5xl" color="white">
              Login
            </Text>
          </Center>
          {/* <Box p={4}>
            <Center>
              <Button variant="noaccent" pl="22%" pr="22%" onClick={routeChange}>
                <HStack spacing={3}>
                  <Icon as={FaUserShield} boxSize={6} />
                  <Text>Als Admin einloggen</Text>
                </HStack>
              </Button>
            </Center>
            <Box padding="5">
              <HStack spacing={4}>
                <Divider />
                <Text as="i" color="white">
                  oder
                </Text>
                <Divider />
              </HStack>
            </Box>
          </Box> */}
          <form onSubmit={handleSubmit(loginUser)}>
            <Box p={4}>
              <Text fontSize="xl" color="white">
                Email
              </Text>
              <Input
                {...register('email', { required: 'Email is required' })}
                type="email"
                name="email"
                variant="filled"
                size="sm"
                border={errors.email ? '2px' : undefined}
                borderColor={errors.email ? 'red' : undefined}
                focusBorderColor="red"
              />

              {errors.email && (
                <Text fontSize="sm" color="red">
                  {errors.email.message}
                </Text>
              )}

              <Text fontSize="xl" color="white">
                Password
              </Text>
              <Input
                {...register('password', { required: 'Password is required' })}
                type="password"
                name="password"
                variant="filled"
                size="sm"
                border={errors.password ? '2px' : undefined}
                borderColor={errors.password ? 'red' : undefined}
              />
              {errors.password && (
                <Text fontSize="sm" color="red">
                  {errors.password.message}
                </Text>
              )}
            </Box>
            <Flex justify="flex-end">
              <Button type="submit" variant="accent" p={6} m={5} isDisabled={!isValid}>
                Einloggen
              </Button>
            </Flex>
          </form>
        </Box>
        <Box>
          {!readOnly && isErrorVisible && (
            <Alert status="error" border="1px" borderColor="red">
              <AlertIcon />
              <Text color="red">
                Autorisierung mit Keycloak fehlgeschlagen. Bitte versuchen Sie erneut.
              </Text>
              <CloseButton
                alignSelf="flex-start"
                position="relative"
                right={-1}
                top={-1}
                onClick={onClose}
                color="red"
              />
            </Alert>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default Login;
