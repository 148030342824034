import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['field', 'addon']);

export const Input = helpers.defineMultiStyleConfig({
  variants: {
    filled: {
      field: {
        bg: 'white',
        px: '4',
        py: '3',
        border: '0',
        shadow: 'md',
        height: '48px',
        _hover: {
          bg: 'whiteAlpha.600',
        },
        _focus: {
          bg: 'whiteAlpha.600',
        },
      },
    },
  },
});
