import React from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';

export default function SectionHeader({ title }: { title: string }) {
  return (
    <>
      <Flex borderTop="3px" borderColor="gray.200" color="black" p="4" alignItems="center" flex="1">
        <Icon w={8} h={6} as={FaUser} />
        <Text marginLeft="6" fontWeight="500" fontSize={'md'}>
          {title}
        </Text>
      </Flex>
    </>
  );
}
