import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['icon', 'container', 'control', 'label']);

export const Checkbox = helpers.defineMultiStyleConfig({
  baseStyle: {
    control: {
      _checked: {
        bg: 'primaryDark',
        borderColor: 'primaryDark',
      },
    },
  },
});
