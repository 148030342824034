import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Checkbox,
  Flex,
  Link,
  Spacer,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  memo,
  useCallback,
  useState,
} from 'react';
import { selectSortedAndFilteredUseCases, useUseCaseStore } from '../../../store/useUseCaseStore';
import { Link as RouterLink } from 'react-router-dom';
import { useQueryIndex } from '../../QueryIndexProvider';
import {
  ChevronRightIcon,
  CheckIcon,
  TriangleDownIcon,
  TriangleUpIcon,
  CloseIcon,
  StarIcon,
} from '@chakra-ui/icons';
import { isAdmin, isConsultantUser, isReadOnly, isSalesUser } from 'src/auth/scope';
import { SortDirection, tableHeaderData } from './useCaseTableData';
import SpinnerUI from 'src/components/common/Spinner';
import { useCustomerSelectorMode } from '../UserSelector/useCustomerSelectorMode';

export const UseCaseTable = memo(
  forwardRef<ElementRef<typeof Table>, ComponentPropsWithoutRef<typeof Table>>(
    function UseCaseTable(props, ref) {
      const [showAlert, setShowAlert] = useState(false);
      const readOnly = isReadOnly();
      const isSales = isSalesUser();
      const queryIndex = useQueryIndex();
      const { allgemeinMode, customerMode } = useCustomerSelectorMode();
      const customerModeOrReadOnly = customerMode || readOnly;
      const useCases = useUseCaseStore(
        useCallback(selectSortedAndFilteredUseCases(queryIndex), [queryIndex])
      );

      const setFieldToggle = useUseCaseStore(state => state.setFieldToggle);
      const sortData = useUseCaseStore(state => state.sortData);
      const setSortData = useUseCaseStore(state => state.setSortData);
      const changeIsKiUseCase = useUseCaseStore(state => state.changeIsKiUseCase);

      const toggleWorkshop = useCallback(async (useCaseId: string, checked: boolean) => {
        try {
          await setFieldToggle(useCaseId, checked, 'selektionWorkshop');
          //"Deep Dive" is only editable when "Workshop" is enabled. I.e. when "workshop" is disabled "Deep dive" must be disabled as well.
          if (!checked) {
            await setFieldToggle(useCaseId, false, 'selektionDeepdive');
          }
        } catch (error) {
          console.error(error);
          setShowAlert(true);
        }
      }, []);

      const toggleDeepdive = useCallback(async (useCaseId: string, checked: boolean) => {
        try {
          await setFieldToggle(useCaseId, checked, 'selektionDeepdive');
        } catch (error) {
          console.error(error);
          setShowAlert(true);
        }
      }, []);

      const toggleFavorite = useCallback(async (useCaseId: string, checked: boolean) => {
        try {
          await setFieldToggle(useCaseId, checked, 'selektionFavorite');
        } catch (error) {
          console.error(error);
          setShowAlert(true);
        }
      }, []);

      if (!useCases) return <SpinnerUI />;

      return (
        <>
          <Table ref={ref} {...props}>
            <Thead data-testid="usecaseTableHeader" zIndex={1}>
              <Tr>
                {tableHeaderData.map((header, index) => {
                  if (!allgemeinMode && header.generalMode) return <></>;
                  return (
                    <Th
                      style={{ cursor: 'pointer' }}
                      key={index}
                      onClick={() => setSortData(header)}>
                      <Flex>
                        <span>{header.title}</span>
                        <Spacer />
                        {sortData.keyToSort === header.field &&
                          (sortData.direction === SortDirection.ASC ? (
                            <TriangleUpIcon boxSize="3" />
                          ) : (
                            <TriangleDownIcon boxSize="3" />
                          ))}
                      </Flex>
                    </Th>
                  );
                })}
                <Th display={customerModeOrReadOnly && !isSales ? '' : 'none'}>Workshop</Th>
                <Th display={customerModeOrReadOnly && !isSales ? '' : 'none'}>Deep Dive</Th>
                {customerMode && (
                  <Th width="20">
                    <StarIcon />
                  </Th>
                )}
                <Th width="20"></Th>
              </Tr>
            </Thead>
            <Tbody data-testid="usecaseTableBody" width={100}>
              {customerMode &&
                useCases?.map(useCase => (
                  <Tr key={useCase.id}>
                    <Td>{useCase.industriespezifischTags.join(', ')}</Td>
                    <Td>{useCase.geschaeftsfelderTags.join(', ')}</Td>
                    <Td>
                      <Link as={RouterLink} to={useCase.id + '/details'}>
                        {useCase.usecaseUsecaseTitel}
                      </Link>
                    </Td>
                    <Td>{useCase.technologienTags.join(', ')}</Td>
                    <Td>
                      <Checkbox
                        defaultChecked={useCase['ki-usecase'] == 'ja' ? true : false}
                        isDisabled={!isAdmin()}
                        onChange={() => changeIsKiUseCase(useCase)}
                      />
                    </Td>
                    <Td display={customerModeOrReadOnly && !isSales ? '' : 'none'}>
                      {(isAdmin() || isConsultantUser()) && customerMode && (
                        <Switch
                          data-test="workshop-switch"
                          isChecked={useCase.selektionWorkshop}
                          onChange={e => toggleWorkshop(useCase.id, e.target.checked)}
                        />
                      )}
                      {readOnly &&
                        !isConsultantUser() &&
                        (useCase.selektionWorkshop ? (
                          <CheckIcon color="#33B140" />
                        ) : (
                          <CloseIcon color="#D41212" />
                        ))}
                    </Td>
                    <Td display={customerModeOrReadOnly && !isSales ? '' : 'none'}>
                      {(isAdmin() || isConsultantUser()) && customerMode && (
                        <Switch
                          data-test="deep-dive-switch"
                          isChecked={useCase.selektionDeepdive}
                          onChange={e => toggleDeepdive(useCase.id, e.target.checked)}
                          disabled={!useCase.selektionWorkshop}
                        />
                      )}
                      {readOnly &&
                        !isConsultantUser() &&
                        (useCase.selektionDeepdive ? (
                          <CheckIcon color="#33B140" />
                        ) : (
                          <CloseIcon color="#D41212" />
                        ))}
                    </Td>
                    <Td>
                      {useCase.selektionFavorite ? (
                        <StarIcon
                          onClick={() => toggleFavorite(useCase.id, false)}
                          color="accent"
                        />
                      ) : (
                        <StarIcon onClick={() => toggleFavorite(useCase.id, true)} color="grey" />
                      )}
                    </Td>
                    <Td data-testid="usecaseDetailLink">
                      <Link as={RouterLink} to={useCase.id + '/details'}>
                        <ChevronRightIcon color="accent" boxSize="5" data-test="open-usecase" />
                      </Link>
                    </Td>
                  </Tr>
                ))}
              {!readOnly &&
                allgemeinMode &&
                useCases?.map(generalUseCases => (
                  <Tr key={generalUseCases.id}>
                    <Td>{generalUseCases.industrieTags.join(', ')}</Td>
                    <Td>{generalUseCases.industriespezifischTags.join(', ')}</Td>
                    <Td>{generalUseCases.geschaeftsfelderTags.join(', ')}</Td>
                    <Td>
                      <Link as={RouterLink} to={'general/' + generalUseCases.id + '/details'}>
                        {generalUseCases.usecaseUsecaseTitel}
                      </Link>
                    </Td>
                    <Td>{generalUseCases.technologienTags.join(', ')}</Td>
                    <Td>
                      <Checkbox
                        defaultChecked={generalUseCases['ki-usecase'] == 'ja' ? true : false}
                        onChange={() => changeIsKiUseCase(generalUseCases)}
                      />
                    </Td>
                    <Td>
                      <Link as={RouterLink} to={'general/' + generalUseCases.id + '/details'}>
                        <ChevronRightIcon color="accent" boxSize="5" />
                      </Link>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          {showAlert && (
            <Flex align="center" justify="center">
              <Alert width="30%" status="error">
                <AlertIcon />
                <AlertTitle>Error!</AlertTitle>
                <AlertDescription>There was an error saving your changes.</AlertDescription>
              </Alert>
            </Flex>
          )}
        </>
      );
    }
  )
);
