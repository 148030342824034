import { UseCaseDTO } from 'src/api';

export const createEmptyUsecase = (
  customerId: string,
  useCaseGeneralId: string,
  selektionWorkshop = true,
  generalUseCase: any = {}
): UseCaseDTO => {
  const skeletonUseCase: UseCaseDTO = {
    id: null,
    customerId,
    usecaseNumber: 0,
    usecaseExternerKundenkontakt: true,
    usecaseUsecaseTitel: '',
    usecaseFachlicherHintergrund: '',
    usecaseChance: '',
    industrieTags: [],
    geschaeftsbereichTags: [],
    technologienTags: [],
    selektionExterneZulieferung: false,
    selektionWorkshop,
    selektionDeepdive: false,
    selektionBacklog: false,
    selektionLeuchtturm: false,
    usecaseWorkshop: '',
    usecaseDeepdive: '',
    usecaseErgaenzendeInformation: '',
    erstbewertungGesamtbewertungNutzen: 0,
    erstbewertungGesamtbewertungAufwand: 0,
    erstbewertungGesamtsumme: 0,
    erstbewertungErtragssteigerung: 0,
    erstbewertungErtragssteigerungWeight: 0,
    erstbewertungAufwandsreduktion: 0,
    erstbewertungAufwandsreduktionWeight: 0,
    erstbewertungCustomerExperience: 0,
    erstbewertungCustomerExperienceWeight: 0,
    erstbewertungProzessqualitaet: 0,
    erstbewertungProzessqualitaetWeight: 0,
    erstbewertungUmsetzungszei: 0,
    erstbewertungUmsetzungszeiWeight: 0,
    erstbewertungChangeImpact: 0,
    erstbewertungChangeImpactWeight: 0,
    erstbewertungKomplexitaet: 0,
    erstbewertungKomplexitaetWeight: 0,
    erstbewertungSynergie: 0,
    erstbewertungSynergieWeight: 0,
    erstbewertungKostenItBetrieb: 0,
    erstbewertungKostenItBetriebWeight: 0,
    erstbewertungEthischeRegRisiken: 0,
    erstbewertungEthischeRegRisikenWeight: 0,
    workshopbewertungGesamtbewertungNutzen: 0,
    workshopbewertungErtragssteigerung: 0,
    workshopbewertungErtragssteigerungWeight: 0,
    workshopbewertungAufwandsreduktion: 0,
    workshopbewertungAufwandsreduktionWeight: 0,
    workshopbewertungCustomerExperience: 0,
    workshopbewertungCustomerExperienceWeight: 0,
    workshopbewertungProzessqualitaet: 0,
    deepdiveDatengrundlage: '',
    deepdiveSkizzierungAblauf: '',
    deepdiveProdcutOwner: '',
    deepdiveStellvertreter: '',
    deepdiveInvolvierteBereiche: '',
    deepdiveFormulierungVision: '',
    workshopbewertungProzessqualitaetWeight: 0,
    deepdiveNutzenpotential: 0,
    deepdiveAnforderungsdefinition: '',
    deepdiveNutzen: '',
    deepdiveKennzahlen: '',
    deepdiveErfolgsfaktorenUmsetzungshindernisse: '',
    deepdiveAbhaengigkeitenVoraussetzungen: '',
    deepdiveTechnischeLoesungen: '',
    deepdiveNutzenErtragssteigerung: 0,
    deepdiveNutzenAufwandsreduktion: 0,
    deepdiveNutzenKundenerlebnis: 0,
    deepdiveNutzenProzessqualitaet: 0,
    deepdiveOwnership: '',
    deepdiveUmsetzung: '',
    deepdiveSwReuse: '',
    deepdiveSaasBms: '',
    deepdiveGebundeneMa: '',
    usecaseSoftwareloesung: '',
    selektionShortlist: true,
    ...generalUseCase,
  };

  if (generalUseCase.customerId) {
    return { ...skeletonUseCase, useCaseUserGeneralId: useCaseGeneralId };
  }
  return {
    ...skeletonUseCase,
    useCaseGeneralId,
  };
};
