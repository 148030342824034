import React, { memo, useRef } from 'react';
import { useUpdateField } from './useUpdateField';
import { KeysWithValuesOfType } from '../../../types';
import { UseCaseDTO } from '../../../api';
import { Editor } from '@tinymce/tinymce-react';
import { Box, GridItem, Text } from '@chakra-ui/react';
import DeepDiveRatingScale from './DeepDiveRatingScale';

type TextareaInputProps = {
  title: string;
  rowSpan?: number;
  colSpan?: number;
  field: KeysWithValuesOfType<UseCaseDTO, string>;
  isTextfield?: boolean;
};

const DeepDiveItem = memo(function DeepDiveItem({
  rowSpan,
  colSpan,
  field,
  title,
  isTextfield,
}: TextareaInputProps) {
  const { value, update, readOnly } = useUpdateField(field);

  const editorRef = useRef(null);

  return (
    <>
      <GridItem
        border="1px"
        borderColor="gray.50"
        borderRadius="5px"
        minH="120px"
        padding={'0.8rem'}
        rowSpan={rowSpan}
        colSpan={colSpan}
        color={'primaryDark'}>
        <Text fontWeight={'600'}>{title}</Text>
        {isTextfield ? (
          <>
            <Box>
              <DeepDiveRatingScale
                scale={2}
                fillColor="gold"
                strokeColor="grey"
                field="deepdiveNutzenErtragssteigerung"
                title="Ertragssteigerung"
              />
              <DeepDiveRatingScale
                scale={2}
                fillColor="gold"
                strokeColor="grey"
                field="deepdiveNutzenAufwandsreduktion"
                title="Aufwandsreduktion"
              />
              <DeepDiveRatingScale
                scale={2}
                fillColor="gold"
                strokeColor="grey"
                field="deepdiveNutzenKundenerlebnis"
                title="Kundenerlebnis"
              />
              <DeepDiveRatingScale
                scale={2}
                fillColor="gold"
                strokeColor="grey"
                field="deepdiveNutzenProzessqualitaet"
                title="Prozessqualität"
              />
            </Box>
          </>
        ) : (
          <Editor
            disabled={readOnly}
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              promotion: false,
              statusbar: false,
              menubar: false,
              label: 'test',
              min_height: 100,
              content_style:
                '.mce-content-body { height: 100%; min-height: 120px; width: 100%; outline: none; color: primary; padding-top: 0.8rem; }' +
                'li { margin-left: 20px }' +
                '.css-1ai23le { height: min-content}',
              base_url: '/tinymce',
              suffix: '.min',
              inline: true,
              plugins: ['link', 'lists', 'autolink'],
              toolbar: [
                'undo redo | bold italic underline | fontselect fontsizeselect',
                'forecolor backcolor | alignleft aligncenter alignright alignfull | numlist bullist outdent indent',
              ],
              valid_elements: 'p[style],strong,em,span[style],a[href],ul,ol,li',
              valid_styles: {
                '*': 'font-size,font-family,color,text-decoration,text-align',
              },
              powerpaste_word_import: 'clean',
              powerpaste_html_import: 'clean',
            }}
            onEditorChange={e => update(e)}
            value={value}
          />
        )}
      </GridItem>
    </>
  );
});

export default DeepDiveItem;
