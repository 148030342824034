import React, { memo, useEffect } from 'react';
import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Spacer,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { isAdmin, isReadOnly } from '../../../auth/scope';
import { AddExistingContactModal } from './controls/addExistingContactModal';
import { ContactCard } from './controls/ContactCard';
import { useUserStore } from '../../../store/useUserStore';
import { useCustomerSelectorMode } from '../UserSelector/useCustomerSelectorMode';
import { useAuth } from '../../../auth/useAuth';
import utf8 from 'utf8';
import { WelcomeAdmin } from './WelcomeAdmin';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const WelcomePage = memo(function WelcomePage() {
  const readOnly = isReadOnly();
  const { customerMode } = useCustomerSelectorMode();

  const getContactsByUserId = useUserStore(state => state.getContactsByUserId);
  const getContacts = useUserStore(state => state.getAllContacts);

  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('status') === 'changed') {
      toast.closeAll();
      toast({
        position: 'top-right',
        status: 'success',
        description: 'Das Einmalpasswort wurde Erfolgreich geändert',
      });
      setSearchParams({});
    }
  }, []);

  useEffect(() => {
    getContacts();
    if (customerMode) getContactsByUserId(customerMode?.id);
  }, [customerMode?.id]);

  const contacts = useUserStore(state => state.allContacts);
  const userContacts = useUserStore(state => state.contacts);
  const { user } = useAuth();

  const user_name = user.name ? user.name : user.preferred_username;
  const arr = user_name.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const username = arr.join(' ');
  const decoded = utf8.decode(username);
  const navigate = useNavigate();

  const handleCreateUseCase = () => {
    navigate('/usecases/general/create');
  };

  return (
    <>
      {isAdmin() && !customerMode && <WelcomeAdmin />}
      {isReadOnly() && (
        <Box
          height="87vh"
          bgColor="primary"
          bgImage="url('/images/Background.png')"
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat">
          <Flex
            p={20}
            maxW="46vw"
            height="100%"
            align="center"
            textShadow="0px 3px 6px rgba(0, 0, 0, 0.42)">
            <Heading as="h1" size="4xl" color="gray.50">
              Willkommen <br /> {decoded} !
            </Heading>
          </Flex>
        </Box>
      )}

      {customerMode && (
        <Box>
          <Container maxW="container.xl" p={20}>
            <Flex align="center">
              <Tooltip hasArrow label="Neuen Use-Case erstellen" bg="grey">
                <IconButton
                  size="lg"
                  isRound={true}
                  variant="accent"
                  onClick={handleCreateUseCase}
                  icon={<AddIcon />}
                  aria-label="Add General Use-Case"
                  mb={5}
                />
              </Tooltip>
              <Text ml={5} mt={-4}>
                Neuen Use-Case erstellen
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="3xl" mb="35px">
                Ansprechpartner
              </Text>
              <Spacer />
              {!readOnly && (
                <HStack spacing={3}>
                  <AddExistingContactModal
                    customerId={customerMode?.id}
                    allContacts={contacts}
                    selectedContacts={userContacts}
                  />
                  {/**Add buton for user specific use case */}
                </HStack>
              )}
            </Flex>
            <Flex justify="flex-start" wrap="wrap" gap="20px">
              {userContacts.map(contact => (
                <ContactCard contact={contact} key={contact.id}></ContactCard>
              ))}
            </Flex>
          </Container>
        </Box>
      )}
    </>
  );
});
