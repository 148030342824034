import { extendTheme } from '@chakra-ui/react';
import { Table } from './components/table';
import { Input } from './components/input';
import { Button } from './components/button';
import { Switch } from './components/switch';
import { Textarea } from './components/textarea';
import { Checkbox } from './components/checkbox';
import { Menu } from './components/menu';

export const theme = extendTheme({
  colors: {
    gray: {
      '50': '#F3F2F1',
      '100': '#DEDBD9',
      '200': '#C9C4C0',
      '300': '#B4ADA7',
      '400': '#9E968E',
      '500': '#898076',
      '600': '#6E665E',
      '700': '#524D47',
      '800': '#37332F',
      '900': '#1B1A18',
    },
    primary: '#006ec7',
    primaryDark: '#003d69',
    accent: '#c8b600',
    accentLight: '#e6d200',
    secondary: '#887d75',
  },
  sizes: {
    container: {
      // sm: '500px',
      md: '1200px',
      xl: '1700px',
    },
  },
  components: {
    Button,
    Input,
    Table,
    Switch,
    Textarea,
    Checkbox,
    Menu,
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50',
      },
    },
  },
  space: {
    px: '1px',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
  },
});
