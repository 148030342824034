import './styles/style.scss';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import UseCaseList from './components/pages/UseCaseList/UseCaseList';
import { UseCaseDetails } from './components/pages/UseCaseDetails/UseCaseDetails';
import { Layout } from './components/Layout';
import { WelcomePage } from './components/pages/WelcomePage/WelcomePage';
import { DeepdiveRating } from './components/pages/UseCaseDetails/DeepdiveRating';
import { UseCaseWorkshop } from './components/pages/UseCaseDetails/UseCaseWorkshop';
import UserModeSelectorPage from './components/pages/UserSelector/UserModeSelectorPage';
import NotFound from './components/pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import { GeneralUseCaseDetails } from './components/pages/UseCaseDetails/general/sections/GeneralUsecaseDetails';
import Login from './components/Login';
import { ProtectedLayout } from './components/ProtectedLayout';
import AuthRoute from './components/AuthRoute';
import { CreateGeneralUseCase } from './components/pages/UseCaseDetails/general/sections/CreateGeneralUseCase';
import ChangePassword from './components/ChangePassword';
import RoleManagement from './components/pages/RoleManagementPage/RoleManagement';
// import ADFS from './components/ADFS';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="login" element={<Login />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route element={<AuthRoute />}>
        <Route path="selector" element={<UserModeSelectorPage />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<ProtectedLayout />}>
            <Route index element={<WelcomePage />} />
            <Route path="usecases" element={<UseCaseList />} />
            <Route path="usecases/:useCaseId/details" element={<UseCaseDetails />} />
            <Route path="usecases/general/:useCaseId/details" element={<GeneralUseCaseDetails />} />
            <Route path="usecases/general/create" element={<CreateGeneralUseCase />} />
            <Route path="usecases/:useCaseId/workshop" element={<UseCaseWorkshop />} />
            <Route path="usecases/:useCaseId/deepdive" element={<DeepdiveRating />} />
            <Route path="rolemanagement" element={<RoleManagement />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
