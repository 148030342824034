import React, { memo } from 'react';
import { Box, Container, Grid, Stack } from '@chakra-ui/react';
import { DetailsTabNav } from './sections/DetailsTabsNav';
import DeepDiveItem from './DeepDiveItem';
import { RatingContainer } from './sections/ratings/elements/RatingContainer';
import { TextareaInput } from './controls/TextareaInput';
import { PreviousPageButton } from './controls/PreviousPageButton';
import { ScrollToTop } from './ScrollToTop';
import { EditorBar } from './EditorBar';
import { isConsultantUser, isReadOnly } from '../../../auth/scope';
import { DEEPDIVE_TOOLTIP } from './sections/ratings/util/InfoBoxUtil';
export const DeepdiveRating = memo(function DeepdiveRating() {
  const readOnly = isReadOnly();

  return (
    <>
      <Box bgColor="primary">
        <DetailsTabNav />
        <PreviousPageButton url={'/usecases'} />
        <Container maxW="container.md" py="1rem">
          <RatingContainer title="Deep Dive Bewertung" info={DEEPDIVE_TOOLTIP}>
            <Grid
              color="primaryDark"
              templateRows="repeat(9, 0.5fr)"
              templateColumns="repeat(5, 1fr)"
              gap={4}>
              <DeepDiveItem
                field="deepdiveDatengrundlage"
                title="Datengrundlage:"
                colSpan={2}
                rowSpan={3}
              />
              <DeepDiveItem
                title="Skizzierung aktueller Ablauf (grob):"
                field="deepdiveSkizzierungAblauf"
                colSpan={2}
                rowSpan={3}
              />
              <DeepDiveItem title="Product Owner:" field="deepdiveProdcutOwner" />
              <DeepDiveItem title="Stellvertreter:" field="deepdiveStellvertreter" />
              <DeepDiveItem title="Involvierte Bereiche:" field="deepdiveInvolvierteBereiche" />
              <DeepDiveItem
                title="Formulierung einer Vision - Mehrwert des UC: Ich will erreichen, dass beispielsweise ..."
                field="deepdiveFormulierungVision"
                colSpan={2}
                rowSpan={3}
              />
              <DeepDiveItem
                title="Anforderungsdefinition (Epics):"
                field="deepdiveAnforderungsdefinition"
                colSpan={2}
                rowSpan={3}
              />
              <DeepDiveItem title="Nutzen:" field="deepdiveNutzen" isTextfield={true} />
              <DeepDiveItem
                title="Kennzahlen:"
                field="deepdiveKennzahlen"
                colSpan={1}
                rowSpan={2}
              />
              <DeepDiveItem
                title="Kritische Erfolgsfaktoren, Umsetzungshindernisse:"
                field="deepdiveErfolgsfaktorenUmsetzungshindernisse"
                colSpan={2}
                rowSpan={3}
              />
              <DeepDiveItem
                title="Abhängigkeiten, Voraussetzungen:"
                field="deepdiveAbhaengigkeitenVoraussetzungen"
                colSpan={2}
                rowSpan={3}
              />
              <DeepDiveItem
                title="Überblick technische Lösungen:"
                field="deepdiveTechnischeLoesungen"
                colSpan={1}
                rowSpan={3}
              />
            </Grid>
            <Box py={'3rem'}>
              <TextareaInput title="Ownership & Commitment" field="deepdiveOwnership" />
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                direction={['column', 'row']}
                gap={10}>
                <Box flex={1}>
                  <TextareaInput field="deepdiveUmsetzung" title="Umsetzung" />
                </Box>
                <Box flex={1}>
                  <TextareaInput field="deepdiveSwReuse" title="SW Reuse" />
                </Box>
              </Stack>
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                direction={['column', 'row']}
                gap={10}>
                <Box flex={1}>
                  <TextareaInput field="deepdiveSaasBms" title="SaaS Bms" />
                </Box>
                <Box flex={1}>
                  <TextareaInput field="deepdiveGebundeneMa" title="Gebundene MA" />
                </Box>
              </Stack>
            </Box>
          </RatingContainer>
        </Container>
        <ScrollToTop />
        {(!readOnly || isConsultantUser()) && <EditorBar />}
      </Box>
    </>
  );
});
