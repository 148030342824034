import React, { memo, useEffect } from 'react';
import { UseCaseFilter } from './UseCaseFilter';
import { UseCaseFilterTiles } from './UseCaseFilterTiles';
import { UseCaseTable } from './UseCaseTable';
import { Box, Container, VStack } from '@chakra-ui/react';
import { useUseCaseStore } from 'src/store/useUseCaseStore';
import { useCustomerSelectorMode } from '../UserSelector/useCustomerSelectorMode';
import { useUserStore } from 'src/store/useUserStore';

const UseCaseList = memo(function UseCaseList() {
  const getAllUseCases = useUseCaseStore(state => state.getAllUseCases);
  const getGeneralUseCases = useUseCaseStore(state => state.getGeneralUseCases);
  const { allgemeinMode, customerMode } = useCustomerSelectorMode();
  const getSelectedUser = useUserStore(state => state.getSelectedUser);

  useEffect(() => {
    if (allgemeinMode) {
      getGeneralUseCases();
    }
    if (customerMode) {
      const getUseCases = async () => {
        await getSelectedUser(localStorage.getItem('userSelectedId'));
        getAllUseCases();
      };
      getUseCases();
    }
  }, [allgemeinMode, customerMode]);

  return (
    <>
      <Container flexGrow={1} maxW="container.xl">
        <Box py="16">
          <VStack alignItems="stretch" spacing="8">
            <UseCaseFilter />
            <UseCaseFilterTiles />
            <UseCaseTable />
          </VStack>
        </Box>
      </Container>
    </>
  );
});

export default UseCaseList;
