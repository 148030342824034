import React, { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  useDisclosure,
  Text,
  ModalFooter,
  useToast,
  Divider,
} from '@chakra-ui/react';
import { ContactIds, UserInputDTO } from 'src/api';
import { userApi } from 'src/store/useUserStore';
import { multiSelectStyles } from '../UseCaseDetails/controls/SelectInput';
import { CreatableSelect } from 'chakra-react-select';
import SpinnerUI from 'src/components/common/Spinner';
import { relationshipApi, useRelationshipStore } from 'src/store/useRelationshipStore';

export default function CustomerConsultantManager({ consultantId }: { consultantId: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [consultantCustomer, setConsultantCustomer] = useState<UserInputDTO[]>([]);
  const [customers, setCustomers] = useState<UserInputDTO[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<UserInputDTO>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setCustomersContacts = useRelationshipStore(state => state.setCustomerContacts);

  async function getConsultantCustomer() {
    const consultantCustomerRes = await relationshipApi.getAllCustomersFromConsultant(consultantId);

    setConsultantCustomer(consultantCustomerRes.data);
  }

  async function getCustomer() {
    const customerRes = await userApi.getAllCustomerUsers();

    setCustomers(customerRes.data);
  }

  useEffect(() => {
    getConsultantCustomer();
    getCustomer();
  }, []);

  const customerOptions = customers.map(customer => {
    return { label: customer.name, value: customer.id };
  });

  function clearModal() {
    setSelectedCustomer(null);
    onClose();
  }

  async function removeRelation(id: string) {
    setIsLoading(true);
    const customerConsultants = (await relationshipApi.getCustomerContacts(id)).data;
    const filteredConsultants = customerConsultants
      .filter(c => c.id !== consultantId)
      .map(c => c.id);

    const res = await relationshipApi.updateCustomerContacts(id, {
      contactids: filteredConsultants,
    } as ContactIds);

    if (res.status === 200) {
      toast.closeAll();
      toast({
        status: 'success',
        position: 'top-right',
        description: 'Kunde erfolgreich entfernt',
      });
      await setCustomersContacts();
      await getConsultantCustomer();
    } else {
      toast.closeAll();
      toast({
        status: 'error',
        position: 'top-right',
        description: 'Fehler beim entfernen des Kunden',
      });
    }
    setIsLoading(false);
  }

  async function createRelation() {
    setIsLoading(true);
    // Gets the ids of the current Consultants of Customer
    const customerConsultants = (
      await relationshipApi.getCustomerContacts(selectedCustomer.id)
    ).data.map(consultants => consultants.id);

    const res = await relationshipApi.updateCustomerContacts(selectedCustomer.id, {
      contactids: [...customerConsultants, consultantId],
    } as ContactIds);

    if (res.status === 200) {
      toast.closeAll();
      toast({
        status: 'success',
        position: 'top-right',
        description: 'Kunde erfolgreich hinzugefügt',
      });
      await setCustomersContacts();
      await getConsultantCustomer();
    } else {
      toast.closeAll();
      toast({
        status: 'error',
        position: 'top-right',
        description: 'Fehler beim hinzufügen des Kunden',
      });
    }

    setSelectedCustomer(null);
    setIsLoading(false);
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent p={6}>
          <ModalHeader>Kundenverwaltung</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <SpinnerUI />
            ) : (
              <>
                <Flex direction="row" justify="space-between" align="center" gap="20px">
                  <Box flex={1} mr={5}>
                    <Text fontSize="lg" fontWeight="bold" mb={3}>
                      Kunden des Consultants
                    </Text>
                    <Divider />
                    {consultantCustomer.map(c => {
                      return (
                        <Box key={c.id}>
                          <Flex
                            key={c.id}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Flex direction="column" alignItems="start">
                              <Text fontWeight="bold">{c.name}</Text>
                              <Text fontSize="sm">{c.email}</Text>
                              <Text fontSize="sm" fontWeight="bold">
                                Industrie: {c.industrie}
                              </Text>
                            </Flex>

                            <Button colorScheme="red" onClick={() => removeRelation(c.id)}>
                              Kunde entfernen
                            </Button>
                          </Flex>
                          <Divider />
                        </Box>
                      );
                    })}
                    <Text fontSize="lg" fontWeight="bold" mt={5}>
                      Kunden zuweisen
                    </Text>
                    <CreatableSelect
                      chakraStyles={multiSelectStyles}
                      options={customerOptions}
                      value={
                        customerOptions.find(option => option.value === selectedCustomer?.id) ||
                        null
                      }
                      onChange={(e: any) =>
                        setSelectedCustomer(customers.find(customer => customer.id === e.value))
                      }
                      variant="flushed"
                      placeholder="Kunde"
                    />
                  </Box>
                </Flex>

                <ModalFooter display="flex" justifyContent="space-between" px="0">
                  <Button className="unstyledBtn" onClick={clearModal}>
                    Abbrechen
                  </Button>
                  <Button variant="noaccent" onClick={createRelation}>
                    Kunde Zuweisen
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box mr={5}>
        <Button variant="noaccent" onClick={onOpen}>
          Kunden verwalten
        </Button>
      </Box>
    </>
  );
}
