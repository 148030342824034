/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v01
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ContactDTO
 */
export interface ContactDTO {
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'telNr'?: string;
}
/**
 * 
 * @export
 * @interface ContactIds
 */
export interface ContactIds {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactIds
     */
    'contactids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface LoginDTO
 */
export interface LoginDTO {
    /**
     * 
     * @type {string}
     * @memberof LoginDTO
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDTO
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface LoginResponseDTO
 */
export interface LoginResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface TagDTO
 */
export interface TagDTO {
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'tagType'?: TagDTOTagTypeEnum;
}

export const TagDTOTagTypeEnum = {
    Industrie: 'INDUSTRIE',
    Industriespezifisch: 'INDUSTRIESPEZIFISCH',
    Technologien: 'TECHNOLOGIEN',
    Geschaeftsfelder: 'GESCHAEFTSFELDER'
} as const;

export type TagDTOTagTypeEnum = typeof TagDTOTagTypeEnum[keyof typeof TagDTOTagTypeEnum];

/**
 * 
 * @export
 * @interface UpdatePasswordDTO
 */
export interface UpdatePasswordDTO {
    /**
     * 
     * @type {LoginDTO}
     * @memberof UpdatePasswordDTO
     */
    'credentials'?: LoginDTO;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDTO
     */
    'newPassword'?: string;
}
/**
 * 
 * @export
 * @interface UseCaseDTO
 */
export interface UseCaseDTO {
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'useCaseGeneralId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'useCaseUserGeneralId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'usecaseNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'usecaseExternerKundenkontakt'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'usecaseUsecaseTitel'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'usecaseFachlicherHintergrund'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'usecaseChance'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseDTO
     */
    'industrieTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseDTO
     */
    'industriespezifischTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseDTO
     */
    'technologienTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseDTO
     */
    'geschaeftsfelderTags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'selektionFavorite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'selektionExterneZulieferung'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'selektionWorkshop'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'selektionDeepdive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'selektionBacklog'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'selektionLeuchtturm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'referenceAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'referenceExecuted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'referenceContact'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'referenceDemoAvailable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'referenceDemoLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'usecaseWorkshop'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'usecaseDeepdive'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'usecaseErgaenzendeInformation'?: string;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungGesamtbewertungNutzen'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungGesamtbewertungAufwand'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungGesamtsumme'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungErtragssteigerung'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungErtragssteigerungWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungAufwandsreduktion'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungAufwandsreduktionWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungCustomerExperience'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungCustomerExperienceWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungProzessqualitaet'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungProzessqualitaetWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungUmsetzungszei'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungUmsetzungszeiWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungChangeImpact'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungChangeImpactWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungKomplexitaet'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungKomplexitaetWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungSynergie'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungSynergieWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungKostenItBetrieb'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungKostenItBetriebWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungEthischeRegRisiken'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'erstbewertungEthischeRegRisikenWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungGesamtbewertungNutzen'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungErtragssteigerung'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungErtragssteigerungWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungAufwandsreduktion'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungAufwandsreduktionWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungCustomerExperience'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungCustomerExperienceWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungProzessqualitaet'?: number;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveDatengrundlage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveSkizzierungAblauf'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveProdcutOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveStellvertreter'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveInvolvierteBereiche'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveFormulierungVision'?: string;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'workshopbewertungProzessqualitaetWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'deepdiveNutzenpotential'?: number;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveAnforderungsdefinition'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveNutzen'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveKennzahlen'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveErfolgsfaktorenUmsetzungshindernisse'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveAbhaengigkeitenVoraussetzungen'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveTechnischeLoesungen'?: string;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'deepdiveNutzenErtragssteigerung'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'deepdiveNutzenAufwandsreduktion'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'deepdiveNutzenKundenerlebnis'?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseDTO
     */
    'deepdiveNutzenProzessqualitaet'?: number;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveOwnership'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveUmsetzung'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveSwReuse'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveSaasBms'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'deepdiveGebundeneMa'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseDTO
     */
    'usecaseSoftwareloesung'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseDTO
     */
    'selektionShortlist'?: boolean;
}
/**
 * 
 * @export
 * @interface UseCaseGeneralDTO
 */
export interface UseCaseGeneralDTO {
    /**
     * 
     * @type {string}
     * @memberof UseCaseGeneralDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UseCaseGeneralDTO
     */
    'usecaseNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseGeneralDTO
     */
    'usecaseExternerKundenkontakt'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UseCaseGeneralDTO
     */
    'usecaseUsecaseTitel'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseGeneralDTO
     */
    'usecaseFachlicherHintergrund'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseGeneralDTO
     */
    'usecaseChance'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseGeneralDTO
     */
    'ki-usecase'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseGeneralDTO
     */
    'industrieTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseGeneralDTO
     */
    'industriespezifischTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseGeneralDTO
     */
    'technologienTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseGeneralDTO
     */
    'geschaeftsfelderTags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UseCaseUserGeneralDTO
 */
export interface UseCaseUserGeneralDTO {
    /**
     * 
     * @type {string}
     * @memberof UseCaseUserGeneralDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseUserGeneralDTO
     */
    'customerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseUserGeneralDTO
     */
    'selektionFavorite'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UseCaseUserGeneralDTO
     */
    'usecaseNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UseCaseUserGeneralDTO
     */
    'usecaseExternerKundenkontakt'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UseCaseUserGeneralDTO
     */
    'usecaseUsecaseTitel'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseUserGeneralDTO
     */
    'usecaseFachlicherHintergrund'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseUserGeneralDTO
     */
    'usecaseChance'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseUserGeneralDTO
     */
    'industrieTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseUserGeneralDTO
     */
    'industriespezifischTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseUserGeneralDTO
     */
    'technologienTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCaseUserGeneralDTO
     */
    'geschaeftsfelderTags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserInputDTO
 */
export interface UserInputDTO {
    /**
     * 
     * @type {string}
     * @memberof UserInputDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputDTO
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputDTO
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputDTO
     */
    'industrie'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputDTO
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface UserOutputDTO
 */
export interface UserOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof UserOutputDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOutputDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOutputDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOutputDTO
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOutputDTO
     */
    'industrie'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOutputDTO
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOutputDTO
     */
    'message'?: string;
}

/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Performs login
         * @param {LoginDTO} loginDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDTO: LoginDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDTO' is not null or undefined
            assertParamExists('login', 'loginDTO', loginDTO)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Performs login
         * @param {LoginDTO} loginDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDTO: LoginDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @summary Performs login
         * @param {LoginDTO} loginDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDTO: LoginDTO, options?: any): AxiosPromise<LoginResponseDTO> {
            return localVarFp.login(loginDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @summary Performs login
     * @param {LoginDTO} loginDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(loginDTO: LoginDTO, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).login(loginDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RefreshTokenApi - axios parameter creator
 * @export
 */
export const RefreshTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Refresh accessToken
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('refreshToken', 'body', body)
            const localVarPath = `/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefreshTokenApi - functional programming interface
 * @export
 */
export const RefreshTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefreshTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Refresh accessToken
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RefreshTokenApi - factory interface
 * @export
 */
export const RefreshTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefreshTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Refresh accessToken
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(body: string, options?: any): AxiosPromise<string> {
            return localVarFp.refreshToken(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RefreshTokenApi - object-oriented interface
 * @export
 * @class RefreshTokenApi
 * @extends {BaseAPI}
 */
export class RefreshTokenApi extends BaseAPI {
    /**
     * 
     * @summary Refresh accessToken
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefreshTokenApi
     */
    public refreshToken(body: string, options?: AxiosRequestConfig) {
        return RefreshTokenApiFp(this.configuration).refreshToken(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RelationshipApi - axios parameter creator
 * @export
 */
export const RelationshipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a relationship between a general use case and a customer
         * @param {string} customerid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFavoriteGeneralUseCaseCustomerRelationship: async (customerid: string, usecaseid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('createFavoriteGeneralUseCaseCustomerRelationship', 'customerid', customerid)
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('createFavoriteGeneralUseCaseCustomerRelationship', 'usecaseid', usecaseid)
            const localVarPath = `/customer/favoritegeneralusecase/{customerid}/{usecaseid}`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)))
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a relationship between a general use case and a tag
         * @param {string} tagid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUseCaseTagRelationship: async (tagid: string, usecaseid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagid' is not null or undefined
            assertParamExists('createUseCaseTagRelationship', 'tagid', tagid)
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('createUseCaseTagRelationship', 'usecaseid', usecaseid)
            const localVarPath = `/tag/{tagid}/usecase/{usecaseid}`
                .replace(`{${"tagid"}}`, encodeURIComponent(String(tagid)))
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes a relationship between a general use case and a customer
         * @param {string} customerid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteGeneralUseCaseCustomerRelationship: async (customerid: string, usecaseid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('deleteFavoriteGeneralUseCaseCustomerRelationship', 'customerid', customerid)
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('deleteFavoriteGeneralUseCaseCustomerRelationship', 'usecaseid', usecaseid)
            const localVarPath = `/customer/favoritegeneralusecase/{customerid}/{usecaseid}`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)))
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes a relationship between a general use case and a tag
         * @param {string} tagid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUseCaseTagRelationship: async (tagid: string, usecaseid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagid' is not null or undefined
            assertParamExists('deleteUseCaseTagRelationship', 'tagid', tagid)
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('deleteUseCaseTagRelationship', 'usecaseid', usecaseid)
            const localVarPath = `/tag/{tagid}/usecase/{usecaseid}`
                .replace(`{${"tagid"}}`, encodeURIComponent(String(tagid)))
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets all Customers from a Consultant
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomersFromConsultant: async (userid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('getAllCustomersFromConsultant', 'userid', userid)
            const localVarPath = `/consultant/{userid}/customer`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets all contact Persons by Customer id
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerContacts: async (customerid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('getCustomerContacts', 'customerid', customerid)
            const localVarPath = `/customer/{customerid}/contacts`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Contacts to Customer
         * @param {string} customerid 
         * @param {ContactIds} contactIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerContacts: async (customerid: string, contactIds: ContactIds, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('updateCustomerContacts', 'customerid', customerid)
            // verify required parameter 'contactIds' is not null or undefined
            assertParamExists('updateCustomerContacts', 'contactIds', contactIds)
            const localVarPath = `/customer/{customerid}/contacts`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelationshipApi - functional programming interface
 * @export
 */
export const RelationshipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RelationshipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a relationship between a general use case and a customer
         * @param {string} customerid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFavoriteGeneralUseCaseCustomerRelationship(customerid: string, usecaseid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFavoriteGeneralUseCaseCustomerRelationship(customerid, usecaseid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a relationship between a general use case and a tag
         * @param {string} tagid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUseCaseTagRelationship(tagid: string, usecaseid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseGeneralDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUseCaseTagRelationship(tagid, usecaseid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes a relationship between a general use case and a customer
         * @param {string} customerid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFavoriteGeneralUseCaseCustomerRelationship(customerid: string, usecaseid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoriteGeneralUseCaseCustomerRelationship(customerid, usecaseid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes a relationship between a general use case and a tag
         * @param {string} tagid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUseCaseTagRelationship(tagid: string, usecaseid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseGeneralDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUseCaseTagRelationship(tagid, usecaseid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets all Customers from a Consultant
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomersFromConsultant(userid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutputDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomersFromConsultant(userid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets all contact Persons by Customer id
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerContacts(customerid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerContacts(customerid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Contacts to Customer
         * @param {string} customerid 
         * @param {ContactIds} contactIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerContacts(customerid: string, contactIds: ContactIds, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerContacts(customerid, contactIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RelationshipApi - factory interface
 * @export
 */
export const RelationshipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RelationshipApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a relationship between a general use case and a customer
         * @param {string} customerid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFavoriteGeneralUseCaseCustomerRelationship(customerid: string, usecaseid: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.createFavoriteGeneralUseCaseCustomerRelationship(customerid, usecaseid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a relationship between a general use case and a tag
         * @param {string} tagid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUseCaseTagRelationship(tagid: string, usecaseid: string, options?: any): AxiosPromise<UseCaseGeneralDTO> {
            return localVarFp.createUseCaseTagRelationship(tagid, usecaseid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes a relationship between a general use case and a customer
         * @param {string} customerid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteGeneralUseCaseCustomerRelationship(customerid: string, usecaseid: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.deleteFavoriteGeneralUseCaseCustomerRelationship(customerid, usecaseid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes a relationship between a general use case and a tag
         * @param {string} tagid 
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUseCaseTagRelationship(tagid: string, usecaseid: string, options?: any): AxiosPromise<UseCaseGeneralDTO> {
            return localVarFp.deleteUseCaseTagRelationship(tagid, usecaseid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets all Customers from a Consultant
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomersFromConsultant(userid: string, options?: any): AxiosPromise<Array<UserOutputDTO>> {
            return localVarFp.getAllCustomersFromConsultant(userid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets all contact Persons by Customer id
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerContacts(customerid: string, options?: any): AxiosPromise<Array<ContactDTO>> {
            return localVarFp.getCustomerContacts(customerid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Contacts to Customer
         * @param {string} customerid 
         * @param {ContactIds} contactIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerContacts(customerid: string, contactIds: ContactIds, options?: any): AxiosPromise<Array<ContactDTO>> {
            return localVarFp.updateCustomerContacts(customerid, contactIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RelationshipApi - object-oriented interface
 * @export
 * @class RelationshipApi
 * @extends {BaseAPI}
 */
export class RelationshipApi extends BaseAPI {
    /**
     * 
     * @summary creates a relationship between a general use case and a customer
     * @param {string} customerid 
     * @param {string} usecaseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipApi
     */
    public createFavoriteGeneralUseCaseCustomerRelationship(customerid: string, usecaseid: string, options?: AxiosRequestConfig) {
        return RelationshipApiFp(this.configuration).createFavoriteGeneralUseCaseCustomerRelationship(customerid, usecaseid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a relationship between a general use case and a tag
     * @param {string} tagid 
     * @param {string} usecaseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipApi
     */
    public createUseCaseTagRelationship(tagid: string, usecaseid: string, options?: AxiosRequestConfig) {
        return RelationshipApiFp(this.configuration).createUseCaseTagRelationship(tagid, usecaseid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes a relationship between a general use case and a customer
     * @param {string} customerid 
     * @param {string} usecaseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipApi
     */
    public deleteFavoriteGeneralUseCaseCustomerRelationship(customerid: string, usecaseid: string, options?: AxiosRequestConfig) {
        return RelationshipApiFp(this.configuration).deleteFavoriteGeneralUseCaseCustomerRelationship(customerid, usecaseid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes a relationship between a general use case and a tag
     * @param {string} tagid 
     * @param {string} usecaseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipApi
     */
    public deleteUseCaseTagRelationship(tagid: string, usecaseid: string, options?: AxiosRequestConfig) {
        return RelationshipApiFp(this.configuration).deleteUseCaseTagRelationship(tagid, usecaseid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets all Customers from a Consultant
     * @param {string} userid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipApi
     */
    public getAllCustomersFromConsultant(userid: string, options?: AxiosRequestConfig) {
        return RelationshipApiFp(this.configuration).getAllCustomersFromConsultant(userid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets all contact Persons by Customer id
     * @param {string} customerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipApi
     */
    public getCustomerContacts(customerid: string, options?: AxiosRequestConfig) {
        return RelationshipApiFp(this.configuration).getCustomerContacts(customerid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Contacts to Customer
     * @param {string} customerid 
     * @param {ContactIds} contactIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipApi
     */
    public updateCustomerContacts(customerid: string, contactIds: ContactIds, options?: AxiosRequestConfig) {
        return RelationshipApiFp(this.configuration).updateCustomerContacts(customerid, contactIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a new TagEntity
         * @param {TagDTO} tagDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (tagDTO: TagDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagDTO' is not null or undefined
            assertParamExists('createTag', 'tagDTO', tagDTO)
            const localVarPath = `/tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets all tag names of tags with specific tagType
         * @param {string} tagtype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsByTagType: async (tagtype: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagtype' is not null or undefined
            assertParamExists('getAllTagsByTagType', 'tagtype', tagtype)
            const localVarPath = `/tag/tagtype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tagtype !== undefined) {
                localVarQueryParameter['tagtype'] = tagtype;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a tag
         * @param {string} tagid 
         * @param {TagDTO} tagDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (tagid: string, tagDTO: TagDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagid' is not null or undefined
            assertParamExists('updateTag', 'tagid', tagid)
            // verify required parameter 'tagDTO' is not null or undefined
            assertParamExists('updateTag', 'tagDTO', tagDTO)
            const localVarPath = `/tag/{tagid}`
                .replace(`{${"tagid"}}`, encodeURIComponent(String(tagid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a new TagEntity
         * @param {TagDTO} tagDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(tagDTO: TagDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(tagDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets all tag names of tags with specific tagType
         * @param {string} tagtype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTagsByTagType(tagtype: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTagsByTagType(tagtype, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a tag
         * @param {string} tagid 
         * @param {TagDTO} tagDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(tagid: string, tagDTO: TagDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(tagid, tagDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a new TagEntity
         * @param {TagDTO} tagDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(tagDTO: TagDTO, options?: any): AxiosPromise<TagDTO> {
            return localVarFp.createTag(tagDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets all tag names of tags with specific tagType
         * @param {string} tagtype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsByTagType(tagtype: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllTagsByTagType(tagtype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a tag
         * @param {string} tagid 
         * @param {TagDTO} tagDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(tagid: string, tagDTO: TagDTO, options?: any): AxiosPromise<TagDTO> {
            return localVarFp.updateTag(tagid, tagDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @summary creates a new TagEntity
     * @param {TagDTO} tagDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public createTag(tagDTO: TagDTO, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).createTag(tagDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets all tag names of tags with specific tagType
     * @param {string} tagtype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getAllTagsByTagType(tagtype: string, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).getAllTagsByTagType(tagtype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a tag
     * @param {string} tagid 
     * @param {TagDTO} tagDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public updateTag(tagid: string, tagDTO: TagDTO, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).updateTag(tagid, tagDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdatePasswordApi - axios parameter creator
 * @export
 */
export const UpdatePasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary update the password of a user on the first login
         * @param {UpdatePasswordDTO} updatePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePasswordOnFirstLogin: async (updatePasswordDTO: UpdatePasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordDTO' is not null or undefined
            assertParamExists('updatePasswordOnFirstLogin', 'updatePasswordDTO', updatePasswordDTO)
            const localVarPath = `/updatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdatePasswordApi - functional programming interface
 * @export
 */
export const UpdatePasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdatePasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary update the password of a user on the first login
         * @param {UpdatePasswordDTO} updatePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePasswordOnFirstLogin(updatePasswordDTO: UpdatePasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePasswordOnFirstLogin(updatePasswordDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdatePasswordApi - factory interface
 * @export
 */
export const UpdatePasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdatePasswordApiFp(configuration)
    return {
        /**
         * 
         * @summary update the password of a user on the first login
         * @param {UpdatePasswordDTO} updatePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePasswordOnFirstLogin(updatePasswordDTO: UpdatePasswordDTO, options?: any): AxiosPromise<LoginResponseDTO> {
            return localVarFp.updatePasswordOnFirstLogin(updatePasswordDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdatePasswordApi - object-oriented interface
 * @export
 * @class UpdatePasswordApi
 * @extends {BaseAPI}
 */
export class UpdatePasswordApi extends BaseAPI {
    /**
     * 
     * @summary update the password of a user on the first login
     * @param {UpdatePasswordDTO} updatePasswordDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdatePasswordApi
     */
    public updatePasswordOnFirstLogin(updatePasswordDTO: UpdatePasswordDTO, options?: AxiosRequestConfig) {
        return UpdatePasswordApiFp(this.configuration).updatePasswordOnFirstLogin(updatePasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UseCaseApi - axios parameter creator
 * @export
 */
export const UseCaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new use case for a user based on existing general use case
         * @param {UseCaseDTO} useCaseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUseCase: async (useCaseDTO: UseCaseDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'useCaseDTO' is not null or undefined
            assertParamExists('createUseCase', 'useCaseDTO', useCaseDTO)
            const localVarPath = `/usecase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(useCaseDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a use case by ID
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUseCase: async (usecaseid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('deleteUseCase', 'usecaseid', usecaseid)
            const localVarPath = `/usecase/{usecaseid}`
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseCase: async (usecaseid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('getUseCase', 'usecaseid', usecaseid)
            const localVarPath = `/usecase/{usecaseid}`
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseCases: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usecase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a use case
         * @param {string} usecaseid 
         * @param {UseCaseDTO} useCaseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUseCase: async (usecaseid: string, useCaseDTO: UseCaseDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('updateUseCase', 'usecaseid', usecaseid)
            // verify required parameter 'useCaseDTO' is not null or undefined
            assertParamExists('updateUseCase', 'useCaseDTO', useCaseDTO)
            const localVarPath = `/usecase/{usecaseid}`
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(useCaseDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UseCaseApi - functional programming interface
 * @export
 */
export const UseCaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UseCaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new use case for a user based on existing general use case
         * @param {UseCaseDTO} useCaseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUseCase(useCaseDTO: UseCaseDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUseCase(useCaseDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a use case by ID
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUseCase(usecaseid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUseCase(usecaseid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUseCase(usecaseid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUseCase(usecaseid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUseCases(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCaseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUseCases(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a use case
         * @param {string} usecaseid 
         * @param {UseCaseDTO} useCaseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUseCase(usecaseid: string, useCaseDTO: UseCaseDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUseCase(usecaseid, useCaseDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UseCaseApi - factory interface
 * @export
 */
export const UseCaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UseCaseApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new use case for a user based on existing general use case
         * @param {UseCaseDTO} useCaseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUseCase(useCaseDTO: UseCaseDTO, options?: any): AxiosPromise<UseCaseDTO> {
            return localVarFp.createUseCase(useCaseDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a use case by ID
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUseCase(usecaseid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUseCase(usecaseid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseCase(usecaseid: string, options?: any): AxiosPromise<UseCaseDTO> {
            return localVarFp.getUseCase(usecaseid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseCases(options?: any): AxiosPromise<Array<UseCaseDTO>> {
            return localVarFp.getUseCases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a use case
         * @param {string} usecaseid 
         * @param {UseCaseDTO} useCaseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUseCase(usecaseid: string, useCaseDTO: UseCaseDTO, options?: any): AxiosPromise<UseCaseDTO> {
            return localVarFp.updateUseCase(usecaseid, useCaseDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UseCaseApi - object-oriented interface
 * @export
 * @class UseCaseApi
 * @extends {BaseAPI}
 */
export class UseCaseApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new use case for a user based on existing general use case
     * @param {UseCaseDTO} useCaseDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseApi
     */
    public createUseCase(useCaseDTO: UseCaseDTO, options?: AxiosRequestConfig) {
        return UseCaseApiFp(this.configuration).createUseCase(useCaseDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a use case by ID
     * @param {string} usecaseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseApi
     */
    public deleteUseCase(usecaseid: string, options?: AxiosRequestConfig) {
        return UseCaseApiFp(this.configuration).deleteUseCase(usecaseid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a use case for id
     * @param {string} usecaseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseApi
     */
    public getUseCase(usecaseid: string, options?: AxiosRequestConfig) {
        return UseCaseApiFp(this.configuration).getUseCase(usecaseid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all use cases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseApi
     */
    public getUseCases(options?: AxiosRequestConfig) {
        return UseCaseApiFp(this.configuration).getUseCases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a use case
     * @param {string} usecaseid 
     * @param {UseCaseDTO} useCaseDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseApi
     */
    public updateUseCase(usecaseid: string, useCaseDTO: UseCaseDTO, options?: AxiosRequestConfig) {
        return UseCaseApiFp(this.configuration).updateUseCase(usecaseid, useCaseDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UseCaseGeneralApi - axios parameter creator
 * @export
 */
export const UseCaseGeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new general UseCase
         * @param {UseCaseGeneralDTO} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralUseCase: async (useCaseGeneralDTO: UseCaseGeneralDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'useCaseGeneralDTO' is not null or undefined
            assertParamExists('createGeneralUseCase', 'useCaseGeneralDTO', useCaseGeneralDTO)
            const localVarPath = `/usecase/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(useCaseGeneralDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds multiple UseCaseGeneral
         * @param {Array<UseCaseGeneralDTO>} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipleUseCaseGeneral: async (useCaseGeneralDTO: Array<UseCaseGeneralDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'useCaseGeneralDTO' is not null or undefined
            assertParamExists('createMultipleUseCaseGeneral', 'useCaseGeneralDTO', useCaseGeneralDTO)
            const localVarPath = `/usecase/general/createMultiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(useCaseGeneralDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a general use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUseCase: async (usecaseid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('getGeneralUseCase', 'usecaseid', usecaseid)
            const localVarPath = `/usecase/general/{usecaseid}`
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all general use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUseCases: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usecase/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all general use cases for industrie
         * @param {string} industrie 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUseCasesByIndustrie: async (industrie: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'industrie' is not null or undefined
            assertParamExists('getGeneralUseCasesByIndustrie', 'industrie', industrie)
            const localVarPath = `/usecase/general/industrie/{industrie}`
                .replace(`{${"industrie"}}`, encodeURIComponent(String(industrie)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the \'geschaeftsfelderTags\' of all  general use cases with \'Banking\' industry to \'Finanzen und Buchhaltung\'.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessFieldsForBanking: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usecase/general/update-business-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a general use case
         * @param {string} usecaseid 
         * @param {UseCaseGeneralDTO} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralUseCase: async (usecaseid: string, useCaseGeneralDTO: UseCaseGeneralDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('updateGeneralUseCase', 'usecaseid', usecaseid)
            // verify required parameter 'useCaseGeneralDTO' is not null or undefined
            assertParamExists('updateGeneralUseCase', 'useCaseGeneralDTO', useCaseGeneralDTO)
            const localVarPath = `/usecase/general/{usecaseid}`
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(useCaseGeneralDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Industrie of all use case to Banking if the value in industrie is Empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralUseCasesIndustrie: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usecase/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the property ki-usecase of all use case to \"ja\" if the value in ki-usecase is Empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralUseCasesKiUsecase: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usecase/general/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UseCaseGeneralApi - functional programming interface
 * @export
 */
export const UseCaseGeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UseCaseGeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new general UseCase
         * @param {UseCaseGeneralDTO} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeneralUseCase(useCaseGeneralDTO: UseCaseGeneralDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseGeneralDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeneralUseCase(useCaseGeneralDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds multiple UseCaseGeneral
         * @param {Array<UseCaseGeneralDTO>} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultipleUseCaseGeneral(useCaseGeneralDTO: Array<UseCaseGeneralDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCaseGeneralDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultipleUseCaseGeneral(useCaseGeneralDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a general use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralUseCase(usecaseid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseGeneralDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralUseCase(usecaseid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all general use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralUseCases(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCaseGeneralDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralUseCases(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all general use cases for industrie
         * @param {string} industrie 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralUseCasesByIndustrie(industrie: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCaseGeneralDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralUseCasesByIndustrie(industrie, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the \'geschaeftsfelderTags\' of all  general use cases with \'Banking\' industry to \'Finanzen und Buchhaltung\'.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBusinessFieldsForBanking(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessFieldsForBanking(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a general use case
         * @param {string} usecaseid 
         * @param {UseCaseGeneralDTO} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralUseCase(usecaseid: string, useCaseGeneralDTO: UseCaseGeneralDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseGeneralDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralUseCase(usecaseid, useCaseGeneralDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the Industrie of all use case to Banking if the value in industrie is Empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralUseCasesIndustrie(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralUseCasesIndustrie(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the property ki-usecase of all use case to \"ja\" if the value in ki-usecase is Empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralUseCasesKiUsecase(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralUseCasesKiUsecase(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UseCaseGeneralApi - factory interface
 * @export
 */
export const UseCaseGeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UseCaseGeneralApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new general UseCase
         * @param {UseCaseGeneralDTO} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralUseCase(useCaseGeneralDTO: UseCaseGeneralDTO, options?: any): AxiosPromise<UseCaseGeneralDTO> {
            return localVarFp.createGeneralUseCase(useCaseGeneralDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds multiple UseCaseGeneral
         * @param {Array<UseCaseGeneralDTO>} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipleUseCaseGeneral(useCaseGeneralDTO: Array<UseCaseGeneralDTO>, options?: any): AxiosPromise<Array<UseCaseGeneralDTO>> {
            return localVarFp.createMultipleUseCaseGeneral(useCaseGeneralDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a general use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUseCase(usecaseid: string, options?: any): AxiosPromise<UseCaseGeneralDTO> {
            return localVarFp.getGeneralUseCase(usecaseid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all general use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUseCases(options?: any): AxiosPromise<Array<UseCaseGeneralDTO>> {
            return localVarFp.getGeneralUseCases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all general use cases for industrie
         * @param {string} industrie 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUseCasesByIndustrie(industrie: string, options?: any): AxiosPromise<Array<UseCaseGeneralDTO>> {
            return localVarFp.getGeneralUseCasesByIndustrie(industrie, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the \'geschaeftsfelderTags\' of all  general use cases with \'Banking\' industry to \'Finanzen und Buchhaltung\'.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessFieldsForBanking(options?: any): AxiosPromise<void> {
            return localVarFp.updateBusinessFieldsForBanking(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a general use case
         * @param {string} usecaseid 
         * @param {UseCaseGeneralDTO} useCaseGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralUseCase(usecaseid: string, useCaseGeneralDTO: UseCaseGeneralDTO, options?: any): AxiosPromise<UseCaseGeneralDTO> {
            return localVarFp.updateGeneralUseCase(usecaseid, useCaseGeneralDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the Industrie of all use case to Banking if the value in industrie is Empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralUseCasesIndustrie(options?: any): AxiosPromise<void> {
            return localVarFp.updateGeneralUseCasesIndustrie(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the property ki-usecase of all use case to \"ja\" if the value in ki-usecase is Empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralUseCasesKiUsecase(options?: any): AxiosPromise<void> {
            return localVarFp.updateGeneralUseCasesKiUsecase(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UseCaseGeneralApi - object-oriented interface
 * @export
 * @class UseCaseGeneralApi
 * @extends {BaseAPI}
 */
export class UseCaseGeneralApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new general UseCase
     * @param {UseCaseGeneralDTO} useCaseGeneralDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public createGeneralUseCase(useCaseGeneralDTO: UseCaseGeneralDTO, options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).createGeneralUseCase(useCaseGeneralDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds multiple UseCaseGeneral
     * @param {Array<UseCaseGeneralDTO>} useCaseGeneralDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public createMultipleUseCaseGeneral(useCaseGeneralDTO: Array<UseCaseGeneralDTO>, options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).createMultipleUseCaseGeneral(useCaseGeneralDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a general use case for id
     * @param {string} usecaseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public getGeneralUseCase(usecaseid: string, options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).getGeneralUseCase(usecaseid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all general use cases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public getGeneralUseCases(options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).getGeneralUseCases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all general use cases for industrie
     * @param {string} industrie 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public getGeneralUseCasesByIndustrie(industrie: string, options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).getGeneralUseCasesByIndustrie(industrie, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the \'geschaeftsfelderTags\' of all  general use cases with \'Banking\' industry to \'Finanzen und Buchhaltung\'.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public updateBusinessFieldsForBanking(options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).updateBusinessFieldsForBanking(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a general use case
     * @param {string} usecaseid 
     * @param {UseCaseGeneralDTO} useCaseGeneralDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public updateGeneralUseCase(usecaseid: string, useCaseGeneralDTO: UseCaseGeneralDTO, options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).updateGeneralUseCase(usecaseid, useCaseGeneralDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the Industrie of all use case to Banking if the value in industrie is Empty
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public updateGeneralUseCasesIndustrie(options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).updateGeneralUseCasesIndustrie(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the property ki-usecase of all use case to \"ja\" if the value in ki-usecase is Empty
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseGeneralApi
     */
    public updateGeneralUseCasesKiUsecase(options?: AxiosRequestConfig) {
        return UseCaseGeneralApiFp(this.configuration).updateGeneralUseCasesKiUsecase(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UseCaseUserGeneralApi - axios parameter creator
 * @export
 */
export const UseCaseUserGeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new use case for a customer
         * @param {UseCaseUserGeneralDTO} useCaseUserGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralUserUseCase: async (useCaseUserGeneralDTO: UseCaseUserGeneralDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'useCaseUserGeneralDTO' is not null or undefined
            assertParamExists('createGeneralUserUseCase', 'useCaseUserGeneralDTO', useCaseUserGeneralDTO)
            const localVarPath = `/usecase/general/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(useCaseUserGeneralDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a general use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUserUseCase: async (usecaseid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('getGeneralUserUseCase', 'usecaseid', usecaseid)
            const localVarPath = `/usecase/general/customer/{usecaseid}`
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all general user use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUserUseCases: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usecase/general/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a general user use case
         * @param {string} usecaseid 
         * @param {UseCaseUserGeneralDTO} useCaseUserGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralUserUseCase: async (usecaseid: string, useCaseUserGeneralDTO: UseCaseUserGeneralDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usecaseid' is not null or undefined
            assertParamExists('updateGeneralUserUseCase', 'usecaseid', usecaseid)
            // verify required parameter 'useCaseUserGeneralDTO' is not null or undefined
            assertParamExists('updateGeneralUserUseCase', 'useCaseUserGeneralDTO', useCaseUserGeneralDTO)
            const localVarPath = `/usecase/general/customer/{usecaseid}`
                .replace(`{${"usecaseid"}}`, encodeURIComponent(String(usecaseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(useCaseUserGeneralDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UseCaseUserGeneralApi - functional programming interface
 * @export
 */
export const UseCaseUserGeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UseCaseUserGeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new use case for a customer
         * @param {UseCaseUserGeneralDTO} useCaseUserGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeneralUserUseCase(useCaseUserGeneralDTO: UseCaseUserGeneralDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseUserGeneralDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeneralUserUseCase(useCaseUserGeneralDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a general use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralUserUseCase(usecaseid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseUserGeneralDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralUserUseCase(usecaseid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all general user use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralUserUseCases(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCaseUserGeneralDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralUserUseCases(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a general user use case
         * @param {string} usecaseid 
         * @param {UseCaseUserGeneralDTO} useCaseUserGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralUserUseCase(usecaseid: string, useCaseUserGeneralDTO: UseCaseUserGeneralDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCaseUserGeneralDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralUserUseCase(usecaseid, useCaseUserGeneralDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UseCaseUserGeneralApi - factory interface
 * @export
 */
export const UseCaseUserGeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UseCaseUserGeneralApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new use case for a customer
         * @param {UseCaseUserGeneralDTO} useCaseUserGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralUserUseCase(useCaseUserGeneralDTO: UseCaseUserGeneralDTO, options?: any): AxiosPromise<UseCaseUserGeneralDTO> {
            return localVarFp.createGeneralUserUseCase(useCaseUserGeneralDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a general use case for id
         * @param {string} usecaseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUserUseCase(usecaseid: string, options?: any): AxiosPromise<UseCaseUserGeneralDTO> {
            return localVarFp.getGeneralUserUseCase(usecaseid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all general user use cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralUserUseCases(options?: any): AxiosPromise<Array<UseCaseUserGeneralDTO>> {
            return localVarFp.getGeneralUserUseCases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a general user use case
         * @param {string} usecaseid 
         * @param {UseCaseUserGeneralDTO} useCaseUserGeneralDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralUserUseCase(usecaseid: string, useCaseUserGeneralDTO: UseCaseUserGeneralDTO, options?: any): AxiosPromise<UseCaseUserGeneralDTO> {
            return localVarFp.updateGeneralUserUseCase(usecaseid, useCaseUserGeneralDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UseCaseUserGeneralApi - object-oriented interface
 * @export
 * @class UseCaseUserGeneralApi
 * @extends {BaseAPI}
 */
export class UseCaseUserGeneralApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new use case for a customer
     * @param {UseCaseUserGeneralDTO} useCaseUserGeneralDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseUserGeneralApi
     */
    public createGeneralUserUseCase(useCaseUserGeneralDTO: UseCaseUserGeneralDTO, options?: AxiosRequestConfig) {
        return UseCaseUserGeneralApiFp(this.configuration).createGeneralUserUseCase(useCaseUserGeneralDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a general use case for id
     * @param {string} usecaseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseUserGeneralApi
     */
    public getGeneralUserUseCase(usecaseid: string, options?: AxiosRequestConfig) {
        return UseCaseUserGeneralApiFp(this.configuration).getGeneralUserUseCase(usecaseid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all general user use cases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseUserGeneralApi
     */
    public getGeneralUserUseCases(options?: AxiosRequestConfig) {
        return UseCaseUserGeneralApiFp(this.configuration).getGeneralUserUseCases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a general user use case
     * @param {string} usecaseid 
     * @param {UseCaseUserGeneralDTO} useCaseUserGeneralDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseUserGeneralApi
     */
    public updateGeneralUserUseCase(usecaseid: string, useCaseUserGeneralDTO: UseCaseUserGeneralDTO, options?: AxiosRequestConfig) {
        return UseCaseUserGeneralApiFp(this.configuration).updateGeneralUserUseCase(usecaseid, useCaseUserGeneralDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds multiple users
         * @param {Array<UserInputDTO>} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipleUsers: async (userInputDTO: Array<UserInputDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInputDTO' is not null or undefined
            assertParamExists('createMultipleUsers', 'userInputDTO', userInputDTO)
            const localVarPath = `/user/createMultiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a new user
         * @param {UserInputDTO} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userInputDTO: UserInputDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInputDTO' is not null or undefined
            assertParamExists('createUser', 'userInputDTO', userInputDTO)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a user by id
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('deleteUser', 'userid', userid)
            const localVarPath = `/user/{userid}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all admin users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all consultants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConsultantUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/consultant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all sales users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSalesUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a customer by id
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById: async (customerid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('getCustomerById', 'customerid', customerid)
            const localVarPath = `/user/customer/{customerid}`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUseCases: async (customerid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('getCustomerUseCases', 'customerid', customerid)
            const localVarPath = `/user/{customerid}/usecases`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all general use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUseCasesGeneral: async (customerid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('getCustomerUseCasesGeneral', 'customerid', customerid)
            const localVarPath = `/user/{customerid}/usecases/general`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all favorite use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersFavoriteUseCases: async (customerid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('getCustomersFavoriteUseCases', 'customerid', customerid)
            const localVarPath = `/user/{customerid}/favoriteusecases`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a user by id
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('getUserById', 'userid', userid)
            const localVarPath = `/user/{userid}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user
         * @param {string} userid 
         * @param {UserInputDTO} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userid: string, userInputDTO: UserInputDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('updateUser', 'userid', userid)
            // verify required parameter 'userInputDTO' is not null or undefined
            assertParamExists('updateUser', 'userInputDTO', userInputDTO)
            const localVarPath = `/user/{userid}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds multiple users
         * @param {Array<UserInputDTO>} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultipleUsers(userInputDTO: Array<UserInputDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutputDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultipleUsers(userInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a new user
         * @param {UserInputDTO} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userInputDTO: UserInputDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a user by id
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all admin users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAdminUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutputDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAdminUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all consultants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConsultantUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutputDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConsultantUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomerUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutputDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomerUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all sales users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSalesUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutputDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSalesUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a customer by id
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerById(customerid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerById(customerid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerUseCases(customerid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCaseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerUseCases(customerid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all general use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerUseCasesGeneral(customerid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCaseUserGeneralDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerUseCasesGeneral(customerid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all favorite use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersFavoriteUseCases(customerid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersFavoriteUseCases(customerid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a user by id
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutputDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a user
         * @param {string} userid 
         * @param {UserInputDTO} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userid: string, userInputDTO: UserInputDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userid, userInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds multiple users
         * @param {Array<UserInputDTO>} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipleUsers(userInputDTO: Array<UserInputDTO>, options?: any): AxiosPromise<Array<UserOutputDTO>> {
            return localVarFp.createMultipleUsers(userInputDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a new user
         * @param {UserInputDTO} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userInputDTO: UserInputDTO, options?: any): AxiosPromise<UserOutputDTO> {
            return localVarFp.createUser(userInputDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a user by id
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all admin users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminUsers(options?: any): AxiosPromise<Array<UserOutputDTO>> {
            return localVarFp.getAllAdminUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all consultants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConsultantUsers(options?: any): AxiosPromise<Array<UserOutputDTO>> {
            return localVarFp.getAllConsultantUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerUsers(options?: any): AxiosPromise<Array<UserOutputDTO>> {
            return localVarFp.getAllCustomerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all sales users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSalesUsers(options?: any): AxiosPromise<Array<UserOutputDTO>> {
            return localVarFp.getAllSalesUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a customer by id
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById(customerid: string, options?: any): AxiosPromise<UserOutputDTO> {
            return localVarFp.getCustomerById(customerid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUseCases(customerid: string, options?: any): AxiosPromise<Array<UseCaseDTO>> {
            return localVarFp.getCustomerUseCases(customerid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all general use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUseCasesGeneral(customerid: string, options?: any): AxiosPromise<Array<UseCaseUserGeneralDTO>> {
            return localVarFp.getCustomerUseCasesGeneral(customerid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all favorite use cases for a customer
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersFavoriteUseCases(customerid: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCustomersFavoriteUseCases(customerid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a user by id
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userid: string, options?: any): AxiosPromise<UserOutputDTO> {
            return localVarFp.getUserById(userid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserOutputDTO>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user
         * @param {string} userid 
         * @param {UserInputDTO} userInputDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userid: string, userInputDTO: UserInputDTO, options?: any): AxiosPromise<UserOutputDTO> {
            return localVarFp.updateUser(userid, userInputDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Adds multiple users
     * @param {Array<UserInputDTO>} userInputDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createMultipleUsers(userInputDTO: Array<UserInputDTO>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createMultipleUsers(userInputDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a new user
     * @param {UserInputDTO} userInputDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(userInputDTO: UserInputDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(userInputDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a user by id
     * @param {string} userid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(userid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(userid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all admin users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllAdminUsers(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllAdminUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all consultants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllConsultantUsers(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllConsultantUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all customers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllCustomerUsers(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllCustomerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all sales users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllSalesUsers(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllSalesUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a customer by id
     * @param {string} customerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCustomerById(customerid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCustomerById(customerid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all use cases for a customer
     * @param {string} customerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCustomerUseCases(customerid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCustomerUseCases(customerid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all general use cases for a customer
     * @param {string} customerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCustomerUseCasesGeneral(customerid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCustomerUseCasesGeneral(customerid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all favorite use cases for a customer
     * @param {string} customerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCustomersFavoriteUseCases(customerid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCustomersFavoriteUseCases(customerid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a user by id
     * @param {string} userid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(userid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserById(userid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user
     * @param {string} userid 
     * @param {UserInputDTO} userInputDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(userid: string, userInputDTO: UserInputDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(userid, userInputDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


