import { LoginApi } from '../api';

const authApi = () => {
  const login = async (username: string, password: string) => {
    const {
      data: { accessToken, message },
    } = await new LoginApi().login({ username, password });
    if (message) {
      return true;
    }
    document.cookie = accessToken;
    localStorage.setItem('token', accessToken);
    //localStorage.setItem('refreshToken', refreshToken);

    return false;
  };

  const setAccessToken = (token: string) => {
    document.cookie = token;
    localStorage.setItem('token', token);
  };
  const getToken = () => localStorage.getItem('token');
  //const getRefreshToken = () => localStorage.getItem('refreshToken');
  const logout = () => {
    //localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    document.cookie = null;
  };

  /*
  const refreshAccessToken = async () => {
    const res = await new RefreshTokenApi().refreshToken(getRefreshToken());
    localStorage.setItem('token', res.data);
  };*/

  return { login, getToken, logout, setAccessToken };
};

export default authApi;
