import { Input } from '@chakra-ui/react';
import React, { ElementRef, forwardRef, memo } from 'react';
import { KeysWithValuesOfType } from '../../../../types';
import { UseCaseDTO } from '../../../../api';
import { useUpdateField } from '../useUpdateField';
import { useCustomerSelectorMode } from '../../UserSelector/useCustomerSelectorMode';

type Props = { field: KeysWithValuesOfType<UseCaseDTO, string> };

export const TitleInput = memo(
  forwardRef<ElementRef<'input'>, Props>(function TitleInput(props, ref) {
    const { field } = props;

    const { value, update, readOnly } = useUpdateField(field);
    const { customerMode } = useCustomerSelectorMode();

    return (
      <Input
        readOnly={Boolean(customerMode)}
        ref={ref}
        {...props}
        value={value ?? ''}
        isReadOnly={readOnly}
        onChange={e => update(e.target.value)}
        variant="unstyled"
        my="3rem"
        fontSize="3xl"
        fontWeight="semibold"
        color="primary"
        textAlign="center"
        _hover={{ bg: 'blackAlpha.100' }}
        _readOnly={{ _hover: { bg: 'none' } }}
      />
    );
  })
);
