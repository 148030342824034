import { useNavigate, useParams } from 'react-router-dom';
import React, { memo, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  HStack,
  Spacer,
  Stack,
  useToast,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  Flex,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { ScrollToTop } from '../../ScrollToTop';
import { PreviousPageButton } from '../../controls/PreviousPageButton';

import SpinnerUI from 'src/components/common/Spinner';
import { useCustomerSelectorMode } from '../../../UserSelector/useCustomerSelectorMode';
import { useGeneralUseCaseStore } from 'src/store/useGeneralUseCaseStore';
import { GeneralDescriptionSection } from './GeneralDescription';
import GeneralUsecaseSection from './GeneralUsecaseSection';
import { isAdmin } from 'src/auth/scope';
import { useCaseApi, useUseCaseStore } from 'src/store/useUseCaseStore';

export const GeneralUseCaseDetails = memo(function GeneralUseCaseDetails() {
  const { useCaseId } = useParams();
  const getGeneralUseCaseById = useGeneralUseCaseStore(state => state.getGeneralUseCaseById);
  const saveChanges = useGeneralUseCaseStore(state => state.saveChanges);
  const { allgemeinMode } = useCustomerSelectorMode();
  const toast = useToast();
  const navigate = useNavigate();
  const getGeneralUseCases = useUseCaseStore(state => state.getGeneralUseCases);

  const dataIsLoading = useGeneralUseCaseStore(state => state.isLoading);

  const deleteUseCase = async () => {
    await useCaseApi.deleteUseCase(useCaseId);
    await getGeneralUseCases();
    navigate('/usecases');
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const saveChangesHandler = useCallback(async () => {
    try {
      await saveChanges();

      toast.closeAll();
      toast({
        status: 'success',
        position: 'top-right',
        description: 'Änderungen erfolgreich gespeichert',
      });
    } catch (e) {
      toast.closeAll();
      toast({
        position: 'top-right',
        status: 'error',
        description: 'Änderungen konnten nicht gespeichert werden',
      });
    }
  }, []);

  useEffect(() => {
    if (allgemeinMode) {
      getGeneralUseCaseById(useCaseId);
    }
  }, [useCaseId, allgemeinMode]);

  if (dataIsLoading) return <SpinnerUI />;

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent p={6}>
          <Flex direction="column" align="center">
            <ModalHeader>Use-Case löschen</ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" justify="space-between" align="center" gap="20px">
              <Text>Möchten sie den Use-Case wirklich löschen?</Text>

              <Flex direction="row" justify="space-between" align="center" gap="20px">
                <Button className="unstyledBtn" onClick={onClose}>
                  Abbrechen
                </Button>
                <Button variant="accent" onClick={deleteUseCase}>
                  Löschen
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box>
        <PreviousPageButton url={'/usecases'} />
        <GeneralDescriptionSection />
        <Box bg="primary" pt={3} pb={4}>
          <Container maxW="container.md">
            <Stack direction={['column', 'row']} gap={20}>
              <GeneralUsecaseSection />
            </Stack>
          </Container>
        </Box>
        <ScrollToTop />
        <Box position="sticky" backgroundColor="white" shadow="2xl" bottom="0" width="full">
          <Container maxWidth="container.md">
            <HStack p="4" spacing="4">
              <Spacer />
              {isAdmin() && (
                <Button variant="accent" onClick={onOpen}>
                  Use-Case löschen
                </Button>
              )}
              <Button variant="accent" onClick={saveChangesHandler}>
                Änderungen speichern
              </Button>
            </HStack>
          </Container>
        </Box>
      </Box>
    </>
  );
});
