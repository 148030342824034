import React, { memo } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon, EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { FiMoreVertical } from 'react-icons/fi';
import { ContactDTO, UserOutputDTO } from '../../../../api';
import { isReadOnly } from '../../../../auth/scope';
import { useRelationshipStore } from '../../../../store/useRelationshipStore';
import { useUserStore } from '../../../../store/useUserStore';

export const ContactCard = memo(function ContactCard({ contact }: { contact: ContactDTO }) {
  const readOnly = isReadOnly();
  const deleteCustomerContactRelationship = useRelationshipStore(
    state => state.deleteCustomerContactRelationship
  );
  const user: UserOutputDTO = useUserStore().selectedUser;
  const removeContact = useUserStore(state => state.removeContact);

  const remove = (contact: ContactDTO) => {
    deleteCustomerContactRelationship(user.id, contact.id);
    removeContact(contact.id);
  };

  return (
    <Card p={4} borderRadius="20px" direction={{ base: 'column', sm: 'row' }} flexBasis="32%">
      <CardBody>
        <Flex align="center" gap="40px">
          <Flex direction="column" gap="10px">
            <Box>
              <Heading as="h4" size="md">
                {contact.name}
              </Heading>
            </Box>
            <Flex direction="column" color="primary" fontSize="sm" gap="5px">
              <Link href={'mailto:' + contact.email}>
                {<EmailIcon mr={2} />}
                {contact.email}
              </Link>
              <Text>
                {<PhoneIcon mr={2} />}
                {contact.telNr}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
      {!readOnly && (
        <Menu>
          <MenuButton
            color="accent"
            fontSize="25px"
            variant="unstyled"
            as={IconButton}
            aria-label="more"
            icon={<Icon as={FiMoreVertical} />}
          />
          <MenuList border="none" boxShadow="var(--card-shadow)" className="customMenu">
            <Button variant="unstyled" leftIcon={<DeleteIcon />} onClick={() => remove(contact)}>
              Löschen
            </Button>
          </MenuList>
        </Menu>
      )}
    </Card>
  );
});
