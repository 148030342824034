import { KeysWithValuesOfType } from '../../../../types';
import { UseCaseDTO } from '../../../../api';
import { useUpdateField } from '../useUpdateField';
import { Flex, Switch, Text } from '@chakra-ui/react';
import React, { memo, useEffect } from 'react';
import { isAdmin, isConsultantUser } from 'src/auth/scope';

const STRING_TRUE = 'ja';
const STRING_FALSE = 'nein';

const stringifyBoolean = (value: boolean) => {
  return value ? STRING_TRUE : STRING_FALSE;
};

export const SwitchInput = memo(function SwitchInput({
  field,
  title,
  onChange,
  isDisabled,
  dependentValue,
}: {
  field: KeysWithValuesOfType<UseCaseDTO, string | boolean>;
  title?: string;
  onChange?: (value: boolean) => void;
  isDisabled?: boolean;
  dependentValue?: boolean;
}) {
  const { value, update, readOnly } = useUpdateField(field);

  const isString = typeof value === 'string';

  const isChecked = isString ? value === STRING_TRUE : value;

  useEffect(() => {
    if (onChange) {
      onChange(isChecked);
    }
    if (dependentValue === false && isChecked) {
      update(false);
    }
  }, [isChecked, onChange, dependentValue]);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {title && <Text>{title}</Text>}
      {(isAdmin() || isConsultantUser()) && (
        <Switch
          size="lg"
          isChecked={isChecked}
          isDisabled={isDisabled}
          data-test="switch-input"
          onChange={e => update(isString ? stringifyBoolean(e.target.checked) : e.target.checked)}
        />
      )}
      {readOnly && !isConsultantUser() && <Text>{stringifyBoolean(isChecked)}</Text>}
    </Flex>
  );
});
