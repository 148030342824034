import React, { memo } from 'react';
import { Box, Container } from '@chakra-ui/react';
import { getYear } from 'date-fns';
import { MainLogo } from './MainLogo';
import { Link } from 'react-router-dom';

const Footer = memo(function Footer() {
  const currentYear = getYear(new Date());
  return (
    <Box bg="primaryDark">
      <Container maxW="container.xl" py={20} color="white">
        <Link to="/">
          <MainLogo />
          &copy; {currentYear}
        </Link>
      </Container>
    </Box>
  );
});

export default Footer;
