import { useState, memo, useEffect } from 'react';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  MenuDivider,
} from '@chakra-ui/react';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';
import { FaUsers, FaUser } from 'react-icons/fa';
import { UseCaseDTO, UserOutputDTO } from '../../../api';
import { UserModeEnum, selectSelectedUser, useUserStore, userApi } from 'src/store/useUserStore';
import { UserProfile } from './UserProfile';
import { UserListItem } from './UserListItem';
import { useCustomerSelectorMode } from './useCustomerSelectorMode';
import { useUseCaseStore } from 'src/store/useUseCaseStore';
import { IconType } from 'react-icons/lib';
import { isConsultantUser } from 'src/auth/scope';

const Allgemein = memo(function Allgemein() {
  return (
    <Flex py="2" px="2" alignItems="center">
      <Icon w={8} h={8} as={FaUsers} />
      <Box ml={4}>
        <Text fontWeight="500" fontSize={'md'}>
          Allgemein
        </Text>
      </Box>
    </Flex>
  );
});

const UserSection = memo(function UserSection({
  icon,
  userTitle,
  users,
  handleUserSelect,
}: {
  icon: IconType;
  userTitle: string;
  users: UseCaseDTO[];
  handleUserSelect: (user: UserOutputDTO) => void;
}) {
  const selectedUser = useUserStore(selectSelectedUser);
  return (
    <>
      <MenuDivider />
      <Flex borderTop="3px" borderColor="gray.200" color="black" p="4" alignItems="center">
        <Icon w={8} h={6} as={icon} />
        <Text marginLeft="6" fontWeight="500" fontSize={'md'}>
          {userTitle}
        </Text>
      </Flex>
      {users.map(user => (
        <UserListItem
          key={user.id}
          user={user}
          isSelected={selectedUser?.id === user.id}
          onSelect={handleUserSelect}
        />
      ))}
    </>
  );
});

const UserSelector = memo(function UserSelector() {
  const [searchQuery, setSearchQuery] = useState('');
  const [consultantUser, setConsultantUser] = useState<UserOutputDTO[]>([]);
  const [customerUser, setCustomerUser] = useState<UserOutputDTO[]>([]);
  const [salesUser, setSalesUser] = useState<UserOutputDTO[]>([]);
  const getUsers = useUserStore(state => state.getCustomerUsers);

  const setUserMode = useUserStore(state => state.setUserMode);
  const { allgemeinMode, customerMode } = useCustomerSelectorMode();

  const selectedUser = useUserStore(selectSelectedUser);
  const setUser = useUserStore(state => state.setSelectedUser);
  const getCustomerUsers = useUserStore(state => state.getCustomerUsers);

  const getAllUseCases = useUseCaseStore(state => state.getAllUseCases);

  async function getConsultantUser() {
    const users = (await userApi.getAllConsultantUsers()).data;

    setConsultantUser(users);
  }

  async function getCustomerUser() {
    const users = (await userApi.getAllCustomerUsers()).data;

    setCustomerUser(users);
  }

  async function getSalesUser() {
    const users = (await userApi.getAllSalesUsers()).data;

    setSalesUser(users);
  }

  useEffect(() => {
    if (isConsultantUser()) getCustomerUsers();
    if (!isConsultantUser()) {
      getConsultantUser();
      getSalesUser();
      getUsers();
    }
    getCustomerUser();
  }, []);

  const handleAdminSelect = () => {
    setUser(null);
    setUserMode(UserModeEnum.allgemein);
  };

  const handleUserSelect = (user: UserOutputDTO) => {
    setUser(user);
    setUserMode(UserModeEnum.userSpezifisch);
    getAllUseCases();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  function filterUser(users: UserOutputDTO[]): UserOutputDTO[] {
    return users.filter(user => user.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }

  return (
    <Menu>
      <MenuButton minWidth="300px" px="4" py="2" borderRadius="md" borderWidth="1px">
        <Flex alignItems="center" gap="4" justifyContent="space-between">
          {allgemeinMode === UserModeEnum.allgemein ? (
            <Allgemein />
          ) : customerMode?.mode === UserModeEnum.userSpezifisch ? (
            <UserProfile name={selectedUser?.name} />
          ) : (
            'Select Customer Profile'
          )}
          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      <MenuList zIndex={2} maxHeight="350px" overflow="scroll" maxW="none">
        <Box p={2} color="black">
          <InputGroup variant="filled" minWidth={'300px'}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon />
            </InputLeftElement>
            <Input
              placeholder="Suchen"
              type="text"
              onChange={handleSearchChange}
              value={searchQuery}
            />
          </InputGroup>
        </Box>
        <MenuDivider />

        {!isConsultantUser() && (
          <MenuItem
            borderTop="3px"
            borderColor="red"
            onClick={handleAdminSelect}
            color="black"
            backgroundColor={allgemeinMode === UserModeEnum.allgemein ? 'gray.50' : 'white'}>
            <Allgemein />
          </MenuItem>
        )}
        <UserSection
          icon={FaUser}
          userTitle="Kunden"
          handleUserSelect={handleUserSelect}
          users={filterUser(customerUser)}
        />
        {!isConsultantUser() && (
          <>
            <UserSection
              icon={FaUser}
              userTitle="Consultant"
              handleUserSelect={handleUserSelect}
              users={filterUser(consultantUser)}
            />

            <UserSection
              icon={FaUser}
              userTitle="Sales"
              handleUserSelect={handleUserSelect}
              users={filterUser(salesUser)}
            />
          </>
        )}
      </MenuList>
    </Menu>
  );
});

export default UserSelector;
