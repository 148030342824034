import React, { memo, useCallback, useEffect } from 'react';
import { Box, Button, Container, HStack, Spacer, Stack, useToast } from '@chakra-ui/react';
import { ScrollToTop } from '../../ScrollToTop';
import { PreviousPageButton } from '../../controls/PreviousPageButton';
import { Navigate } from 'react-router-dom';

import SpinnerUI from 'src/components/common/Spinner';
import { useCustomerSelectorMode } from '../../../UserSelector/useCustomerSelectorMode';
import { useGeneralUseCaseStore } from 'src/store/useGeneralUseCaseStore';
import { GeneralDescriptionSection } from './GeneralDescription';
import GeneralUsecaseSection from './GeneralUsecaseSection';
import { isReadOnly } from 'src/auth/scope';

export const CreateGeneralUseCase = memo(function CreateGeneralUseCase() {
  const setEmptyUseCase = useGeneralUseCaseStore(state => state.setEmptyUseCase);
  const saveChanges = useGeneralUseCaseStore(state => state.saveChanges);
  const { allgemeinMode, customerMode } = useCustomerSelectorMode();
  const toast = useToast();
  const getGeneralUseCases = useGeneralUseCaseStore(state => state.getGeneralUseCases);

  const dataIsLoading = useGeneralUseCaseStore(state => state.isLoading);

  const saveChangesHandler = useCallback(async () => {
    try {
      await saveChanges();

      toast.closeAll();
      toast({
        status: 'success',
        position: 'top-right',
        description: 'Use-Case erfolgreich erstellt',
      });
    } catch (e: any) {
      toast.closeAll();
      toast({
        position: 'top-right',
        status: 'error',
        description: e.message,
      });
    }
  }, []);

  useEffect(() => {
    if (allgemeinMode || customerMode) {
      setEmptyUseCase();
    }

    if (customerMode) {
      getGeneralUseCases();
    }
  }, [allgemeinMode]);

  if (isReadOnly()) return <Navigate to="/" />;
  if (dataIsLoading) return <SpinnerUI />;

  return (
    <Box>
      <PreviousPageButton url={'/usecases'} />
      <GeneralDescriptionSection />
      <Box bg="primary" pt={3} pb={4}>
        <Container maxW="container.md">
          <Stack direction={['column', 'row']} gap={20}>
            <GeneralUsecaseSection />
          </Stack>
        </Container>
      </Box>
      <ScrollToTop />
      <Box position="sticky" backgroundColor="white" shadow="2xl" bottom="0" width="full">
        <Container maxWidth="container.md">
          <HStack p="4" spacing="4">
            <Spacer />
            <Button variant="accent" onClick={saveChangesHandler}>
              Änderungen speichern
            </Button>
          </HStack>
        </Container>
      </Box>
    </Box>
  );
});
