import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['table', 'th', 'td', 'tr', 'tbody', 'a']);

export const Table = helpers.defineMultiStyleConfig({
  variants: {
    simple: {
      table: {
        tableLayout: 'fixed',
        borderCollapse: 'separate',
        borderSpacing: 0,
      },
      th: {
        _first: {
          roundedTopLeft: 'lg',
          roundedBottomLeft: 'lg',
        },
        _last: {
          roundedTopRight: 'lg',
          roundedBottomRight: 'lg',
        },
        _notLast: {
          borderRightWidth: 1,
          borderRightStyle: 'solid',
          borderRightColor: 'whiteAlpha.200',
        },
        letterSpacing: 'normal',
        px: '6',
        py: '6',
        bg: 'primary',
        color: 'white',
        fontSize: 'md',
        fontWeight: 'normal',
        textTransform: 'none',
        position: 'sticky',
        top: '0',
        borderBottom: 0,
        zIndex: '1',
      },
      td: {
        bg: 'white',
        px: '6',
        height: '28',
        borderColor: 'gray.50',
      },
      tbody: {
        _before: {
          content: '"-"',
          lineHeight: '0.5rem',
          display: 'block',
          color: 'transparent',
        },
        a: {
          verticalAlign: 'middle',
          display: 'table-row',

          _first: {
            td: {
              _first: {
                roundedTopLeft: 'lg',
              },
              _last: {
                roundedTopRight: 'lg',
              },
            },
          },
          _last: {
            td: {
              _first: {
                roundedBottomLeft: 'lg',
              },
              _last: {
                roundedBottomRight: 'lg',
              },
              border: 'none',
            },
          },
          _hover: {
            textDecoration: 'none',

            td: {
              bg: 'whiteAlpha.600',
            },
          },
        },
      },
    },
  },
});
