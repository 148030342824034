import React, { ElementRef, forwardRef, memo } from 'react';
import { Flex, Input, Text } from '@chakra-ui/react';
import { KeysWithValuesOfType } from '../../../../types';
import { UseCaseDTO } from '../../../../api';
import { useUpdateField } from '../useUpdateField';
import { isAdmin } from '../../../../auth/scope';

type TextInputProps = {
  field: KeysWithValuesOfType<UseCaseDTO, string>;
  title?: string;
};

export const TextInput = memo(
  forwardRef<ElementRef<'input'>, TextInputProps>(function TextInput(props, ref) {
    const { field, title } = props;

    const { value, update } = useUpdateField(field);
    const adminUser = isAdmin();

    return (
      <Flex justifyContent="space-between" alignItems="center">
        {title && <Text>{title}</Text>}
        {adminUser && (
          <Input
            ref={ref}
            {...props}
            value={value ?? ''}
            onChange={e => update(e.target.value)}
            variant="outlined"
            textAlign="right"
            marginLeft={20}
            _hover={{ bg: 'blackAlpha.100' }}
            _readOnly={{ _hover: { bg: 'none' } }}
          />
        )}
        {!adminUser && <Text>{value}</Text>}
      </Flex>
    );
  })
);
