import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

interface ProtectedRouteProps {
  redirectPath?: string;
  Component?: React.NamedExoticComponent<object>;
}

const AuthRoute = ({ redirectPath = 'login', Component }: ProtectedRouteProps) => {
  const { tokenData } = useAuth();
  //const tokenData = document.cookie;

  if (!tokenData) {
    return <Navigate to={redirectPath} replace />;
  }

  return Component ? <Route element={<Component />} /> : <Outlet />;
};

export default AuthRoute;
