import React, { memo } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  Spacer,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { MainLogo } from './MainLogo';
import { MoreMenu } from './pages/UseCaseDetails/controls/MoreMenu';
import UserSelector from './pages/UserSelector/UserSelector';
import { isAdmin, isConsultantUser, isReadOnly } from 'src/auth/scope';
import { useAuth } from '../auth/useAuth';
import authApi from 'src/auth/authApi';

const Header = memo(function Header() {
  /*   const { keycloak } = isKeycloakDisabled() ? { keycloak: null } : useKeycloak(); */
  const location = useLocation();
  const readOnly = isReadOnly();
  const role = useAuth().user?.realm_access.roles[2];
  const navigate = useNavigate();

  const logout = () => {
    authApi().logout();
    navigate('/login?logout=success');
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent p={6}>
          <Flex direction="column" align="center">
            <ModalHeader>Wirklich ausloggen?</ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" justify="space-between" align="center" gap="20px">
              <Text>Möchten sie sich wirklich ausloggen?</Text>

              <Flex direction="row" justify="space-between" align="center" gap="20px">
                <Button className="unstyledBtn" onClick={onClose}>
                  Abbrechen
                </Button>
                <Button variant="accent" onClick={logout}>
                  Ausloggen
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box bg="primary" color="white">
        <Container maxW="container.xl" p={8}>
          <HStack spacing="150px" justifyContent="space-between">
            <Link to="/">
              <MainLogo />
            </Link>
            {location.pathname !== '/selector' && (
              <>
                <Flex gap="100px">
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? 'activated' : 'deactivated')}>
                    Startseite
                  </NavLink>
                  <NavLink
                    to="/usecases"
                    data-test="header-use-case-overview"
                    className={({ isActive }) => (isActive ? 'activated' : 'deactivated')}>
                    Overview KI Use-Cases
                  </NavLink>
                  {isAdmin() && (
                    <NavLink
                      to="/rolemanagement"
                      data-test="header-use-case-overview"
                      className={({ isActive }) => (isActive ? 'activated' : 'deactivated')}>
                      Rollenverwaltung
                    </NavLink>
                  )}
                </Flex>
                <Spacer />
                {(isAdmin() || isConsultantUser()) &&
                  (location.pathname == '/usecases' || location.pathname == '/') && (
                    <UserSelector />
                  )}
              </>
            )}
            <Flex gap={'20px'} alignItems="center">
              <Button variant="link" color="white" onClick={onOpen}>
                Abmelden
              </Button>
              {role === 'ADMIN' && !readOnly && <MoreMenu />}
            </Flex>
          </HStack>
        </Container>
      </Box>
    </>
  );
});

export default Header;
