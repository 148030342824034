import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { useCustomerSelectorMode } from './pages/UserSelector/useCustomerSelectorMode';

interface ProtectedRouteProps {
  redirectPath?: string;
  Component?: React.NamedExoticComponent<object>;
}

const ProtectedRoute = ({ redirectPath = 'selector', Component }: ProtectedRouteProps) => {
  const { isSelectedMode } = useCustomerSelectorMode();

  if (!isSelectedMode) {
    return <Navigate to={redirectPath} replace />;
  }

  return Component ? <Route element={<Component />} /> : <Outlet />;
};

export default ProtectedRoute;
