import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Tooltip,
  useToast,
} from '@chakra-ui/react';

import React, { memo, useCallback, useRef } from 'react';
import * as XLSX from 'xlsx';
import { CustomSelect } from 'src/components/controls/CustomSelect';
import {
  SelectionsUserCaseFilter,
  selectArrayFieldValues,
  selectFilterField,
  selectSortedAndFilteredUseCases,
  useUseCaseStore,
} from '../../../store/useUseCaseStore';
import { useMultiSelectOptions } from '../../controls/useSelectOptions';
import { UseCaseDTO, UseCaseGeneralDTO } from '../../../api';
import { AddIcon, ArrowUpIcon, DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import { useCustomerSelectorMode } from '../UserSelector/useCustomerSelectorMode';
import { useNavigate } from 'react-router-dom';
import { useQueryIndex } from 'src/components/QueryIndexProvider';
import { useUserStore } from 'src/store/useUserStore';
import { isAdmin, isReadOnly } from 'src/auth/scope';
import { useCaseGeneralApi } from 'src/store/useGeneralUseCaseStore';

const FilterSelect = memo(function FilterSelect({
  field,
  placeholder,
}: {
  field: keyof UseCaseDTO;
  placeholder: string;
}) {
  const arrayFieldValues = useUseCaseStore(
    useCallback(selectArrayFieldValues(field), [field])
  ) as string[];
  const filterValue = useUseCaseStore(useCallback(selectFilterField(field), [field])) as string[];

  const setFilter = useUseCaseStore(state => state.setFilter);

  const { options, value, onChange } = useMultiSelectOptions(arrayFieldValues, filterValue, value =>
    setFilter({ [field]: value })
  );

  return (
    <CustomSelect
      placeholder={placeholder}
      variant="filled"
      options={options}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      value={value}
      onChange={onChange as (option: unknown) => void}
    />
  );
});

const BooleanFilterSelect = memo(function BooleanFilterSelect({
  fields,
  placeholder,
  filterKey,
  formattedLabel,
}: {
  fields: (keyof UseCaseDTO)[];
  placeholder: string;
  filterKey: keyof SelectionsUserCaseFilter;
  formattedLabel?: (w: string) => string;
}) {
  const filterValue = useUseCaseStore(
    useCallback(selectFilterField(filterKey), [filterKey])
  ) as string[];
  const setFilter = useUseCaseStore(state => state.setFilter);

  const { options, value, onChange } = useMultiSelectOptions(fields, filterValue, field =>
    setFilter({ [filterKey]: field })
  );
  const formattedOptions = options.map(opt => {
    return { ...opt, label: formattedLabel(opt.label) };
  });

  return (
    <CustomSelect
      placeholder={placeholder}
      variant="filled"
      options={formattedOptions}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      value={value}
      onChange={onChange as (option: unknown) => void}
    />
  );
});

interface ExcelUsecaseObject {
  titel: string;
  hintergrund: string;
  chance: string;
  geschaeftsfelder: string;
  industrieTags: string;
  technologienTags: string;
  industriespezifisch: string;
  'ki-usecase': string;
}

const fileUploadHandler = async (file: File) => {
  const data = await file.arrayBuffer();
  const workbook = await XLSX.read(data);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const rawUseCases: ExcelUsecaseObject[] = XLSX.utils.sheet_to_json(worksheet, {
    header: [
      'titel',
      'hintergrund',
      'chance',
      'geschaeftsfelder',
      'industrieTags',
      'technologienTags',
      'industriespezifisch',
      'ki-usecase',
    ],
    raw: true,
    defval: '',
  });

  const useCases: UseCaseGeneralDTO[] = [];

  for (let i = 3; i < rawUseCases.length; i++) {
    const rawUC: ExcelUsecaseObject = rawUseCases[i];

    // Transforms the raw UC objects. Need to be done, instead of just using the header as the object to split the tags
    const uc: UseCaseGeneralDTO = {
      usecaseUsecaseTitel: rawUC.titel,
      usecaseFachlicherHintergrund: rawUC.hintergrund,
      usecaseChance: rawUC.chance,
      'ki-usecase': rawUC['ki-usecase'],
      industrieTags: rawUC.industrieTags?.split(','),
      industriespezifischTags: rawUC.industriespezifisch?.split(','),
      technologienTags: rawUC.technologienTags?.split(','),
      geschaeftsfelderTags: rawUC.geschaeftsfelder?.split(','),
    };

    useCases.push(uc);
  }

  await useCaseGeneralApi.createMultipleUseCaseGeneral(useCases);
};

const fileExportHandler = (useCases: UseCaseDTO[], customerName: string) => {
  let workshopCount = 0;
  let deepdiveCount = 0;

  const rows: any[] = useCases.map(row => {
    if (row.selektionWorkshop) workshopCount++;
    if (row.selektionDeepdive) deepdiveCount++;
    return {
      useCaseTitel: row.usecaseUsecaseTitel,
      fachlicherHintergrund: row.usecaseFachlicherHintergrund,
      selectionFacvorite: row.selektionFavorite ? 'Ja' : 'Nein',
      chance: row.usecaseChance,
      backlog: row.selektionBacklog ? 'Ja' : 'Nein',
      workshop: row.selektionWorkshop ? 'Ja' : 'Nein',
      deepdive: row.selektionDeepdive ? 'Ja' : 'Nein',
      leuchtturm: row.selektionLeuchtturm ? 'Ja' : 'Nein',
      industriespezifisch: row.industriespezifischTags.toString(),
      geschaeftsfelder: row.geschaeftsfelderTags.toString(),
      industrieTags: row.industrieTags.toString(),
      technologieTags: row.technologienTags.toString(),
      externerKundenkontakt: row.usecaseExternerKundenkontakt ? 'Ja' : 'Nein',
    };
  });
  rows.unshift([customerName, workshopCount, deepdiveCount]);
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'UseCases');
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        'Customer',
        'Selected Workshops',
        'Selected Deepdives',
        'UseCase Titel',
        'Fachlicher Hintergrund',
        'Selected Favorite',
        'KI-Chance',
        'Backlog',
        'Workshop',
        'Deep-Dive',
        'Leuchtturm',
        'Induestrie Spezifisch',
        'Geschäftsfelder',
        'Industrie',
        'Technologie',
        'externer Kundenkontakt',
      ],
    ],
    { origin: 'A1' }
  );

  XLSX.writeFile(workbook, 'UseCases.xlsx', { compression: true });
};
export const UseCaseFilter = memo(function UseCaseFilter() {
  const fileInput = useRef(null);
  const toast = useToast();
  const filterQuery = useUseCaseStore(state => state.filterQuery);
  const setFilterQuery = useUseCaseStore(state => state.setFilterQuery);
  const { customerMode, allgemeinMode } = useCustomerSelectorMode();
  const navigate = useNavigate();
  const queryIndex = useQueryIndex();
  const useCases = useUseCaseStore(
    useCallback(selectSortedAndFilteredUseCases(queryIndex), [queryIndex])
  );

  let customerName = '';
  if (customerMode === undefined) {
    customerName = useUserStore(state => state.user.name);
    customerName = 'Allgemein';
  } else {
    customerName = useUserStore(state => state.selectedUser.name);
  }

  return (
    <HStack spacing="3">
      {allgemeinMode && <FilterSelect field="industrieTags" placeholder="Industrie" />}
      <FilterSelect field="industriespezifischTags" placeholder="Industriespezifisch" />
      <FilterSelect field="geschaeftsfelderTags" placeholder="Geschäftsfelder" />
      <FilterSelect field="usecaseUsecaseTitel" placeholder="Usecase Titel" />
      <FilterSelect field="technologienTags" placeholder="Technologien" />
      {customerMode && (
        <BooleanFilterSelect
          fields={
            isReadOnly()
              ? ['selektionFavorite']
              : [
                  'selektionBacklog',
                  'selektionDeepdive',
                  'selektionWorkshop',
                  'selektionLeuchtturm',
                  'selektionFavorite',
                ]
          }
          placeholder="Selektionen"
          filterKey="selektionen"
          formattedLabel={w => w.split(/(?=[A-Z])/)[1]}
        />
      )}
      <Spacer />
      {isAdmin && !isReadOnly() && (
        <Tooltip hasArrow label="Add Use-Case" bg="grey">
          <IconButton
            size="lg"
            isRound={true}
            variant="accent"
            onClick={() => navigate('/usecases/general/create')}
            icon={<AddIcon />}
            aria-label="Add General Use-Case"
          />
        </Tooltip>
      )}

      {isAdmin && (
        <>
          <Tooltip label="Upload Use-Cases">
            <IconButton
              size="lg"
              isRound={true}
              variant="accent"
              onClick={() => fileInput.current.click()}
              icon={<ArrowUpIcon />}
              aria-label="Upload Use-Cases"
            />
          </Tooltip>
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={async event => {
              try {
                toast.closeAll();
                await fileUploadHandler(event.target.files[0]);
                toast({
                  position: 'top-right',
                  status: 'success',
                  description: 'UseCases Erfolgreich eingelesen!',
                });
              } catch (err) {
                toast({
                  position: 'top-right',
                  status: 'error',
                  description: 'Fehler beim Einlesen der UseCases. Bitte versuche es erneut!',
                });
              }
            }}
            ref={fileInput}
            accept=".xlsx, .xls"
          />
        </>
      )}

      <Tooltip hasArrow label="Export selected Use-Cases" bg="grey">
        <IconButton
          size="lg"
          isRound={true}
          variant="accent"
          onClick={() => fileExportHandler(useCases, customerName)}
          icon={<DownloadIcon />}
          aria-label="Export selected Use-Cases"
        />
      </Tooltip>

      <Box>
        <InputGroup variant="filled" minWidth="300px">
          <InputLeftElement pointerEvents="none">
            <SearchIcon />
          </InputLeftElement>
          <Input
            placeholder="Suchen"
            value={filterQuery}
            onChange={e => setFilterQuery(e.target.value)}
          />
        </InputGroup>
      </Box>
    </HStack>
  );
});
