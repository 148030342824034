import { useCallback, useMemo } from 'react';

interface Option<T> {
  value: T;
  label: string;
}

export function useSingleSelectOptions<T>(options: T[], value: T, onChange: (value: T) => void) {
  const selectOptions = useMemo(() => options.map(o => ({ value: o, label: o })), [options]);

  const optionsValue = useMemo(() => ({ value, label: value }), [value]);

  const handleChange = useCallback(
    (option: Option<T>) => value !== option.value && onChange && onChange(option.value),
    [value]
  );

  return {
    options: selectOptions,
    value: optionsValue,
    onChange: handleChange,
  };
}

export function useMultiSelectOptions<T>(options: T[], value: T[], onChange: (value: T[]) => void) {
  const selectOptions = useMemo(() => options.map(o => ({ value: o, label: o })), [options]);

  const optionsValue = useMemo(() => value?.map(v => ({ value: v, label: v })), [value]);

  const handleChange = useCallback(
    (options: Option<T>[]) => onChange && onChange(options.map(o => o.value)),
    []
  );

  return {
    options: selectOptions,
    value: optionsValue,
    onChange: handleChange,
  };
}
