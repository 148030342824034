import { Input } from '@chakra-ui/react';
import React, { ElementRef, forwardRef, memo } from 'react';
import { KeysWithValuesOfType } from '../../../../../types';
import { UseCaseGeneralDTO } from '../../../../../api';
import { useGeneralUpdateField } from '../../useUpdateField';
import { isAdmin } from '../../../../../auth/scope';

type Props = {
  field: KeysWithValuesOfType<UseCaseGeneralDTO, string>;
};

export const GeneralTitleInput = memo(
  forwardRef<ElementRef<'input'>, Props>(function GeneralTitleInput(props, ref) {
    const { field } = props;

    const { value, update, readOnly } = useGeneralUpdateField(field);

    return (
      <Input
        isDisabled={Boolean(!isAdmin)}
        ref={ref}
        {...props}
        value={value ?? ''}
        isReadOnly={readOnly}
        onChange={e => update(e.target.value)}
        variant="unstyled"
        my="3rem"
        fontSize="3xl"
        fontWeight="semibold"
        color="primary"
        textAlign="center"
        placeholder="Use-Case Titel"
        _hover={{ bg: 'blackAlpha.100' }}
        _readOnly={{ _hover: { bg: 'none' } }}
      />
    );
  })
);
