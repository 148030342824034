import { Box, BoxProps, Text } from '@chakra-ui/react';
import React from 'react';

const TitleSection: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      width="100%"
      bgColor="white"
      borderRadius="md"
      py="5"
      px="10"
      color="primaryDark"
      fontSize="2xl"
      fontWeight="semibold"
      {...rest}>
      <Text data-testid="titleSectionTextId" textTransform="uppercase">
        {children}
      </Text>
    </Box>
  );
};

export default TitleSection;
