import React, { memo } from 'react';
import { Box, HStack, Text, Textarea, TextareaProps } from '@chakra-ui/react';
import TextareaAutosize from 'react-textarea-autosize';
import { KeysWithValuesOfType } from '../../../../../types';
import { UseCaseGeneralDTO } from '../../../../../api';
import { useGeneralUpdateField } from '../../useUpdateField';

type GeneralTextareaInputProps = {
  field: KeysWithValuesOfType<UseCaseGeneralDTO, string>;
  title?: string;
  isFieldDisabled?: boolean;
};

const GeneralTextareaInput = memo(function GeneralTextareaInput({
  title,
  field,
  variant,
  isFieldDisabled,
}: TextareaProps & GeneralTextareaInputProps) {
  const { value, update, readOnly } = useGeneralUpdateField(field);

  if (!value && readOnly) return null;

  return (
    <Box mb="3rem">
      {title && (
        <HStack mb="1rem" justifyContent="space-between">
          <Text color="accent">{title}</Text>
        </HStack>
      )}
      <Textarea
        variant={variant}
        isReadOnly={readOnly}
        value={value ?? ''}
        onChange={e => update(e.target.value)}
        as={TextareaAutosize}
        transition="height none"
        isDisabled={isFieldDisabled}
      />
    </Box>
  );
});

export default GeneralTextareaInput;
