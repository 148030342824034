import { Avatar, Flex, Text } from '@chakra-ui/react';
import { memo } from 'react';

export enum UserProfileEnum {
  standard = 'standard',
  custom = 'custom',
}

export type UserProfileProps = {
  name: string;
  email?: string;
  avatarUrl?: string;
  category?: string;
  type?: UserProfileEnum;
};

export const UserProfile = memo(function UserProfile({
  name,
  avatarUrl,
  email,
  type,
}: UserProfileProps) {
  return (
    <Flex alignItems="center">
      <Avatar size="md" src={avatarUrl} />
      <Flex ml={4} direction="column" alignItems="start">
        {type === UserProfileEnum.standard ? (
          <>
            <Text fontWeight="bold">{name}</Text>
            <Text fontSize="sm">{email}</Text>
          </>
        ) : (
          <>
            <Text fontSize="sm">KundenSpezifisch</Text>
            <Text fontSize="lg" fontWeight="bold">
              {name}
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
});
