import React, { memo } from 'react';
import { Flex } from '@chakra-ui/react';
import { InfoTile } from '../../controls/InfoTile';
import { SwitchInput } from '../../controls/SwitchInput';
import { useUseCaseStore } from 'src/store/useUseCaseStore';

const Selektion = memo(function Selektion() {
  const useCase = useUseCaseStore(state => state.useCase);

  return (
    <InfoTile title="Selektion">
      <Flex h="100%" justify="space-around" rowGap="8" direction="column">
        <SwitchInput field="selektionBacklog" title="Backlog" />
        <SwitchInput field="selektionWorkshop" title="Workshops" />
        <SwitchInput
          field="selektionDeepdive"
          title="Deep Dive"
          isDisabled={!useCase.selektionWorkshop}
          dependentValue={useCase.selektionWorkshop}
        />
        <SwitchInput field="selektionLeuchtturm" title="Leuchtturm" />
      </Flex>
    </InfoTile>
  );
});

export default Selektion;
