import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import { QueryIndexProvider } from './components/QueryIndexProvider';
import { enableWhyDidYouRender } from './wdyr';

enableWhyDidYouRender(React);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const appContent = (
  <React.StrictMode>
    <QueryIndexProvider>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </QueryIndexProvider>
  </React.StrictMode>
);

root.render(appContent);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
