import {
  Configuration,
  UseCaseGeneralApi,
  UseCaseGeneralDTO,
  UseCaseUserGeneralApi,
  UseCaseUserGeneralDTO,
} from '../api';
import { combine, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';
import { ExtractState } from './types';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { emptyGeneralUseCase } from './emptyGeneralUseCase';
import { useUseCaseStore } from './useUseCaseStore';

export const useCaseGeneralApi = new UseCaseGeneralApi(new Configuration({ basePath: '/api' }));
export const useCaseUserGeneralApi = new UseCaseUserGeneralApi(
  new Configuration({ basePath: '/api' })
);

export const useGeneralUseCaseStore = create(
  devtools(
    immer(
      combine(
        {
          generalUseCase: {} as UseCaseGeneralDTO & UseCaseUserGeneralDTO,
          generalUseCases: [] as Array<UseCaseGeneralDTO & UseCaseUserGeneralDTO>,
          error: null,
          isLoading: false,
        },
        (set, get) => ({
          getGeneralUseCases: async () => {
            try {
              set({ isLoading: true });
              const response = await useCaseGeneralApi.getGeneralUseCases();
              set({ isLoading: false, generalUseCases: response.data });
            } catch (err: any) {
              set({ error: err });
            }
          },
          getGeneralUseCaseById: async (id: string) => {
            try {
              set({ isLoading: true });
              try {
                const response = await useCaseGeneralApi.getGeneralUseCase(id);

                set({ isLoading: false, generalUseCase: response.data });
              } catch (err: any) {
                if (err.response.status === HttpStatusCode.NotFound) {
                  const response = await useCaseUserGeneralApi.getGeneralUserUseCase(id);
                  set({ isLoading: false, generalUseCase: response.data });
                }
              }
            } catch (err: any) {
              set({ error: err });
            }
          },
          updateField: <T extends keyof UseCaseGeneralDTO>(
            field: T,
            value: UseCaseGeneralDTO[T]
          ) => {
            set(state => {
              state.generalUseCase[field] = value;
            });
          },
          saveChanges: async () => {
            const useCase = get().generalUseCase;

            if (
              !useCase.usecaseUsecaseTitel ||
              !useCase.usecaseChance ||
              !useCase.usecaseFachlicherHintergrund
            ) {
              throw new Error('Use-Case Titel, Fachlicher Hintergrund oder KI Lösung fehlt');
            }

            let response: AxiosResponse<UseCaseGeneralDTO, any> = null;
            if (useCase.customerId) {
              response = useCase.id
                ? await useCaseUserGeneralApi.updateGeneralUserUseCase(useCase.id, useCase)
                : await useCaseUserGeneralApi.createGeneralUserUseCase(useCase);
            } else {
              response = useCase.id
                ? await useCaseGeneralApi.updateGeneralUseCase(useCase.id, useCase)
                : await useCaseGeneralApi.createGeneralUseCase(useCase);
            }

            if (
              response.status !== HttpStatusCode.Ok &&
              response.status !== HttpStatusCode.Created
            ) {
              throw new Error('Use-Case konnte nicht erstellt werden');
            }

            if (response.status === HttpStatusCode.Created) {
              useGeneralUseCaseStore.getState().setEmptyUseCase();
              set(state => {
                state.generalUseCases.push(response.data);
              });

              useUseCaseStore.getState().addUseCase(response.data);
            }

            set(state => {
              state.generalUseCases = state.generalUseCases.map(currentUseCase => {
                return currentUseCase.id === useCase.id ? { ...useCase } : currentUseCase;
              });
            });
          },
          setEmptyUseCase: () => {
            set({ generalUseCase: emptyGeneralUseCase });
          },
          setCustomerIdOnUseCase: (customerId: string) => {
            const useCase = get().generalUseCase;
            set({ generalUseCase: { ...useCase, customerId } });
          },
          generalUCfromUserUC: () => {
            const useCase = get().generalUseCase;
            delete useCase.customerId;
            set({ generalUseCase: useCase });
          },
          setIndustrie: (industries: { label: string; value: string }[]) => {
            const values = industries.map(industrie => industrie.value);
            const useCase = get().generalUseCase;
            set({ generalUseCase: { ...useCase, industrieTags: values } });

            // if (values.includes('Banking')) {
            //   set({
            //     generalUseCase: { ...useCase, geschaeftsfelderTags: ['Finanzen und Buchhaltung'] },
            //   });
            // }
          },
        })
      )
    )
  )
);

type GeneralUseCaseStoreState = ExtractState<typeof useGeneralUseCaseStore>;

export const selectGeneralUseCaseField =
  <T extends keyof UseCaseGeneralDTO>(field: T) =>
  (state: GeneralUseCaseStoreState) =>
    state.generalUseCase[field];

export const selectGeneralUsecaseFieldValues =
  (field: keyof UseCaseGeneralDTO) => (state: GeneralUseCaseStoreState) => {
    return [...new Set(state.generalUseCases.map(u => u[field]))];
  };

export const selectGeneralUsecaseArrayFieldValues =
  (field: keyof UseCaseGeneralDTO) => (state: GeneralUseCaseStoreState) => {
    const values: (string | number | boolean)[] = [];

    state.generalUseCases.forEach(usecase => {
      const value = usecase[field];
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (!values.includes(item)) {
            values.push(item);
          }
        });
      } else {
        if (!values.includes(value)) {
          values.push(value);
        }
      }
    });

    return values;
  };
