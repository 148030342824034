import { VStack } from '@chakra-ui/react';
import { MdWork } from 'react-icons/md';
import { SiMoleculer } from 'react-icons/si';
import { Icon } from '@chakra-ui/react';
import { MultiSelectInput } from '../../controls/SelectInput';
import { SwitchInput } from '../../controls/SwitchInput';
import { memo } from 'react';
import { InfoTile } from '../../controls/InfoTile';
import { isReadOnly } from '../../../../../auth/scope';
import { useCustomerSelectorMode } from 'src/components/pages/UserSelector/useCustomerSelectorMode';
import { UserModeEnum } from 'src/store/useUserStore';

const UseCase = memo(function UseCase() {
  const readOnly = isReadOnly();
  const { customerMode } = useCustomerSelectorMode();

  return (
    <InfoTile
      className={customerMode?.mode === UserModeEnum.userSpezifisch ? 'disabled' : ''}
      title="Use-Case">
      <VStack align="stretch" spacing={readOnly ? 8 : 4}>
        {customerMode && (
          <>
            <MultiSelectInput
              isFieldDisabled={Boolean(customerMode)}
              icon={<Icon as={MdWork} color="gray.500" />}
              field="industriespezifischTags"
              title="Geschäftsbereich"
              data-test="geschaeftsInput"
            />
            <MultiSelectInput
              isFieldDisabled={Boolean(customerMode)}
              icon={<Icon as={SiMoleculer} color="gray.500" />}
              field="technologienTags"
              title="Technologien"
            />
            <SwitchInput
              isDisabled={Boolean(customerMode)}
              field="usecaseExternerKundenkontakt"
              title="Externer Kundenkontakt"
            />
          </>
        )}
      </VStack>
    </InfoTile>
  );
});

export default UseCase;
