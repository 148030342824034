import React, { memo } from 'react';
import { Box, HStack, Text, Textarea, TextareaProps } from '@chakra-ui/react';
import TextareaAutosize from 'react-textarea-autosize';
import { KeysWithValuesOfType } from '../../../../types';
import { UseCaseDTO } from '../../../../api';
import { useUpdateField } from '../useUpdateField';

type TextareaInputProps = {
  field: KeysWithValuesOfType<UseCaseDTO, string>;
  title?: string;
  isFieldDisabled?: boolean;
};

export const TextareaInput = memo(function TextareaInput({
  title,
  field,
  variant,
  isFieldDisabled,
}: TextareaProps & TextareaInputProps) {
  const { value, update, readOnly } = useUpdateField(field);

  if (!value && readOnly) return null;

  return (
    <Box mb="3rem">
      {title && (
        <HStack mb="1rem" justifyContent="space-between">
          <Text color="accent">{title}</Text>
        </HStack>
      )}
      <Textarea
        variant={variant}
        isReadOnly={readOnly}
        value={value ?? ''}
        onChange={e => update(e.target.value)}
        as={TextareaAutosize}
        transition="height none"
        readOnly={isFieldDisabled}
        data-test="textarea"
      />
    </Box>
  );
});
