import Header from './Header';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import React, { memo, useEffect } from 'react';
import { useUseCaseStore } from '../store/useUseCaseStore';
import { useCustomerSelectorMode } from './pages/UserSelector/useCustomerSelectorMode';
import { useGeneralUseCaseStore } from 'src/store/useGeneralUseCaseStore';

export const ProtectedLayout = memo(function Layout() {
  const getUseCases = useUseCaseStore(state => state.getAllUseCases);
  const getGeneralUseCases = useGeneralUseCaseStore(state => state.getGeneralUseCases);
  const { allgemeinMode, customerMode } = useCustomerSelectorMode();

  useEffect(() => {
    if (allgemeinMode) {
      getGeneralUseCases();
    }
    if (customerMode) {
      getUseCases();
    }
  }, [allgemeinMode, customerMode]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
});
